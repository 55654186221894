import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert, Pagination, PaginationItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';

function ApmOpenBankSelection(props) {
    const { onClose, value: valueProp, open, options, title, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const [showError, setShowError] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const radioGroupRef = React.useRef(null);

    const maxRows = 10;

    let startRecord = (10 * page) - maxRows - 1;    
    if (startRecord > options.length) {
      startRecord = 0;
      setPage(1);
    }

    const endRecord = (10 * page) - 1;
    const pageCount = Math.ceil(options.length / maxRows);
  
    React.useEffect(() => {
      if (!open) {
        setValue(valueProp);
      }
    }, [valueProp, open]);
  
    const handleEntering = () => {
      if (radioGroupRef.current != null) {
        radioGroupRef.current.focus();
      }
    };
  
    const handleCancel = () => {
      onClose(null);
    };
  
    const handleOk = () => {
      if (value == null) {
        setShowError(true);
      }
      else {
        onClose(value);
      }
    };
  
    const handleChange = (event) => {
      setValue(event.target.value);
    };

    const handlePageChange = (event, value) => {
      setPage(value);
    };    
  
    return (
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...other}
      >
        <DialogTitle>Select Bank ({title === null ? "" : title.replaceAll("_", " ")})</DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            name="ringtone"
            value={value}
            onChange={handleChange}
          >
            {options.map((option, index) => {
              if (index >= startRecord && index <= endRecord) {
                return <FormControlLabel
                  value={option.code}
                  key={option.code}
                  control={<Radio />}
                  label={option.caption}
                />
              }
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
        <GridContainer style={{padding: 0, margin: 0}}>
            <GridItem xs={11} sm={11} md={11} lg={11}>
              <Pagination count={pageCount} color="primary" variant="outlined" page={page} onChange={handlePageChange} />
            </GridItem>
            <GridItem xs={11} sm={11} md={11} lg={11} style={{ marginTop: "5px" }}>
              <GridContainer style={{padding: 0, margin: 0}}>
                <GridItem xs={11} sm={11} md={8} lg={8}>
                  {showError && 
                    <Alert severity="error" elevation={0} style={{padding: 0, margin: 0, backgroundColor: "#FFF"}}>
                      Select Bank to Proceed</Alert>
                  }
                </GridItem>
                <GridItem xs={11} sm={11} md={2} lg={2}>
                  <Button onClick={handleOk}>Ok</Button>
                </GridItem>
                <GridItem xs={11} sm={11} md={2} lg={2}>
                  <Button autoFocus onClick={handleCancel}>Cancel</Button>
                </GridItem>
              </GridContainer>
            </GridItem>
            </GridContainer>
        </DialogActions>
      </Dialog>
    );
  }

  export default ApmOpenBankSelection;