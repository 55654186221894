export const terminalMetaData = [
    {
        "localName": "nuveiapmemulator",
        "localLogoURL": "nuveiapmemulator.png",
        "paymentMethod": "apmgw_ApmEmulator",
        "paymentMethodDisplayName": [],
        "countries": [
            "AL",
            "DZ",
            "AS",
            "AD",
            "AO",
            "AI",
            "AQ",
            "AG",
            "AR",
            "AM",
            "AW",
            "AU",
            "AT",
            "AZ",
            "BS",
            "BH",
            "BD",
            "BB",
            "BY",
            "BE",
            "BZ",
            "BJ",
            "BM",
            "BT",
            "BO",
            "BA",
            "BW",
            "BV",
            "BR",
            "IO",
            "BN",
            "BG",
            "BF",
            "BI",
            "KH",
            "CM",
            "CA",
            "CV",
            "KY",
            "CF",
            "TD",
            "CL",
            "CN",
            "CX",
            "CC",
            "CO",
            "KM",
            "CG",
            "CD",
            "CK",
            "CR",
            "CI",
            "HR",
            "CU",
            "CY",
            "CZ",
            "DK",
            "DJ",
            "DM",
            "DO",
            "TL",
            "EC",
            "EG",
            "SV",
            "GQ",
            "ER",
            "EE",
            "ET",
            "FK",
            "FO",
            "FJ",
            "FI",
            "FR",
            "GF",
            "PF",
            "TF",
            "GA",
            "GM",
            "GE",
            "DE",
            "GH",
            "GI",
            "GR",
            "GL",
            "GD",
            "GP",
            "GU",
            "GT",
            "GN",
            "GW",
            "GY",
            "HT",
            "HM",
            "VA",
            "HN",
            "HK",
            "HU",
            "IS",
            "IN",
            "ID",
            "IR",
            "IQ",
            "IE",
            "IL",
            "IT",
            "JM",
            "JP",
            "JO",
            "KZ",
            "KE",
            "KI",
            "KP",
            "KR",
            "KW",
            "KG",
            "LA",
            "LV",
            "LB",
            "LS",
            "LR",
            "LY",
            "LI",
            "LT",
            "LU",
            "MO",
            "MK",
            "MG",
            "MW",
            "MY",
            "MV",
            "ML",
            "MT",
            "MH",
            "MQ",
            "MR",
            "MU",
            "YT",
            "MX",
            "FM",
            "MD",
            "MC",
            "MN",
            "MS",
            "MA",
            "MZ",
            "MM",
            "NA",
            "NR",
            "NP",
            "NL",
            "AN",
            "NC",
            "NZ",
            "NI",
            "NE",
            "NG",
            "NU",
            "NF",
            "MP",
            "NO",
            "OM",
            "PK",
            "PW",
            "PA",
            "PG",
            "PY",
            "PE",
            "PH",
            "PN",
            "PL",
            "PT",
            "PR",
            "QA",
            "RE",
            "RO",
            "RU",
            "RW",
            "SH",
            "KN",
            "LC",
            "PM",
            "VC",
            "WS",
            "SM",
            "ST",
            "SA",
            "SN",
            "SC",
            "SL",
            "SG",
            "SK",
            "SI",
            "SB",
            "SO",
            "ZA",
            "ES",
            "LK",
            "SD",
            "SR",
            "SJ",
            "SZ",
            "SE",
            "CH",
            "SY",
            "TW",
            "TJ",
            "TZ",
            "TH",
            "TG",
            "TK",
            "TO",
            "TT",
            "TN",
            "TR",
            "TM",
            "TC",
            "TV",
            "UG",
            "UA",
            "AE",
            "GB",
            "US",
            "UM",
            "UY",
            "UZ",
            "VU",
            "VE",
            "VN",
            "VG",
            "VI",
            "WF",
            "EH",
            "YE",
            "ZM",
            "ZW",
            "AF",
            "ME",
            "RS",
            "AX",
            "BQ",
            "CW",
            "GG",
            "IM",
            "JE",
            "GS",
            "PS",
            "SX"
        ],
        "currencies": [
            "AED",
            "ANG",
            "ARS",
            "AUD",
            "AZN",
            "BBD",
            "BGN",
            "BHD",
            "BMD",
            "BND",
            "BOB",
            "BRL",
            "BWP",
            "BYR",
            "CAD",
            "CHF",
            "CLP",
            "CNY",
            "COP",
            "CRC",
            "CZK",
            "DJF",
            "DKK",
            "DOP",
            "DZD",
            "EEK",
            "EGP",
            "EUR",
            "GBP",
            "GEL",
            "GHS",
            "HKD",
            "HNL",
            "HRK",
            "HUF",
            "IDR",
            "INR",
            "IQD",
            "ISK",
            "JMD",
            "JOD",
            "JPY",
            "KGS",
            "KHR",
            "KRW",
            "KWD",
            "KZT",
            "LBP",
            "LKR",
            "LTL",
            "LVL",
            "MAD",
            "MDL",
            "MMK",
            "MOP",
            "MUR",
            "MXN",
            "MYR",
            "NAD",
            "NGN",
            "NOK",
            "NZD",
            "OMR",
            "PAB",
            "PEN",
            "PGK",
            "PHP",
            "PKR",
            "PLN",
            "PYG",
            "QAR",
            "RON",
            "RSD",
            "RUB",
            "SAR",
            "SEK",
            "SGD",
            "THB",
            "TND",
            "TRY",
            "TTD",
            "TWD",
            "TZS",
            "UAH",
            "USD",
            "UYU",
            "VEF",
            "VND",
            "XAF",
            "XOF",
            "YER",
            "ZAR",
            "NIS",
            "BYN",
            "ZMW"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/apm_test_nuvei.svg",
        "isDirect": "false"
    },
    {
        "localName": "expresscheckout",
        "localLogoURL": "paypal.png",
        "paymentMethod": "apmgw_expresscheckout",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "PayPal"
            }
        ],
        "countries": [
            "AL",
            "DZ",
            "AS",
            "AD",
            "AO",
            "AI",
            "AQ",
            "AG",
            "AR",
            "AM",
            "AW",
            "AU",
            "AT",
            "AZ",
            "BS",
            "BH",
            "BD",
            "BB",
            "BY",
            "BE",
            "BZ",
            "BJ",
            "BM",
            "BT",
            "BO",
            "BA",
            "BW",
            "BV",
            "BR",
            "IO",
            "BN",
            "BG",
            "BF",
            "BI",
            "KH",
            "CM",
            "CA",
            "CV",
            "KY",
            "CF",
            "TD",
            "CL",
            "CN",
            "CX",
            "CC",
            "CO",
            "KM",
            "CG",
            "CD",
            "CK",
            "CR",
            "CI",
            "HR",
            "CU",
            "CY",
            "CZ",
            "DK",
            "DJ",
            "DM",
            "DO",
            "TL",
            "EC",
            "EG",
            "SV",
            "GQ",
            "ER",
            "EE",
            "ET",
            "FK",
            "FO",
            "FJ",
            "FI",
            "FR",
            "GF",
            "PF",
            "TF",
            "GA",
            "GM",
            "GE",
            "DE",
            "GH",
            "GI",
            "GR",
            "GL",
            "GD",
            "GP",
            "GU",
            "GT",
            "GN",
            "GW",
            "GY",
            "HT",
            "HM",
            "VA",
            "HN",
            "HK",
            "HU",
            "IS",
            "IN",
            "ID",
            "IR",
            "IQ",
            "IE",
            "IL",
            "IT",
            "JM",
            "JP",
            "JO",
            "KZ",
            "KE",
            "KI",
            "KP",
            "KR",
            "KW",
            "KG",
            "LA",
            "LV",
            "LB",
            "LS",
            "LR",
            "LY",
            "LI",
            "LT",
            "LU",
            "MO",
            "MK",
            "MG",
            "MW",
            "MY",
            "MV",
            "ML",
            "MT",
            "MH",
            "MQ",
            "MR",
            "MU",
            "YT",
            "MX",
            "FM",
            "MD",
            "MC",
            "MN",
            "MS",
            "MA",
            "MZ",
            "MM",
            "NA",
            "NR",
            "NP",
            "NL",
            "AN",
            "NC",
            "NZ",
            "NI",
            "NE",
            "NG",
            "NU",
            "NF",
            "MP",
            "NO",
            "OM",
            "PK",
            "PW",
            "PA",
            "PG",
            "PY",
            "PE",
            "PH",
            "PN",
            "PL",
            "PT",
            "PR",
            "QA",
            "RE",
            "RO",
            "RU",
            "RW",
            "SH",
            "KN",
            "LC",
            "PM",
            "VC",
            "WS",
            "SM",
            "ST",
            "SA",
            "SN",
            "SC",
            "SL",
            "SG",
            "SK",
            "SI",
            "SB",
            "SO",
            "ZA",
            "ES",
            "LK",
            "SD",
            "SR",
            "SJ",
            "SZ",
            "SE",
            "CH",
            "SY",
            "TW",
            "TJ",
            "TZ",
            "TH",
            "TG",
            "TK",
            "TO",
            "TT",
            "TN",
            "TR",
            "TM",
            "TC",
            "TV",
            "UG",
            "UA",
            "AE",
            "GB",
            "US",
            "UM",
            "UY",
            "UZ",
            "VU",
            "VE",
            "VN",
            "VG",
            "VI",
            "WF",
            "EH",
            "YE",
            "ZM",
            "ZW",
            "AF",
            "ME",
            "RS",
            "AX",
            "CW",
            "GG",
            "IM",
            "JE",
            "GS"
        ],
        "currencies": [
            "AED",
            "AUD",
            "BRL",
            "CAD",
            "CHF",
            "CZK",
            "DKK",
            "EUR",
            "GBP",
            "HKD",
            "HUF",
            "JPY",
            "MXN",
            "MYR",
            "NOK",
            "NZD",
            "PHP",
            "PLN",
            "RUB",
            "SEK",
            "SGD",
            "TWD",
            "USD",
            "NIS"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/paypal.svg",
        "isDirect": "false"
    },
    {
        "localName": "sofort",
        "localLogoURL": "sofort.png",
        "paymentMethod": "apmgw_Sofort",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Sofortuberweisung"
            }
        ],
        "countries": [
            "AT",
            "BE",
            "FR",
            "DE",
            "IT",
            "NL",
            "PL",
            "SK",
            "ES",
            "CH",
            "GB"
        ],
        "currencies": [
            "EUR",
            "GBP"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/sofort.svg",
        "isDirect": "false"
    },
    {
        "localName": "ideal",
        "localLogoURL": "ideal.png",
        "paymentMethod": "apmgw_iDeal",
        "paymentMethodDisplayName": [
            {
                "language": "de",
                "message": "iDeal"
            },
            {
                "language": "en",
                "message": "iDeal"
            },
            {
                "language": "ru",
                "message": "iDeal"
            },
            {
                "language": "pt",
                "message": "iDeal"
            },
            {
                "language": "bg",
                "message": "iDeal"
            },
            {
                "language": "lt",
                "message": "iDeal"
            },
            {
                "language": "hr",
                "message": "iDeal"
            },
            {
                "language": "fr",
                "message": "iDeal"
            },
            {
                "language": "sl",
                "message": "iDeal"
            },
            {
                "language": "en",
                "message": "iDeal"
            },
            {
                "language": "sr",
                "message": "iDeal"
            },
            {
                "language": "sv",
                "message": "iDeal"
            },
            {
                "language": "it",
                "message": "iDeal"
            },
            {
                "language": "zh",
                "message": "iDeal"
            },
            {
                "language": "iw",
                "message": "iDeal"
            },
            {
                "language": "es",
                "message": "iDeal"
            },
            {
                "language": "ar",
                "message": "iDeal"
            },
            {
                "language": "ja",
                "message": "iDeal"
            },
            {
                "language": "pl",
                "message": "iDeal"
            },
            {
                "language": "da",
                "message": "iDeal"
            },
            {
                "language": "ro",
                "message": "iDeal"
            },
            {
                "language": "nl",
                "message": "iDeal"
            },
            {
                "language": "tr",
                "message": "iDeal"
            }
        ],
        "countries": [
            "NL"
        ],
        "currencies": [
            "AUD",
            "CAD",
            "DKK",
            "EUR",
            "GBP",
            "HKD",
            "NOK",
            "SEK",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/ideal.svg",
        "isDirect": "false",
    },
    {
        "localName": "BOLETO",
        "localLogoURL": "boleto.png",
        "paymentMethod": "apmgw_BOLETO",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Boleto bancario"
            }
        ],
        "countries": [
            "BR"
        ],
        "currencies": [
            "BRL",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/boleto.svg",
        "isDirect": "false",
    },
    {
        "localName": "Astropay_TEF",
        "localLogoURL": "astropay-tef.png",
        "paymentMethod": "apmgw_Astropay_TEF",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Local Payment Options"
            }
        ],
        "countries": [
            "AR",
            "BR",
            "CL",
            "CO",
            "IN",
            "MX",
            "PA",
            "PE",
            "UY"
        ],
        "currencies": [
            "ARS",
            "BRL",
            "CLP",
            "COP",
            "MXN",
            "PEN",
            "USD",
            "UYU"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/astropay-tef.svg",
        "isDirect": "false",
    },
    {
        "localName": "PaySafeCard",
        "localLogoURL": "paysafecard.png",
        "paymentMethod": "apmgw_PaySafeCard",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "PaySafeCard"
            }
        ],
        "countries": [
            "AR",
            "AU",
            "AT",
            "BE",
            "BG",
            "CA",
            "HR",
            "CY",
            "CZ",
            "DK",
            "EG",
            "FI",
            "FR",
            "GE",
            "DE",
            "GI",
            "GR",
            "HU",
            "IS",
            "IE",
            "IT",
            "KW",
            "LV",
            "LI",
            "LT",
            "LU",
            "MT",
            "MX",
            "NL",
            "NZ",
            "NO",
            "PY",
            "PE",
            "PL",
            "PT",
            "RO",
            "SA",
            "SK",
            "SI",
            "ES",
            "SE",
            "CH",
            "TR",
            "AE",
            "GB",
            "US",
            "UY",
            "ME",
            "RS"
        ],
        "currencies": [
            "ARS",
            "AUD",
            "BGN",
            "BRL",
            "CAD",
            "CHF",
            "CZK",
            "DKK",
            "EGP",
            "EUR",
            "GBP",
            "GEL",
            "HRK",
            "HUF",
            "ISK",
            "KWD",
            "MXN",
            "NOK",
            "NZD",
            "PEN",
            "PLN",
            "RON",
            "RSD",
            "SAR",
            "SEK",
            "TRY",
            "USD",
            "UYU"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/paysafecard.svg",
        "isDirect": "false",
    },
    {
        "localName": "2C2P_APMs",
        "localLogoURL": "123s.png",
        "paymentMethod": "apmgw_2C2P_APMs",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "2C2P Payments"
            }
        ],
        "countries": [
            "ID",
            "MY",
            "MM",
            "SG",
            "TH",
            "AF"
        ],
        "currencies": [
            "IDR",
            "MMK",
            "MYR",
            "PHP",
            "SGD",
            "THB"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/123s.svg",
        "isDirect": "false",
    },
    {
        "localName": "Alipay",
        "localLogoURL": "alipay_plus.png",
        "paymentMethod": "apmgw_Alipay",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Alipay"
            }
        ],
        "countries": [
            "CN",
            "HK",
            "IT",
            "JP",
            "SG",
            "US",
            "AF"
        ],
        "currencies": [
            "AUD",
            "CAD",
            "CHF",
            "CNY",
            "DKK",
            "EUR",
            "GBP",
            "HKD",
            "NOK",
            "SEK",
            "SGD",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/alipay_plus.svg",
        "isDirect": "false",
    },
    {
        "localName": "AstroPay",
        "localLogoURL": "astropay.png",
        "paymentMethod": "apmgw_AstroPay",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "AstroPay"
            }
        ],
        "countries": [
            "AL",
            "DZ",
            "AS",
            "AD",
            "AO",
            "AI",
            "AQ",
            "AG",
            "AR",
            "AM",
            "AW",
            "AU",
            "AT",
            "AZ",
            "BS",
            "BH",
            "BD",
            "BB",
            "BY",
            "BE",
            "BZ",
            "BJ",
            "BM",
            "BT",
            "BO",
            "BA",
            "BW",
            "BV",
            "BR",
            "IO",
            "BN",
            "BG",
            "BF",
            "BI",
            "KH",
            "CM",
            "CA",
            "CV",
            "KY",
            "CF",
            "TD",
            "CL",
            "CN",
            "CX",
            "CC",
            "CO",
            "KM",
            "CG",
            "CD",
            "CK",
            "CR",
            "CI",
            "HR",
            "CU",
            "CY",
            "CZ",
            "DK",
            "DJ",
            "DM",
            "DO",
            "TL",
            "EC",
            "EG",
            "SV",
            "GQ",
            "ER",
            "EE",
            "ET",
            "FK",
            "FO",
            "FJ",
            "FI",
            "FR",
            "GF",
            "PF",
            "TF",
            "GA",
            "GM",
            "GE",
            "DE",
            "GH",
            "GI",
            "GR",
            "GL",
            "GD",
            "GP",
            "GU",
            "GT",
            "GN",
            "GW",
            "GY",
            "HT",
            "HM",
            "VA",
            "HN",
            "HK",
            "HU",
            "IS",
            "IN",
            "ID",
            "IR",
            "IQ",
            "IE",
            "IT",
            "JM",
            "JP",
            "JO",
            "KZ",
            "KE",
            "KI",
            "KP",
            "KR",
            "KW",
            "KG",
            "LA",
            "LV",
            "LB",
            "LS",
            "LR",
            "LY",
            "LI",
            "LT",
            "LU",
            "MO",
            "MK",
            "MG",
            "MW",
            "MY",
            "MV",
            "ML",
            "MT",
            "MH",
            "MQ",
            "MR",
            "MU",
            "YT",
            "MX",
            "FM",
            "MD",
            "MC",
            "MN",
            "MS",
            "MA",
            "MZ",
            "MM",
            "NA",
            "NR",
            "NP",
            "NL",
            "AN",
            "NC",
            "NZ",
            "NI",
            "NE",
            "NG",
            "NU",
            "NF",
            "MP",
            "NO",
            "OM",
            "PK",
            "PW",
            "PA",
            "PG",
            "PY",
            "PE",
            "PH",
            "PN",
            "PL",
            "PT",
            "PR",
            "QA",
            "RE",
            "RO",
            "RU",
            "RW",
            "SH",
            "KN",
            "LC",
            "PM",
            "VC",
            "WS",
            "SM",
            "ST",
            "SA",
            "SN",
            "SC",
            "SL",
            "SG",
            "SK",
            "SI",
            "SB",
            "SO",
            "ZA",
            "ES",
            "LK",
            "SD",
            "SR",
            "SJ",
            "SZ",
            "SE",
            "CH",
            "SY",
            "TW",
            "TJ",
            "TZ",
            "TH",
            "TG",
            "TK",
            "TO",
            "TT",
            "TN",
            "TR",
            "TM",
            "TC",
            "TV",
            "UG",
            "UA",
            "AE",
            "GB",
            "UM",
            "UY",
            "UZ",
            "VU",
            "VE",
            "VN",
            "VG",
            "VI",
            "WF",
            "EH",
            "YE",
            "ZM",
            "ZW",
            "AF",
            "ME",
            "RS",
            "AX",
            "BQ",
            "CW",
            "GG",
            "IM",
            "JE",
            "GS",
            "PS",
            "SX"
        ],
        "currencies": [
            "AED",
            "ARS",
            "AUD",
            "BOB",
            "BRL",
            "CAD",
            "CLP",
            "CNY",
            "COP",
            "CZK",
            "DKK",
            "EGP",
            "EUR",
            "GBP",
            "GHS",
            "HNL",
            "IDR",
            "INR",
            "JPY",
            "KRW",
            "KWD",
            "MAD",
            "MMK",
            "MNT",
            "MXN",
            "MYR",
            "MZN",
            "NGN",
            "NOK",
            "NZD",
            "PAB",
            "PEN",
            "PKR",
            "PLN",
            "PYG",
            "RSD",
            "RUB",
            "SAR",
            "SEK",
            "THB",
            "TRY",
            "TWD",
            "TZS",
            "UAH",
            "USD",
            "UYU",
            "VND",
            "XAF",
            "XOF",
            "ZAR"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/astropay.svg",
        "isDirect": "false",
    },
    {
        "localName": "Neteller",
        "localLogoURL": "neteller.png",
        "paymentMethod": "apmgw_Neteller",
        "paymentMethodDisplayName": [
            {
                "language": "de",
                "message": "Neteller"
            },
            {
                "language": "en",
                "message": "Neteller"
            },
            {
                "language": "no",
                "message": "Neteller"
            },
            {
                "language": "fi",
                "message": "Neteller"
            },
            {
                "language": "ru",
                "message": "Neteller"
            },
            {
                "language": "fr",
                "message": "Neteller"
            },
            {
                "language": "sv",
                "message": "Neteller"
            },
            {
                "language": "es",
                "message": "Neteller"
            }
        ],
        "countries": [
            "AL",
            "DZ",
            "AD",
            "AO",
            "AI",
            "AQ",
            "AG",
            "AR",
            "AM",
            "AW",
            "AU",
            "AT",
            "AZ",
            "BS",
            "BH",
            "BD",
            "BB",
            "BY",
            "BE",
            "BZ",
            "BJ",
            "BM",
            "BT",
            "BO",
            "BA",
            "BW",
            "BV",
            "BR",
            "IO",
            "BN",
            "BG",
            "BF",
            "BI",
            "KH",
            "CM",
            "CA",
            "CV",
            "KY",
            "CF",
            "TD",
            "CL",
            "CX",
            "CC",
            "CO",
            "KM",
            "CG",
            "CK",
            "CR",
            "CI",
            "HR",
            "CU",
            "CY",
            "CZ",
            "DK",
            "DJ",
            "DM",
            "DO",
            "TL",
            "EC",
            "EG",
            "SV",
            "GQ",
            "ER",
            "EE",
            "ET",
            "FK",
            "FO",
            "FJ",
            "FI",
            "FR",
            "GF",
            "PF",
            "TF",
            "GA",
            "GM",
            "GE",
            "DE",
            "GH",
            "GI",
            "GR",
            "GL",
            "GD",
            "GP",
            "GU",
            "GT",
            "GN",
            "GW",
            "GY",
            "HT",
            "HM",
            "VA",
            "HN",
            "HK",
            "HU",
            "IS",
            "IN",
            "ID",
            "IR",
            "IQ",
            "IE",
            "IL",
            "IT",
            "JM",
            "JP",
            "JO",
            "KZ",
            "KE",
            "KI",
            "KR",
            "KW",
            "KG",
            "LA",
            "LV",
            "LB",
            "LS",
            "LR",
            "LY",
            "LI",
            "LT",
            "LU",
            "MO",
            "MK",
            "MG",
            "MW",
            "MY",
            "MV",
            "ML",
            "MT",
            "MH",
            "MQ",
            "MR",
            "MU",
            "YT",
            "MX",
            "FM",
            "MD",
            "MC",
            "MN",
            "MS",
            "MA",
            "MZ",
            "MM",
            "NA",
            "NR",
            "NP",
            "NL",
            "AN",
            "NC",
            "NZ",
            "NI",
            "NE",
            "NG",
            "NU",
            "NF",
            "NO",
            "OM",
            "PK",
            "PW",
            "PA",
            "PG",
            "PY",
            "PE",
            "PH",
            "PN",
            "PL",
            "PT",
            "QA",
            "RE",
            "RO",
            "RU",
            "RW",
            "SH",
            "KN",
            "LC",
            "PM",
            "VC",
            "WS",
            "SM",
            "ST",
            "SA",
            "SN",
            "SC",
            "SL",
            "SG",
            "SK",
            "SI",
            "SB",
            "SO",
            "ZA",
            "ES",
            "LK",
            "SD",
            "SR",
            "SJ",
            "SZ",
            "SE",
            "CH",
            "SY",
            "TW",
            "TJ",
            "TZ",
            "TH",
            "TG",
            "TK",
            "TO",
            "TT",
            "TN",
            "TR",
            "TM",
            "TC",
            "TV",
            "UG",
            "UA",
            "AE",
            "GB",
            "US",
            "UY",
            "UZ",
            "VU",
            "VE",
            "VN",
            "VG",
            "WF",
            "EH",
            "YE",
            "ZM",
            "ZW",
            "AF",
            "ME",
            "RS",
            "GG",
            "IM",
            "JE",
            "PS"
        ],
        "currencies": [
            "AED",
            "ARS",
            "AUD",
            "BGN",
            "BRL",
            "CAD",
            "CHF",
            "CLP",
            "CNY",
            "COP",
            "CRC",
            "CZK",
            "DKK",
            "EUR",
            "GBP",
            "HKD",
            "HRK",
            "HUF",
            "INR",
            "JOD",
            "JPY",
            "KWD",
            "LTL",
            "LVL",
            "MAD",
            "MXN",
            "MYR",
            "NOK",
            "NZD",
            "PEN",
            "PLN",
            "RON",
            "RSD",
            "RUB",
            "SAR",
            "SEK",
            "SGD",
            "TND",
            "TWD",
            "USD",
            "ZAR"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/neteller.svg",
    },
    {
        "localName": "Epay",
        "localLogoURL": "epay.png",
        "paymentMethod": "apmgw_Epay_bg",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Epay.bg"
            }
        ],
        "countries": [
            "BG"
        ],
        "currencies": [
            "BGN",
            "EUR",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/epay.svg",
        "isDirect": "false",
    },
    {
        "localName": "EPS",
        "localLogoURL": "eps.png",
        "paymentMethod": "apmgw_EPS",
        "paymentMethodDisplayName": [
            {
                "language": "de",
                "message": "EPS"
            },
            {
                "language": "en",
                "message": "EPS"
            },
            {
                "language": "ru",
                "message": "EPS"
            },
            {
                "language": "pt",
                "message": "EPS"
            },
            {
                "language": "bg",
                "message": "EPS"
            },
            {
                "language": "lt",
                "message": "EPS"
            },
            {
                "language": "hr",
                "message": "EPS"
            },
            {
                "language": "fr",
                "message": "EPS"
            },
            {
                "language": "sl",
                "message": "EPS"
            },
            {
                "language": "it",
                "message": "EPS"
            },
            {
                "language": "zh",
                "message": "EPS"
            },
            {
                "language": "iw",
                "message": "EPS"
            },
            {
                "language": "es",
                "message": "EPS"
            },
            {
                "language": "ar",
                "message": "EPS"
            },
            {
                "language": "ja",
                "message": "EPS"
            },
            {
                "language": "pl",
                "message": "EPS"
            },
            {
                "language": "da",
                "message": "EPS"
            },
            {
                "language": "ro",
                "message": "EPS"
            },
            {
                "language": "nl",
                "message": "EPS"
            },
            {
                "language": "tr",
                "message": "EPS"
            }
        ],
        "countries": [
            "AT"
        ],
        "currencies": [
            "EUR"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/eps.svg",
        "isDirect": "false",
    },
    {
        "localName": "Euteller",
        "localLogoURL": "euteller.png",
        "paymentMethod": "apmgw_Euteller",
        "paymentMethodDisplayName": [
            {
                "language": "de",
                "message": "Euteller"
            },
            {
                "language": "en",
                "message": "Euteller"
            },
            {
                "language": "ru",
                "message": "Euteller"
            },
            {
                "language": "pt",
                "message": "Euteller"
            },
            {
                "language": "bg",
                "message": "Euteller"
            },
            {
                "language": "lt",
                "message": "Euteller"
            },
            {
                "language": "hr",
                "message": "Euteller"
            },
            {
                "language": "fr",
                "message": "Euteller"
            },
            {
                "language": "sl",
                "message": "Euteller"
            },
            {
                "language": "sr",
                "message": "Euteller"
            },
            {
                "language": "sv",
                "message": "Euteller"
            },
            {
                "language": "it",
                "message": "Euteller"
            },
            {
                "language": "zh",
                "message": "Euteller"
            },
            {
                "language": "iw",
                "message": "Euteller"
            },
            {
                "language": "es",
                "message": "Euteller"
            },
            {
                "language": "ar",
                "message": "Euteller"
            },
            {
                "language": "ja",
                "message": "Euteller"
            },
            {
                "language": "pl",
                "message": "Euteller"
            },
            {
                "language": "da",
                "message": "Euteller"
            },
            {
                "language": "ro",
                "message": "Euteller"
            },
            {
                "language": "nl",
                "message": "Euteller"
            },
            {
                "language": "tr",
                "message": "Euteller"
            }
        ],
        "countries": [
            "FI"
        ],
        "currencies": [
            "AUD",
            "CAD",
            "DKK",
            "EUR",
            "GBP",
            "HKD",
            "NOK",
            "SEK",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/euteller.svg",
        "isDirect": "false",
    },
    {
        "localName": "Fast_Bank_Transfer",
        "localLogoURL": "fastbanktransfer.png",
        "paymentMethod": "apmgw_Fast_Bank_Transfer",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Fast Bank Transfer"
            }
        ],
        "countries": [
            "AL",
            "DZ",
            "AS",
            "AD",
            "AO",
            "AI",
            "AQ",
            "AG",
            "AR",
            "AM",
            "AW",
            "AU",
            "AT",
            "AZ",
            "BS",
            "BH",
            "BD",
            "BB",
            "BY",
            "BE",
            "BZ",
            "BJ",
            "BM",
            "BT",
            "BO",
            "BA",
            "BW",
            "BV",
            "BR",
            "IO",
            "BN",
            "BG",
            "BF",
            "BI",
            "KH",
            "CM",
            "CA",
            "CV",
            "KY",
            "CF",
            "TD",
            "CL",
            "CX",
            "CC",
            "CO",
            "KM",
            "CG",
            "CD",
            "CK",
            "CR",
            "CI",
            "HR",
            "CU",
            "CY",
            "CZ",
            "DK",
            "DJ",
            "DM",
            "DO",
            "TL",
            "EC",
            "EG",
            "SV",
            "GQ",
            "ER",
            "EE",
            "ET",
            "FK",
            "FO",
            "FJ",
            "FI",
            "FR",
            "GF",
            "PF",
            "TF",
            "GA",
            "GM",
            "GE",
            "DE",
            "GH",
            "GI",
            "GR",
            "GL",
            "GD",
            "GP",
            "GU",
            "GT",
            "GN",
            "GW",
            "GY",
            "HT",
            "HM",
            "VA",
            "HN",
            "HU",
            "IS",
            "IN",
            "ID",
            "IR",
            "IQ",
            "IE",
            "IL",
            "IT",
            "JM",
            "JO",
            "KZ",
            "KE",
            "KI",
            "KP",
            "KR",
            "KW",
            "KG",
            "LA",
            "LV",
            "LB",
            "LS",
            "LR",
            "LY",
            "LI",
            "LT",
            "LU",
            "MO",
            "MK",
            "MG",
            "MW",
            "MY",
            "MV",
            "ML",
            "MT",
            "MH",
            "MQ",
            "MR",
            "MU",
            "YT",
            "MX",
            "FM",
            "MD",
            "MC",
            "MN",
            "MS",
            "MA",
            "MZ",
            "MM",
            "NA",
            "NR",
            "NP",
            "NL",
            "AN",
            "NC",
            "NZ",
            "NI",
            "NE",
            "NG",
            "NU",
            "NF",
            "MP",
            "OM",
            "PK",
            "PW",
            "PA",
            "PG",
            "PY",
            "PE",
            "PH",
            "PN",
            "PL",
            "PT",
            "PR",
            "QA",
            "RE",
            "RO",
            "RU",
            "RW",
            "SH",
            "KN",
            "LC",
            "PM",
            "VC",
            "WS",
            "SM",
            "ST",
            "SA",
            "SN",
            "SC",
            "SL",
            "SG",
            "SK",
            "SI",
            "SB",
            "SO",
            "ZA",
            "ES",
            "LK",
            "SD",
            "SR",
            "SJ",
            "SZ",
            "SE",
            "CH",
            "SY",
            "TW",
            "TJ",
            "TZ",
            "TH",
            "TG",
            "TK",
            "TO",
            "TT",
            "TN",
            "TM",
            "TC",
            "TV",
            "UG",
            "UA",
            "AE",
            "GB",
            "UM",
            "UY",
            "UZ",
            "VU",
            "VE",
            "VN",
            "VG",
            "VI",
            "WF",
            "EH",
            "YE",
            "ZM",
            "ZW",
            "AF",
            "ME",
            "RS",
            "AX",
            "BQ",
            "GG",
            "IM",
            "JE",
            "GS",
            "PS"
        ],
        "currencies": [
            "ANG",
            "ARS",
            "AUD",
            "AZN",
            "BBD",
            "BGN",
            "BHD",
            "BMD",
            "BND",
            "BOB",
            "BWP",
            "BYR",
            "CAD",
            "CHF",
            "CLP",
            "COP",
            "CRC",
            "CZK",
            "DJF",
            "DKK",
            "DOP",
            "DZD",
            "EEK",
            "EGP",
            "EUR",
            "GBP",
            "GEL",
            "GHS",
            "HKD",
            "HNL",
            "HRK",
            "HUF",
            "INR",
            "IQD",
            "ISK",
            "JMD",
            "JOD",
            "KGS",
            "KHR",
            "KRW",
            "KWD",
            "KZT",
            "LBP",
            "LKR",
            "LTL",
            "LVL",
            "MAD",
            "MDL",
            "MMK",
            "MOP",
            "MUR",
            "MXN",
            "NAD",
            "NGN",
            "NOK",
            "NZD",
            "OMR",
            "PAB",
            "PEN",
            "PGK",
            "PHP",
            "PKR",
            "PLN",
            "PYG",
            "QAR",
            "RON",
            "RSD",
            "RUB",
            "SAR",
            "SEK",
            "SGD",
            "THB",
            "TND",
            "TTD",
            "TZS",
            "UAH",
            "USD",
            "UYU",
            "VEF",
            "VND",
            "XAF",
            "XOF",
            "YER",
            "ZAR",
            "ZMW"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/fastbanktransfer.svg",
        "isDirect": "false",
    },
    {
        "localName": "Giropay",
        "localLogoURL": "giropay.png",
        "paymentMethod": "apmgw_Giropay",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Giropay"
            }
        ],
        "countries": [
            "DE"
        ],
        "currencies": [
            "EUR"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/giropay.svg",
        "isDirect": "false",
    },
    {
        "localName": "iDebit",
        "localLogoURL": "idebit.png",
        "paymentMethod": "apmgw_iDebit",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "iDebit"
            }
        ],
        "countries": [
            "CA"
        ],
        "currencies": [
            "CAD",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/idebit.svg",
        "isDirect": "false",
    },
    {
        "localName": "InstaDebit",
        "localLogoURL": "instadebit.png",
        "paymentMethod": "apmgw_InstaDebit",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "InstaDebit"
            }
        ],
        "countries": [
            "CA"
        ],
        "currencies": [
            "CAD",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/instadebit.svg",
        "isDirect": "false",
    },
    {
        "localName": "MISTERCASH",
        "localLogoURL": "mistercash.png",
        "paymentMethod": "apmgw_MISTERCASH",
        "paymentMethodDisplayName": [
            {
                "language": "de",
                "message": "MrCash"
            },
            {
                "language": "en",
                "message": "Bancontact"
            },
            {
                "language": "ru",
                "message": "MrCash"
            },
            {
                "language": "pt",
                "message": "MrCash"
            },
            {
                "language": "bg",
                "message": "MrCash"
            },
            {
                "language": "lt",
                "message": "MrCash"
            },
            {
                "language": "hr",
                "message": "MrCash"
            },
            {
                "language": "fr",
                "message": "MrCash"
            },
            {
                "language": "sr",
                "message": "MrCash"
            },
            {
                "language": "sv",
                "message": "MrCash"
            },
            {
                "language": "it",
                "message": "MrCash"
            },
            {
                "language": "zh",
                "message": "MrCash"
            },
            {
                "language": "iw",
                "message": "MrCash"
            },
            {
                "language": "es",
                "message": "MrCash"
            },
            {
                "language": "en",
                "message": "Bancontact"
            },
            {
                "language": "ar",
                "message": "MrCash"
            },
            {
                "language": "ja",
                "message": "MrCash"
            },
            {
                "language": "pl",
                "message": "MrCash"
            },
            {
                "language": "da",
                "message": "MrCash"
            },
            {
                "language": "ro",
                "message": "MrCash"
            },
            {
                "language": "nl",
                "message": "MrCash"
            },
            {
                "language": "tr",
                "message": "MrCash"
            }
        ],
        "countries": [
            "BE"
        ],
        "currencies": [
            "EUR"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/mistercash.svg",
        "isDirect": "false",
    },
    {
        "localName": "MULTIBANCO",
        "localLogoURL": "multibanco.png",
        "paymentMethod": "apmgw_MULTIBANCO",
        "paymentMethodDisplayName": [
            {
                "language": "de",
                "message": "MULTIBANCO"
            },
            {
                "language": "en",
                "message": "MULTIBANCO"
            },
            {
                "language": "en",
                "message": "MULTIBANCO"
            },
            {
                "language": "ru",
                "message": "MULTIBANCO"
            },
            {
                "language": "pt",
                "message": "MULTIBANCO"
            },
            {
                "language": "bg",
                "message": "MULTIBANCO"
            },
            {
                "language": "lt",
                "message": "MULTIBANCO"
            },
            {
                "language": "hr",
                "message": "MULTIBANCO"
            },
            {
                "language": "fr",
                "message": "MULTIBANCO"
            },
            {
                "language": "sl",
                "message": "MULTIBANCO"
            },
            {
                "language": "sr",
                "message": "MULTIBANCO"
            },
            {
                "language": "sv",
                "message": "MULTIBANCO"
            },
            {
                "language": "it",
                "message": "MULTIBANCO"
            },
            {
                "language": "zh",
                "message": "MULTIBANCO"
            },
            {
                "language": "iw",
                "message": "MULTIBANCO"
            },
            {
                "language": "es",
                "message": "MULTIBANCO"
            },
            {
                "language": "ar",
                "message": "MULTIBANCO"
            },
            {
                "language": "ja",
                "message": "MULTIBANCO"
            },
            {
                "language": "pl",
                "message": "MULTIBANCO"
            },
            {
                "language": "da",
                "message": "MULTIBANCO"
            },
            {
                "language": "ro",
                "message": "MULTIBANCO"
            },
            {
                "language": "nl",
                "message": "MULTIBANCO"
            },
            {
                "language": "tr",
                "message": "MULTIBANCO"
            }
        ],
        "countries": [
            "PT"
        ],
        "currencies": [
            "EUR"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/multibanco.svg",
        "isDirect": "false",
    },
    {
        "localName": "Neosurf",
        "localLogoURL": "neosurf.png",
        "paymentMethod": "apmgw_Neosurf",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Neosurf"
            }
        ],
        "countries": [
            "AU",
            "AT",
            "BE",
            "CA",
            "CO",
            "FR",
            "DE",
            "IT",
            "JP",
            "NL",
            "PL",
            "ES",
            "SE",
            "CH",
            "GB"
        ],
        "currencies": [
            "AUD",
            "CAD",
            "EUR",
            "GBP",
            "PLN"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/neosurf.svg",
        "isDirect": "false",
    },
    {
        "localName": "OnShop",
        "localLogoURL": "onshop.png",
        "paymentMethod": "apmgw_OnShop",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "OnShop"
            }
        ],
        "countries": [
            "IT"
        ],
        "currencies": [
            "EUR"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/onshop.svg",
        "isDirect": "false",
    },
    {
        "localName": "Oxxo",
        "localLogoURL": "oxxo.png",
        "paymentMethod": "apmgw_Oxxo",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Oxxo"
            }
        ],
        "countries": [
            "MX"
        ],
        "currencies": [
            "MXN",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/oxxo.svg",
        "isDirect": "false",
    },
    {
        "localName": "P24",
        "localLogoURL": "przelewy24.png",
        "paymentMethod": "apmgw_P24",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Przelewy24"
            }
        ],
        "countries": [
            "PL"
        ],
        "currencies": [
            "EUR",
            "PLN"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/przelewy24.svg",
        "isDirect": "false",
    },
    {
        "localName": "POLI",
        "localLogoURL": "poli.png",
        "paymentMethod": "apmgw_POLI",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "POLI"
            }
        ],
        "countries": [
            "AU",
            "NZ"
        ],
        "currencies": [
            "AUD",
            "CAD",
            "DKK",
            "EUR",
            "GBP",
            "HKD",
            "NOK",
            "SEK",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/poli.svg",
        "isDirect": "false",
    },
    {
        "localName": "POLINZ",
        "localLogoURL": "poli.png",
        "paymentMethod": "apmgw_POLINZ",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "POLI"
            }
        ],
        "countries": [
            "NZ"
        ],
        "currencies": [
            "AUD",
            "CAD",
            "DKK",
            "EUR",
            "GBP",
            "HKD",
            "NOK",
            "NZD",
            "SEK",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/poli.svg",
        "isDirect": "false",
    },
    {
        "localName": "PRZELEWY",
        "localLogoURL": "przelewy24.png",
        "paymentMethod": "apmgw_PRZELEWY",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Przelewy24"
            }
        ],
        "countries": [
            "PL"
        ],
        "currencies": [
            "AED",
            "AUD",
            "CAD",
            "DKK",
            "EUR",
            "GBP",
            "HKD",
            "NOK",
            "PLN",
            "SEK",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/przelewy24.svg",
        "isDirect": "false",
    },
    {
        "localName": "Pugglepay",
        "localLogoURL": "zimpler.png",
        "paymentMethod": "apmgw_Pugglepay",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Zimpler"
            }
        ],
        "countries": [
            "FI",
            "DE",
            "SE"
        ],
        "currencies": [
            "EUR",
            "SEK",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/zimpler.svg",
        "isDirect": "false",
    },
    {
        "localName": "QIWI",
        "localLogoURL": "qiwi.png",
        "paymentMethod": "apmgw_QIWI",
        "paymentMethodDisplayName": [
            {
                "language": "de",
                "message": "Qiwi"
            },
            {
                "language": "en",
                "message": "Qiwi"
            },
            {
                "language": "no",
                "message": "Qiwi"
            },
            {
                "language": "fi",
                "message": "Qiwi"
            },
            {
                "language": "ru",
                "message": "Qiwi"
            },
            {
                "language": "fr",
                "message": "Qiwi"
            }
        ],
        "countries": [
            "AM",
            "AZ",
            "BY",
            "EE",
            "GE",
            "IN",
            "IL",
            "JP",
            "KZ",
            "KR",
            "KG",
            "LV",
            "LT",
            "MD",
            "PA",
            "RO",
            "RU",
            "TJ",
            "TH",
            "TR",
            "UA",
            "GB",
            "US",
            "UZ",
            "VN"
        ],
        "currencies": [
            "EUR",
            "RUB",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/qiwi.svg",
        "isDirect": "false",
    },
    {
        "localName": "SepaExpress",
        "localLogoURL": "sepa.png",   
        "paymentMethod": "apmgw_Sepa",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Sepa"
            }
        ],
        "countries": [
            "AU",
            "BE",
            "BG",
            "HR",
            "CY",
            "CZ",
            "DK",
            "EE",
            "FI",
            "FR",
            "DE",
            "GR",
            "HU",
            "IS",
            "IE",
            "IT",
            "LV",
            "LI",
            "LT",
            "LU",
            "MT",
            "MC",
            "NL",
            "NO",
            "PL",
            "PT",
            "RO",
            "SM",
            "SK",
            "SI",
            "ES",
            "SE",
            "CH",
            "GB"
        ],
        "currencies": [
            "EUR"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/sepa.svg",
        "isDirect": "false",
    },
    {
        "localName": "Trustly",
        "localLogoURL": "trustly.png",   
        "paymentMethod": "apmgw_Trustly",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Trustly"
            }
        ],
        "countries": [
            "AT",
            "BE",
            "DK",
            "EE",
            "FI",
            "LT",
            "NL",
            "PL",
            "SK",
            "ES",
            "SE"
        ],
        "currencies": [
            "BGN",
            "CZK",
            "DKK",
            "EUR",
            "NOK",
            "PLN",
            "RON",
            "SEK",
            "UAH"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/trustly.svg",
        "isDirect": "false",
    },
    {
        "localName": "Trustpay",
        "localLogoURL": "trustpay.png",   
        "paymentMethod": "apmgw_Trustpay",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "TrustPay"
            }
        ],
        "countries": [
            "BG",
            "CZ",
            "EE",
            "HU",
            "LV",
            "LT",
            "PL",
            "RO",
            "SK",
            "SI"
        ],
        "currencies": [
            "BGN",
            "CHF",
            "CZK",
            "EUR",
            "HUF",
            "LTL",
            "LVL",
            "PLN",
            "RON"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/trustpay.svg",
        "isDirect": "false",
    },
    {
        "localName": "WebMoney",
        "localLogoURL": "webmoney.png",   
        "paymentMethod": "apmgw_WebMoney",
        "paymentMethodDisplayName": [
            {
                "language": "de",
                "message": "WebMoney "
            },
            {
                "language": "en",
                "message": "WebMoney"
            },
            {
                "language": "no",
                "message": "WebMoney"
            },
            {
                "language": "fi",
                "message": "WebMoney"
            },
            {
                "language": "ru",
                "message": "WebMoney "
            },
            {
                "language": "pt",
                "message": "WebMoney "
            },
            {
                "language": "bg",
                "message": "WebMoney "
            },
            {
                "language": "fr",
                "message": "WebMoney"
            },
            {
                "language": "sv",
                "message": "WebMoney "
            },
            {
                "language": "el",
                "message": "WebMoney "
            },
            {
                "language": "it",
                "message": "WebMoney "
            },
            {
                "language": "es",
                "message": "WebMoney "
            },
            {
                "language": "da",
                "message": "WebMoney "
            },
            {
                "language": "ro",
                "message": "WebMoney "
            }
        ],
        "countries": [
            "AM",
            "AZ",
            "BY",
            "GE",
            "KZ",
            "KG",
            "LV",
            "LT",
            "MD",
            "RU",
            "TJ",
            "TM",
            "UZ",
            "VN"
        ],
        "currencies": [
            "EUR",
            "RUB",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/webmoney.svg",
        "isDirect": "false",
    },
    {
        "localName": "WeChatPay",
        "localLogoURL": "wechat.png",
        "paymentMethod": "apmgw_WeChat",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "WeChat"
            }
        ],
        "countries": [
            "CN",
            "SG"
        ],
        "currencies": [
            "AUD",
            "CAD",
            "CNY",
            "EUR",
            "GBP",
            "JPY",
            "KRW",
            "NZD",
            "SGD",
            "THB",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/wechat.svg",
        "isDirect": "false",
    },
    {
        "localName": "YANDEX",
        "localLogoURL": "yandex_money.png",
        "paymentMethod": "apmgw_YANDEXMONEY",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Yandex.Money"
            }
        ],
        "countries": [
            "AM",
            "AZ",
            "BY",
            "GE",
            "KZ",
            "KG",
            "MD",
            "RU",
            "TJ",
            "TM",
            "UA",
            "UZ"
        ],
        "currencies": [
            "RUB",
            "USD"
        ],
        "logoURL": "https://cdn-int.safecharge.com/ppp_resources/01120913/resources/img/svg/yandex_money.svg",
        "isDirect": "false",
    },
    {
        "localName": "Zimpler_PPRO",
        "localLogoURL": "zimpler.png",
        "paymentMethod": "apmgw_Zimpler_PPRO",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Zimpler-PPRO"
            }
        ],
        "countries": [
            "FI",
            "SE"
        ],
        "currencies": [
            "EUR",
            "SEK"
        ],        
        "isDirect": "false",
    },
    {
        "localName": "Open_Bank",
        "localLogoURL": "open_banking.png",
        "paymentMethod": "apmgw_Open_Banking",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Open_Bank"
            }
        ],
        "countries": [
            "AT",
            "BE",
            "EE",
            "FR",
            "DE",
            "HU",
            "IT",
            "LV",
            "LT",
            "NL",
            "PL",
            "PT",
            "ES",
            "GB"
        ],
        "currencies": [
            "EUR",
            "GBP",
            "PLN",
            "USD"
        ],        
        "isDirect": "false",
        "openBankIdList": [
            { "code": "ngp-abna", "caption": "ABN AMRO Group" },
            { "code": "tabu", "caption": "AS TBB pank" },
            { "code": "ngp-asnb", "caption": "ASN bank" },
            { "code": "pa-alior", "caption": "Alior Bank" },
            { "code": "ngp-arsp", "caption": "Argenta" },
            { "code": "stet-bpri", "caption": "B.P RIves de Paris" },
            { "code": "ngp-opsk", "caption": "BAWAG PSK" },
            { "code": "ngp-inal", "caption": "BBVA" },
            { "code": "stet-bnpa-per", "caption": "BNP Paribas" },
            { "code": "stet-parb", "caption": "BNP Paribas Fortis" },
            { "code": "ngp-cbi-03296", "caption": "Banca Fideuram" },
            { "code": "ngp-bbpi", "caption": "Banco BPI" },
            { "code": "ngp-bsab", "caption": "Banco Sabadell" },
            { "code": "ngp-tota", "caption": "Banco Santander Totta" },
            { "code": "pa-millennium", "caption": "Bank Millennium" },
            { "code": "pa-pekao", "caption": "Bank Pekao" },
            { "code": "ngp-parx", "caption": "Banka Citadele" },
            { "code": "ngp-indu", "caption": "Bankas Citadele" },
            { "code": "ob-barclays", "caption": "Barclays Bank" },
            { "code": "stet-ctbk", "caption": "Beobank" },
            { "code": "bdp-budapest", "caption": "Budapest Bank" },
            { "code": "ngp-cibhpr", "caption": "CIB BanknPrivate" },
            { "code": "stet-cegee", "caption": "Caisse d'Epargne Grand Est Europe" },
            { "code": "ngp-cgdi", "caption": "Caixa Bank" },
            { "code": "ngp-mpio", "caption": "Caixa Económica Montepio Geral" },
            { "code": "ngp-cgdipt", "caption": "Caixa Geral de Depósitos (CGD)" },
            { "code": "ngp-parxee", "caption": "Citadele Pank" },
            { "code": "ngp-coba", "caption": "Commerzbank" },
            { "code": "ngp-ekrd", "caption": "Coop Pank" },
            { "code": "ngp-nica", "caption": "Crelan" },
            { "code": "stet-agrifrcc868", "caption": "Crédit Agricole de Centre France" },
            { "code": "stet-cmci", "caption": "Crédit Mutuel" },
            { "code": "ngp-deutdedb", "caption": "DB Privat- und Firmenkunden Bank Aktiengesellschaft (DB PFB AG)" },
            { "code": "sk-deutnl2a", "caption": "Deutsche Bank Aktiengesellschaft, Branch Amsterdam (DB AG (Netherlands)" },
            { "code": "ngp-giba", "caption": "Erste BankAustria" },
            { "code": "ob-hsbc", "caption": "HSBC Bank - Personal" },
            { "code": "ngp-hyve-hvb", "caption": "HypoVereinsbank - HVB Online Banking" },
            { "code": "ngp-bbru", "caption": "ING" },
            { "code": "ngp-ingb", "caption": "ING" },
            { "code": "ngp-ingba", "caption": "ING Austria" },
            { "code": "pa-ing", "caption": "ING Bank Śląski" },
            { "code": "ngp-ingdd", "caption": "ING-DiBa" },
            { "code": "ngp-cbi-03069", "caption": "Intesa Sanpaolo" },
            { "code": "sk-okhb", "caption": "K&H Bank" },
            { "code": "ngp-kred", "caption": "KBC Bank" },
            { "code": "ngp-bask", "caption": "Kutxabank" },
            { "code": "ngp-lhvb", "caption": "LHV Pank" },
            { "code": "ob-lloyds", "caption": "Lloyds Bank" },
            { "code": "ngp-mdba", "caption": "Medicinos Bankas" },
            { "code": "ngp-mult", "caption": "Meridian Trade Bank" },
            { "code": "ngp-mibp", "caption": "Millennium bcp" },
            { "code": "ngp-besc", "caption": "NOVO BANCO" },
            { "code": "ob-natwest", "caption": "NatWest Bank" },
            { "code": "sbas-otphu", "caption": "OTP Banka Hungary" },
            { "code": "pa-pko", "caption": "PKO Bank Polski" },
            { "code": "ngp-rabo", "caption": "Rabobank" },
            { "code": "ngp-rvvg", "caption": "Raiffeisen" },
            { "code": "ngp-ubrt", "caption": "Raiffeisen Bank" },
            { "code": "ob-rbs", "caption": "Royal Bank of Scotland" },
            { "code": "ngp-unla", "caption": "SEB Banka" },
            { "code": "ngp-cbvi", "caption": "SEB Bankas" },
            { "code": "ngp-eeuh", "caption": "SEB Pank" },
            { "code": "ngp-snsb", "caption": "SNS Bank" },
            { "code": "ob-santander", "caption": "Santander Bank" },
            { "code": "ngp-bsch", "caption": "Santander Spain" },
            { "code": "stet-soge", "caption": "Société Générale" },
            { "code": "sk-44152370", "caption": "Sparkasse an der Lippe" },
            { "code": "ngp-habaee", "caption": "Swedbank" },
            { "code": "ngp-uncrit", "caption": "UniCredit" },
            { "code": "ngp-bkau-24you", "caption": "UniCredit Bank Austria - 24 You" },
            { "code": "ngp-bacx", "caption": "UniCredit Bank Hungary" },
            { "code": "ngp-ucja", "caption": "Unicaja Banco" },
            { "code": "sk-fidu-genodef1gue", "caption": "Volks- und Raiffeisenbank eG" },
            { "code": "ngp-vbkt", "caption": "Volksbank Kärnten" },
            { "code": "mbank", "caption": "mBank" },
            { "code": "ngp-axab", "caption": "AXA Bank" },
            { "code": "sk-fidu-genoded1aac", "caption": "Aachener Bank eG" },
            { "code": "sk-fidu-genodes1abr", "caption": "Abtsgmünder Bank -Raiffeisen- eG" },
            { "code": "ngp-actv", "caption": "ActivoBank" },
            { "code": "ngp-bmpb", "caption": "Aion" },
            { "code": "sk-fidu-agbmdemmxxx", "caption": "Airbus Bank GmbH" },
            { "code": "airwallex-prod", "caption": "Airwallex" },
            { "code": "sk-fidu-akbades1xxx", "caption": "Aktivbank AG" },
            { "code": "sk-fidu-genodef1kev", "caption": "Allgäuer Volksbank eG Kempten-Sonthofen" },
            { "code": "ob-aib-bus", "caption": "Allied Irish Bank - Business" },
            { "code": "ob-aib-per", "caption": "Allied Irish Bank - Personal" },
            { "code": "sk-fidu-genodef1alx", "caption": "Alxing-Brucker Genossenschaftsbank eG" },
            { "code": "anpost", "caption": "An Post Bank" },
            { "code": "anpostmoneycc", "caption": "An Post Money Credit Card" },
            { "code": "avantmoney-app", "caption": "Avant Money App" },
            { "code": "avantcard", "caption": "Avantcard" },
            { "code": "ngp-axabfr", "caption": "Axa Banque" },
            { "code": "stet-bpaura", "caption": "B.P AUvergne et Rhône-Alpes" },
            { "code": "stet-bpalc", "caption": "B.P Alsace Lorraine Champagne" },
            { "code": "stet-bpaca", "caption": "B.P Aquitaine Centre" },
            { "code": "stet-bred", "caption": "B.P Bourgogne Franche Comté" },
            { "code": "stet-bpgo", "caption": "B.P Grand Ouest" },
            { "code": "stet-bpmed", "caption": "B.P Méditerranée" },
            { "code": "stet-bpoc", "caption": "B.P OCcitane" },
            { "code": "stet-bps", "caption": "B.P Sud" },
            { "code": "stet-bpvf", "caption": "B.P Val de France" },
            { "code": "stet-bpn", "caption": "B.P du Nord" },
            { "code": "sk-fidu-genode61bbb", "caption": "BBBank eG" },
            { "code": "pa-gpab", "caption": "BGŻ BNP Paribas - goonline" },
            { "code": "pa-ppab", "caption": "BGŻ BNP Paribas - planet" },
            { "code": "sk-brdecp", "caption": "BRD – Group Societe Generale Corporate" },
            { "code": "sk-brdert", "caption": "BRD – Group Societe Generale Retail" },
            { "code": "sk-69051410", "caption": "BSK Reichenau" },
            { "code": "sk-fidu-btvade61xxx", "caption": "BTV Bank für Tirol und Vorarlberg AG Zweigniederlassung Deutschland" },
            { "code": "ngp-bcoeesmmxxx", "caption": "Banco Cooperativo Espanol" },
            { "code": "sk-fidu-sabade5sxxx", "caption": "Bank 1 Saar eG" },
            { "code": "leumi-prod", "caption": "Bank Leumi" },
            { "code": "pa-pczt", "caption": "Bank Pocztowy24" },
            { "code": "pa-pczt-bus", "caption": "Bank Pocztowy24 (Corporate)" },
            { "code": "sk-fidu-genodem1bkc", "caption": "Bank für Kirche und Caritas eG" },
            { "code": "sk-fidu-genoded1dkd", "caption": "Bank für Kirche und Diakonie eG - KD-Bank" },
            { "code": "sk-fidu-genoded1bbe", "caption": "Bank im Bistum Essen eG" },
            { "code": "ob-bankofireland", "caption": "Bank of Ireland (UK)" },
            { "code": "ob-bankofireland-bus", "caption": "Bank of Ireland (UK) Business" },
            { "code": "ob-bankofscotland", "caption": "Bank of Scotland" },
            { "code": "ob-bankofscotland-bus", "caption": "Bank of Scotland Business" },
            { "code": "ob-bankofscotland-com", "caption": "Bank of Scotland Commercial" },
            { "code": "sk-fidu-wefzded1xxx", "caption": "Bank11 für Privatkunden und Handel GmbH" },
            { "code": "sk-fidu-anhode77xxx", "caption": "Bankhaus Anton Hafner KG" },
            { "code": "sk-fidu-bhbades1xxx", "caption": "Bankhaus Bauer Aktiengesellschaft" },
            { "code": "sk-fidu-bkmade61xxx", "caption": "Bankhaus E. Mayer AG" },
            { "code": "sk-fidu-elgedes1xxx", "caption": "Bankhaus Ellwanger & Geiger AG" },
            { "code": "sk-fidu-marbde6gxxx", "caption": "Bankhaus Gebr. Martin AG" },
            { "code": "sk-fidu-flesdemmxxx", "caption": "Bankhaus Max Flessa KG" },
            { "code": "sk-fidu-genodef1rts", "caption": "Bankhaus Rautenschlein AG" },
            { "code": "ngp-cahm", "caption": "Bankia" },
            { "code": "ngp-bkbk", "caption": "Bankinter" },
            { "code": "ngp-bkbke", "caption": "Bankinter" },
            { "code": "stet-cimm", "caption": "Banque BCP" },
            { "code": "stet-bchafr21xxx", "caption": "Banque Chalus" },
            { "code": "stet-kolb", "caption": "Banque Kolb" },
            { "code": "stet-layd", "caption": "Banque Laydernier" },
            { "code": "stet-bnug", "caption": "Banque Nuger" },
            { "code": "stet-ralp", "caption": "Banque Rhône-Alpes" },
            { "code": "stet-tarn", "caption": "Banque Tarneaud" },
            { "code": "stet-bqsav", "caption": "Banque de Savoie" },
            { "code": "ngp-bcoeesmm191", "caption": "Bantierra" },
            { "code": "ob-barclayscard-comm-payments", "caption": "Barclaycard Commercial Payments" },
            { "code": "ob-barclayscard", "caption": "Barclaycard UK" },
            { "code": "ob-barclays-bus", "caption": "Barclays Bank Business" },
            { "code": "ob-barclays-corp", "caption": "Barclays Corporate" },
            { "code": "ob-barclays-wm", "caption": "Barclays Wealth" },
            { "code": "sk-fidu-genodef1lbb", "caption": "Bayerische BodenseeBank - Raiffeisen - eG" },
            { "code": "ngp-gkcc", "caption": "Belfius Bank" },
            { "code": "sk-fidu-genoded1bgl", "caption": "Bensberger Bank eG" },
            { "code": "sk-10050000", "caption": "Berliner Sparkasse" },
            { "code": "sk-fidu-bevodebbxxx", "caption": "Berliner Volksbank eG Abteilung BIT" },
            { "code": "sk-fidu-genodes1bbf", "caption": "Bernhauser Bank eG" },
            { "code": "bitstamp", "caption": "Bitstamp" },
            { "code": "sk-fidu-genodes1bpf", "caption": "Bopfinger Bank Sechta-Ries eG" },
            { "code": "sk-21051275", "caption": "Bordesholmer Sparkasse AG" },
            { "code": "sk-fidu-genodef1brb", "caption": "Brandenburger Bank Volksbank-Raiffeisenbank eG" },
            { "code": "sk-29050000", "caption": "Bremer LB" },
            { "code": "sk-fidu-genodef1hb1", "caption": "Bremische Volksbank eG" },
            { "code": "britsm", "caption": "Brits Money" },
            { "code": "sk-fidu-genoded1brl", "caption": "Brühler Bank eG" },
            { "code": "sk-fidu-genode51bud", "caption": "Budenheimer Volksbank eG" },
            { "code": "cxep", "caption": "CAXTON" },
            { "code": "ngp-creg", "caption": "CBC Bank" },
            { "code": "ngp-cibhco", "caption": "CIB BankCorp" },
            { "code": "stet-cmmgo", "caption": "CMM Grand Ouest" },
            { "code": "stet-cmsou", "caption": "CMM Littoral du Sud Ouest" },
            { "code": "sk-fidu-genodef1whd", "caption": "CVW-Privatbank AG" },
            { "code": "stet-ceapc", "caption": "Caisse d'Epargne Aquitaine Poitou-Charentes" },
            { "code": "stet-cepal", "caption": "Caisse d'Epargne Auvergne et Limousin" },
            { "code": "stet-cebfc", "caption": "Caisse d'Epargne Bourgogne Franche-Comté" },
            { "code": "stet-cebpl", "caption": "Caisse d'Epargne Bretagne-Pays De Loire" },
            { "code": "stet-cecaz", "caption": "Caisse d'Epargne Côte d'Azur" },
            { "code": "stet-cehdf", "caption": "Caisse d'Epargne Hauts de France" },
            { "code": "stet-bpce", "caption": "Caisse d'Epargne Ile De France" },
            { "code": "stet-celr", "caption": "Caisse d'Epargne Languedoc-Roussillon" },
            { "code": "stet-celda", "caption": "Caisse d'Epargne Loire Drôme Ardèche" },
            { "code": "stet-celc", "caption": "Caisse d'Epargne Loire-Centre" },
            { "code": "stet-cemp", "caption": "Caisse d'Epargne Midi-Pyrénées" },
            { "code": "stet-cen", "caption": "Caisse d'Epargne Normandie" },
            { "code": "stet-cera", "caption": "Caisse d'Epargne Rhône Alpes" },
            { "code": "ngp-cccm", "caption": "Caixa Central de Crédito Agrícola Mútuo" },
            { "code": "ngp-bcoeesmm159", "caption": "Caixa Popular" },
            { "code": "ngp-bcoeesmm162", "caption": "Caixa Rural Benicarlo" },
            { "code": "ngp-bcoeesmm070", "caption": "Caixa Rural Galega" },
            { "code": "ngp-bcoeesmm174", "caption": "Caja Rural El Salvador Vinaroz" },
            { "code": "ngp-bcoeesmm098", "caption": "Caja Rural Nueva Carteya" },
            { "code": "ngp-bcoeesmm005", "caption": "Caja Rural Orihuela" },
            { "code": "ngp-bcoeesmm166", "caption": "Caja Rural San Isidro les Coves Vinroma" },
            { "code": "ngp-bcoeesmm115", "caption": "Caja Rural de Adamuz" },
            { "code": "ngp-bcoeesmm150", "caption": "Caja Rural de Albal" },
            { "code": "ngp-bcoeesmm117", "caption": "Caja Rural de Algemesi" },
            { "code": "ngp-bcoeesmm059", "caption": "Caja Rural de Asturias" },
            { "code": "ngp-bcoeesmm089", "caption": "Caja Rural de Baena" },
            { "code": "ngp-bcoeesmm104", "caption": "Caja Rural de Canete de las Torres" },
            { "code": "ngp-bcoeesmm127", "caption": "Caja Rural de Casas Ibanez" },
            { "code": "ngp-bcoeesmm009", "caption": "Caja Rural de Extremadura" },
            { "code": "ngp-bcoeesmm018", "caption": "Caja Rural de Fuentealamo" },
            { "code": "ngp-bcoeesmm007", "caption": "Caja Rural de Gijon" },
            { "code": "ngp-bcoeesmm023", "caption": "Caja Rural de Granada" },
            { "code": "ngp-bcoeesmm067", "caption": "Caja Rural de Jaen" },
            { "code": "ngp-bcoeesmm096", "caption": "Caja Rural de Lu0027alcudia" },
            { "code": "ngp-bcoeesmm008", "caption": "Caja Rural de Navarra" },
            { "code": "ngp-bcoeesmm134", "caption": "Caja Rural de Onda" },
            { "code": "ngp-bcoeesmm138", "caption": "Caja Rural de Rural Nostra" },
            { "code": "ngp-bcoeesmm016", "caption": "Caja Rural de Salamanca" },
            { "code": "ngp-bcoeesmm111", "caption": "Caja Rural de San Isidro Vall Du0027uxo" },
            { "code": "ngp-bcoeesmm113", "caption": "Caja Rural de San Jose Alcora" },
            { "code": "ngp-bcoeesmm130", "caption": "Caja Rural de San Jose de Almassora" },
            { "code": "ngp-bcoeesmm017", "caption": "Caja Rural de Soria" },
            { "code": "ngp-bcoeesmm080", "caption": "Caja Rural de Teruel" },
            { "code": "ngp-bcoeesmm020", "caption": "Caja Rural de Utrera" },
            { "code": "ngp-bcoeesmm144", "caption": "Caja Rural de Villamalea" },
            { "code": "ngp-bcoeesmm085", "caption": "Caja Rural de Zamora" },
            { "code": "ngp-bcoeesmm076", "caption": "Caja Siete" },
            { "code": "ngp-bcoeesmm060", "caption": "Cajaviva" },
            { "code": "canvas", "caption": "Canvas" },
            { "code": "chillmoney", "caption": "Chill Money" },
            { "code": "chillmoney-app", "caption": "Chill Money App" },
            { "code": "pbgvir", "caption": "Clearsettle" },
            { "code": "cecgbp", "caption": "Contis" },
            { "code": "ob-coutts", "caption": "Coutts & Company" },
            { "code": "credecard", "caption": "Credecard" },
            { "code": "pa-agricole", "caption": "Credit Agricole" },
            { "code": "pa-agricole-bus", "caption": "Credit Agricole (Corporate)" },
            { "code": "sk-fidu-genode51cro", "caption": "Cronbank AG" },
            { "code": "stet-agrifrpp813", "caption": "Crédit Agricole Alpes Provence" },
            { "code": "stet-agrifrpp872", "caption": "Crédit Agricole Alsace Vosges" },
            { "code": "stet-agrifrcc847", "caption": "Crédit Agricole Atlantique Vendée" },
            { "code": "stet-agrifrcc887", "caption": "Crédit Agricole Brie Picardie" },
            { "code": "stet-agrifrcc810", "caption": "Crédit Agricole Champagne-Bourgogne" },
            { "code": "stet-agrifrcc824", "caption": "Crédit Agricole Charente Périgord" },
            { "code": "stet-agrifrcc817", "caption": "Crédit Agricole Charente-Maritime Deux-Sèvres" },
            { "code": "stet-agrifrcc812", "caption": "Crédit Agricole Nord Midi-Pyrénées" },
            { "code": "stet-agrifrcc869", "caption": "Crédit Agricole Pyrénées Gascogne" },
            { "code": "stet-agrifrcc871", "caption": "Crédit Agricole Sud Méditerranée" },
            { "code": "stet-agrifrcc839", "caption": "Crédit Agricole Sud Rhône-Alpes" },
            { "code": "stet-agrifrcc831", "caption": "Crédit Agricole Toulouse31" },
            { "code": "stet-agrifrcc844", "caption": "Crédit Agricole Val De France" },
            { "code": "stet-agrifrcc833", "caption": "Crédit Agricole d'Aquitaine" },
            { "code": "stet-agrifrcc836", "caption": "Crédit Agricole d'Ille et Vilaine" },
            { "code": "stet-agrifrcc848", "caption": "Crédit Agricole de Centre Loire" },
            { "code": "stet-agrifrcc878", "caption": "Crédit Agricole de Centre-Est" },
            { "code": "stet-agrifrcc825", "caption": "Crédit Agricole de Franche-Comté" },
            { "code": "stet-agrigpgxxxx", "caption": "Crédit Agricole de Guadeloupe" },
            { "code": "stet-agrifrcc845", "caption": "Crédit Agricole de Loire Haute-Loire" },
            { "code": "stet-agrifrcc861", "caption": "Crédit Agricole de Lorraine" },
            { "code": "stet-agrifrpp867", "caption": "Crédit Agricole de Nord De France" },
            { "code": "stet-agrifrcc866", "caption": "Crédit Agricole de Normandie" },
            { "code": "stet-agrifrcc883", "caption": "Crédit Agricole de Normandie Seine" },
            { "code": "stet-agrifrcc892", "caption": "Crédit Agricole de Paris et d'Ile de France" },
            { "code": "stet-agrimcm1xxx", "caption": "Crédit Agricole de Provence Côtes d'Azur" },
            { "code": "stet-agrifrcc879", "caption": "Crédit Agricole de l'Anjou et du Maine" },
            { "code": "stet-agrifrcc820", "caption": "Crédit Agricole de la Corse" },
            { "code": "stet-agrimqmxxxx", "caption": "Crédit Agricole de la Martinique et de la Guyane" },
            { "code": "stet-agrirerxxxx", "caption": "Crédit Agricole de la Réunion" },
            { "code": "stet-agrifrcc894", "caption": "Crédit Agricole de la Touraine et du Poitou" },
            { "code": "stet-agrifrcc822", "caption": "Crédit Agricole des Côtes d'Armor" },
            { "code": "stet-agrifrpp881", "caption": "Crédit Agricole des Savoie" },
            { "code": "stet-agrifrcc895", "caption": "Crédit Agricole du Centre Ouest" },
            { "code": "stet-agrifrcc829", "caption": "Crédit Agricole du Finistère" },
            { "code": "stet-agrifrcc835", "caption": "Crédit Agricole du Languedoc" },
            { "code": "stet-agrifrpp860", "caption": "Crédit Agricole du Morbihan" },
            { "code": "stet-agrifrcc802", "caption": "Crédit Agricole du Nord Est" },
            { "code": "stet-ccop", "caption": "Crédit Coopératif" },
            { "code": "stet-cic", "caption": "Crédit Industriel et Commercial (CIC)" },
            { "code": "stet-nord", "caption": "Crédit du Nord" },
            { "code": "ngp-pbnkdeff", "caption": "DB Privat- und Firmenkunden Bank Aktiengesellschaft (Postbank eine Niederlassung der DB PFB AG)" },
            { "code": "obldcu", "caption": "DCU" },
            { "code": "ngp-byla", "caption": "DKB (Das kann Bank)" },
            { "code": "sk-fidu-genodem1dkm", "caption": "DKM Darlehnskasse Münster eG" },
            { "code": "ob-danske-bus", "caption": "Danske Bank - Business" },
            { "code": "ob-danske-per", "caption": "Danske Bank - Personal" },
            { "code": "sk-fidu-genodes1dbe", "caption": "Dettinger Bank eG" },
            { "code": "sk-deutptpl", "caption": "Deutsche Bank Aktiengesellschaft - Sucursal em Portugal (DB AG (Portugal))" },
            { "code": "ngp-deutbebe", "caption": "Deutsche Bank Aktiengesellschaft, Branch Brüssel (DB AG (Belgium))" },
            { "code": "sk-deutgb2l", "caption": "Deutsche Bank Aktiengesellschaft, Branch London (DB AG (UK))" },
            { "code": "sk-deutlull", "caption": "Deutsche Bank Aktiengesellschaft, Branch Luxemburg (DB AG (Luxembourg))" },
            { "code": "sk-deutfrpp", "caption": "Deutsche Bank Aktiengesellschaft, Branch Paris (DB AG (France))" },
            { "code": "sk-deutczpx", "caption": "Deutsche Bank Aktiengesellschaft, Branch Prag (DB AG (Prague))" },
            { "code": "sk-deutatww", "caption": "Deutsche Bank Aktiengesellschaft, Branch Vienna (DB AG (Austria))" },
            { "code": "sk-deuthu2b", "caption": "Deutsche Bank Aktiengesellschaft,Branch Budapest (DB AG (Hungary))" },
            { "code": "sk-deutplpx", "caption": "Deutsche Bank Polska Spólka Akcyjna (Dt. Bank Polska S.A.)" },
            { "code": "sk-fidu-genodef1dvr", "caption": "Dithmarscher Volks- und Raiffeisenbank eG" },
            { "code": "sk-fidu-genodes1ehi", "caption": "Donau-Iller Bank eG" },
            { "code": "sk-fidu-chdbdehhxxx", "caption": "Donner & Reuschel AG" },
            { "code": "sk-fidu-genodem1dor", "caption": "Dortmunder Volksbank eG" },
            { "code": "draggb", "caption": "Dragon (Trilogy)" },
            { "code": "sk-fidu-edekdehhxxx", "caption": "EDEKABANK AG Hamburg" },
            { "code": "sk-fidu-genodes1ech", "caption": "Echterdinger Bank eG" },
            { "code": "sk-fidu-genodef1efo", "caption": "Eckernförder Bank eG Volksbank-Raiffeisenbank" },
            { "code": "elavon", "caption": "Elavon Financial Services" },
            { "code": "sk-fidu-genodef1mep", "caption": "Emsländische Volksbank eG" },
            { "code": "engage", "caption": "Engage" },
            { "code": "pa-envl", "caption": "EnveloBank" },
            { "code": "pa-envl-bus", "caption": "EnveloBank  (Corporate)" },
            { "code": "sk-fidu-erfbde8exxx", "caption": "Erfurter Bank eG" },
            { "code": "sk-87054000", "caption": "Erzgebirgssparkasse" },
            { "code": "sk-fidu-genodef1etk", "caption": "EthikBank eG" },
            { "code": "sk-fidu-dlghdeb1xxx", "caption": "Eurocity Bank AG" },
            { "code": "sk-fidu-genodef1ek1", "caption": "Evangelische Bank eG" },
            { "code": "sk-fidu-genodef1n03", "caption": "Evenord-Bank eG-KG" },
            { "code": "sk-fidu-genodes1fed", "caption": "Federseebank eG" },
            { "code": "ffrees", "caption": "Ffrees" },
            { "code": "stet-geba", "caption": "Fintro" },
            { "code": "ob-firstdirect", "caption": "First Direct Bank" },
            { "code": "ob-firsttrust-bus", "caption": "First Trust Bank - Business" },
            { "code": "ob-firsttrust-per", "caption": "First Trust Bank - Personal" },
            { "code": "sk-fidu-genodef1fbk", "caption": "Frankenberger Bank Raiffeisenbank eG" },
            { "code": "sk-fidu-fbgadef1xxx", "caption": "Frankfurter Bankgesellschaft (Deutschland) AG" },
            { "code": "sk-50050201", "caption": "Frankfurter Sparkasse" },
            { "code": "sk-fidu-ffvbdeffxxx", "caption": "Frankfurter Volksbank eG" },
            { "code": "sk-fidu-genodef1fsr", "caption": "Freisinger Bank eG Volksbank-Raiffeisenbank" },
            { "code": "sk-21050170", "caption": "Förde SPK" },
            { "code": "sk-fidu-fubkde71xxx", "caption": "Fürst Fugger Privatbank Aktiengesellschaft" },
            { "code": "sk-fidu-fucede77xxx", "caption": "Fürstlich Castell'sche Bank Credit-Casse AG" },
            { "code": "sk-fidu-genodem1gbe", "caption": "GENO BANK ESSEN eG" },
            { "code": "sk-fidu-genodem1gls", "caption": "GLS Gemeinschaftsbank eG" },
            { "code": "sk-fidu-grebdeh1xxx", "caption": "GRENKE BANK AG" },
            { "code": "sk-fidu-genode51mz6", "caption": "Genobank Mainz eG" },
            { "code": "sk-fidu-genodef1mir", "caption": "Genossenschaftsbank Unterallgäu eG" },
            { "code": "sk-fidu-genodes1gws", "caption": "Genossenschaftsbank Weil im Schönbuch eG" },
            { "code": "sk-fidu-genodef1m07", "caption": "Genossenschaftsbank eG München" },
            { "code": "pa-gnb", "caption": "Getin Noble Bank" },
            { "code": "ghib", "caption": "Ghana International Bank" },
            { "code": "sk-fidu-genoded1gbm", "caption": "Gladbacher Bank AG von 1922" },
            { "code": "grpl", "caption": "Global Reach Group" },
            { "code": "ngp-bcoeesmm190", "caption": "GlobalCaja" },
            { "code": "sk-fidu-genodef1nev", "caption": "Grafschafter Volksbank eG" },
            { "code": "sk-fidu-genodef1m04", "caption": "HAUSBANK MÜNCHEN eG Bank für Haus- und Grundbesitz" },
            { "code": "ob-hsbc-bus", "caption": "HSBC Bank - Business" },
            { "code": "sk-fidu-genode61hag", "caption": "Hagnauer Volksbank eG" },
            { "code": "ob-halifax", "caption": "Halifax" },
            { "code": "sk-20050550", "caption": "Hamburger Sparkasse" },
            { "code": "sk-fidu-genodef1hh2", "caption": "Hamburger Volksbank eG" },
            { "code": "sk-fidu-vohade2hxxx", "caption": "Hannoversche Volksbank eG" },
            { "code": "sk-fidu-genodef1qlb", "caption": "Harzer Volksbank eG" },
            { "code": "sk-81052000", "caption": "Harzsparkasse" },
            { "code": "sk-fidu-genode61hd1", "caption": "Heidelberger Volksbank eG" },
            { "code": "sk-fidu-genodes1hdh", "caption": "Heidenheimer Volksbank eG" },
            { "code": "stet-gebabe", "caption": "Hello" },
            { "code": "stet-hello", "caption": "Hello Bank" },
            { "code": "sk-fidu-hoebde61xxx", "caption": "Hoerner Bank AG" },
            { "code": "ngp-hyve-global", "caption": "HypoVereinsbank - UC eBanking Global" },
            { "code": "ngp-hyve-global-uk", "caption": "HypoVereinsbank - UC eBanking Global - UK branch" },
            { "code": "sk-fidu-genodef1wlt", "caption": "Hümmlinger Volksbank eG" },
            { "code": "sk-fidu-genode51hut", "caption": "Hüttenberger Bank eG" },
            { "code": "sk-fidu-ibkbdes1xxx", "caption": "IBM Deutschland Kreditbank GmbH" },
            { "code": "ngp-ingbf", "caption": "ING France" },
            { "code": "ngp-ingde", "caption": "ING Spain" },
            { "code": "ngp-cazr", "caption": "Ibercaja" },
            { "code": "pa-idea", "caption": "Idea Bank" },
            { "code": "pa-idea-bus", "caption": "Idea Bank (Corporate)" },
            { "code": "sk-fidu-plfgde5aikb", "caption": "Ikano Bank AB (publ) Zweigniederlassung Deutschland" },
            { "code": "pa-ingo", "caption": "Inteligo" },
            { "code": "pa-ingo-bus", "caption": "Inteligo (Corporate)" },
            { "code": "sk-fidu-ibbfde81xxx", "caption": "Internationales Bankhaus Bodensee AG" },
            { "code": "ngp-kredbb", "caption": "KBC Brussels Bank" },
            { "code": "sk-57751310", "caption": "KSK Ahrweiler" },
            { "code": "sk-80053722", "caption": "KSK Anhalt-Bitterfeld" },
            { "code": "sk-72050101", "caption": "KSK Augsburg" },
            { "code": "sk-85550000", "caption": "KSK Bautzen" },
            { "code": "sk-26551540", "caption": "KSK Bersenbrück" },
            { "code": "sk-65450070", "caption": "KSK Biberach" },
            { "code": "sk-56250030", "caption": "KSK Birkenfeld" },
            { "code": "sk-58650030", "caption": "KSK Bitburg-Prüm" },
            { "code": "sk-60350130", "caption": "KSK Böblingen" },
            { "code": "sk-81055000", "caption": "KSK Börde" },
            { "code": "sk-86055462", "caption": "KSK Döbeln" },
            { "code": "sk-30150200", "caption": "KSK Düsseldorf" },
            { "code": "sk-82057070", "caption": "KSK Eichsfeld" },
            { "code": "sk-61150020", "caption": "KSK Esslingen-Nürtingen" },
            { "code": "sk-38250110", "caption": "KSK Euskirchen" },
            { "code": "sk-64251060", "caption": "KSK Freudenstadt" },
            { "code": "sk-70350000", "caption": "KSK Garmisch-Partenkirchen" },
            { "code": "sk-50750094", "caption": "KSK Gelnhausen" },
            { "code": "sk-82052020", "caption": "KSK Gotha" },
            { "code": "sk-25651325", "caption": "KSK Grafschaft Diepholz" },
            { "code": "sk-50852553", "caption": "KSK Groß-Gerau" },
            { "code": "sk-61050000", "caption": "KSK Göppingen" },
            { "code": "sk-48051580", "caption": "KSK Halle (Westf.)" },
            { "code": "sk-63250030", "caption": "KSK Heidenheim" },
            { "code": "sk-62050000", "caption": "KSK Heilbronn" },
            { "code": "sk-31251220", "caption": "KSK Heinsberg" },
            { "code": "sk-23052750", "caption": "KSK Herzogtum Lauenburg" },
            { "code": "sk-84054040", "caption": "KSK Hildburghausen" },
            { "code": "sk-54050220", "caption": "KSK Kaiserslautern" },
            { "code": "sk-75051565", "caption": "KSK Kelheim" },
            { "code": "sk-54051550", "caption": "KSK Kusel" },
            { "code": "sk-37050299", "caption": "KSK Köln" },
            { "code": "sk-51150018", "caption": "KSK Limburg" },
            { "code": "sk-60450050", "caption": "KSK Ludwigsburg" },
            { "code": "sk-57650010", "caption": "KSK Mayen" },
            { "code": "sk-26552286", "caption": "KSK Melle" },
            { "code": "sk-71152570", "caption": "KSK Miesbach-Tegernsee" },
            { "code": "sk-17054040", "caption": "KSK Märkisch-Oderland" },
            { "code": "sk-70250150", "caption": "KSK München Starnberg Ebersberg" },
            { "code": "sk-82054052", "caption": "KSK Nordhausen" },
            { "code": "sk-26750001", "caption": "KSK Nordhorn" },
            { "code": "sk-26250001", "caption": "KSK Northeim" },
            { "code": "sk-61450050", "caption": "KSK Ostalb" },
            { "code": "sk-65050110", "caption": "KSK Ravensburg" },
            { "code": "sk-64050000", "caption": "KSK Reutlingen" },
            { "code": "sk-56051790", "caption": "KSK Rhein-Hunsrück" },
            { "code": "sk-64250040", "caption": "KSK Rottweil" },
            { "code": "sk-83050505", "caption": "KSK Saale-Orla" },
            { "code": "sk-83050303", "caption": "KSK Saalfeld-Rudolstadt" },
            { "code": "sk-59350110", "caption": "KSK Saarlouis" },
            { "code": "sk-59450010", "caption": "KSK Saarpfalz" },
            { "code": "sk-53051396", "caption": "KSK Schlüchtern" },
            { "code": "sk-52052154", "caption": "KSK Schwalm-Eder" },
            { "code": "sk-65351050", "caption": "KSK Sigmaringen" },
            { "code": "sk-25851660", "caption": "KSK Soltau" },
            { "code": "sk-59251020", "caption": "KSK St. Wendel" },
            { "code": "sk-24151116", "caption": "KSK Stade" },
            { "code": "sk-40351060", "caption": "KSK Steinfurt" },
            { "code": "sk-81050555", "caption": "KSK Stendal" },
            { "code": "sk-29151700", "caption": "KSK Syke" },
            { "code": "sk-71052050", "caption": "KSK Traunstein-Trostberg" },
            { "code": "sk-64350070", "caption": "KSK Tuttlingen" },
            { "code": "sk-64150020", "caption": "KSK Tübingen" },
            { "code": "sk-29152670", "caption": "KSK Verden" },
            { "code": "sk-58651240", "caption": "KSK Vulkaneifel" },
            { "code": "sk-60250010", "caption": "KSK Waiblingen" },
            { "code": "sk-25152375", "caption": "KSK Walsrode" },
            { "code": "sk-51151919", "caption": "KSK Weilburg" },
            { "code": "sk-47853520", "caption": "KSK Wiedenbrück" },
            { "code": "sk-73450000", "caption": "KSSK Kaufbeuren" },
            { "code": "sk-71152680", "caption": "KSSK Wasserburg" },
            { "code": "sk-52050353", "caption": "Kasseler Sparkasse" },
            { "code": "ngp-ftno", "caption": "Keytrade Bank" },
            { "code": "sk-fidu-genodef1kil", "caption": "Kieler Volksbank eG" },
            { "code": "kubb", "caption": "Kube" },
            { "code": "sk-fidu-genodef1ks2", "caption": "Kurhessische Landbank eG" },
            { "code": "sk-82055000", "caption": "Kyffhäusersparkasse Artern-Sondershausen" },
            { "code": "sk-60050101", "caption": "LBBW" },
            { "code": "stet-crly", "caption": "LCL" },
            { "code": "sk-fidu-genodef1m05", "caption": "LIGA Bank eG" },
            { "code": "sk-28050100", "caption": "LSK Oldenburg" },
            { "code": "stet-psst", "caption": "La Banque Postale" },
            { "code": "sk-fidu-genode51rew", "caption": "Landbank Horlofftal eG" },
            { "code": "sk-fidu-laredeffxxx", "caption": "Landwirtschaftliche Rentenbank" },
            { "code": "sk-fidu-genodef1lvb", "caption": "Leipziger Volksbank eG" },
            { "code": "ob-lloyds-bus", "caption": "Lloyds Bank Business" },
            { "code": "ob-lloyds-com", "caption": "Lloyds Bank Commercial" },
            { "code": "sk-fidu-genodef1m06", "caption": "MERKUR BANK KGaA" },
            { "code": "sk-fidu-genodef1mkb", "caption": "MKB Mittelstandskreditbank AG" },
            { "code": "sk-fidu-mlpbde61xxx", "caption": "MLP Finanzdienstleistungen" },
            { "code": "sk-hbwe", "caption": "Magnet Bank" },
            { "code": "sk-fidu-mvbmde55xxx", "caption": "Mainzer Volksbank eG" },
            { "code": "ob-msfs", "caption": "Marks & Spencer Financial Services" },
            { "code": "sk-fidu-genodem1men", "caption": "Mendener Bank eG" },
            { "code": "sk-16050000", "caption": "Mittelbrandenburgische SPK in Potsdam" },
            { "code": "monmon", "caption": "Money Mona" },
            { "code": "ob-monzo", "caption": "Monzo Bank" },
            { "code": "morses", "caption": "Morses Club" },
            { "code": "monosu", "caption": "MotoNovo" },
            { "code": "sk-fidu-genodem1hgn", "caption": "Märkische Bank eG" },
            { "code": "sk-fidu-genodef1m01", "caption": "Münchner Bank eG" },
            { "code": "sk-fidu-mlbkdeh1mue", "caption": "Münsterländische Bank Thie & Co. KG" },
            { "code": "sk-15050100", "caption": "Müritz-Sparkasse" },
            { "code": "sk-25050000", "caption": "NORD/LB" },
            { "code": "sk-51050015", "caption": "Nassauische Sparkasse" },
            { "code": "ob-natwest-bus", "caption": "NatWest Bank Bankline" },
            { "code": "ob-natwest-com", "caption": "NatWest Bank ClearSpend" },
            { "code": "ob-nationwide", "caption": "Nationwide Building Society" },
            { "code": "pa-nest", "caption": "Nest Bank" },
            { "code": "pa-nest-bus", "caption": "Nest Bank (Corporate)" },
            { "code": "sk-35650000", "caption": "Niederrheinische Sparkasse RheinLippe" },
            { "code": "sk-21750000", "caption": "Nord-Ostsee SPK" },
            { "code": "afterbanks-nordea_fi", "caption": "Nordea Suomi" },
            { "code": "sk-fidu-genodef1nds", "caption": "Nordthüringer Volksbank eG" },
            { "code": "ngp-norsde51", "caption": "Norisbank GmbH" },
            { "code": "sk-fidu-genodef1ogk", "caption": "North Channel Bank GmbH & Co. KG" },
            { "code": "sk-fidu-genodes1ons", "caption": "Onstmettinger Bank eG" },
            { "code": "ngp-gpba", "caption": "Orange" },
            { "code": "sk-fidu-genodef1ler", "caption": "Ostfriesische Volksbank eG" },
            { "code": "sk-13050000", "caption": "OstseeSparkasse Rostock" },
            { "code": "sk-85050300", "caption": "Ostsächsische Sparkasse Dresden" },
            { "code": "sk-fidu-oscbdeh1xxx", "caption": "Otto M. Schröder Bank AG" },
            { "code": "ob-modelo", "caption": "Ozone Modelo Test Bank" },
            { "code": "sk-fidu-genodef1p01", "caption": "PSD Bank Berlin-Brandenburg eG" },
            { "code": "sk-fidu-genodef1p02", "caption": "PSD Bank Braunschweig eG" },
            { "code": "sk-fidu-genodef1p09", "caption": "PSD Bank Hannover eG" },
            { "code": "sk-fidu-genodef1p06", "caption": "PSD Bank Hessen-Thüringen eG" },
            { "code": "sk-fidu-genodef1p10", "caption": "PSD Bank Karlsruhe-Neustadt eG" },
            { "code": "sk-fidu-genodef1p11", "caption": "PSD Bank Kiel eG" },
            { "code": "sk-fidu-genodef1p12", "caption": "PSD Bank Koblenz eG" },
            { "code": "sk-fidu-genodef1p14", "caption": "PSD Bank München eG" },
            { "code": "sk-fidu-genodef1p08", "caption": "PSD Bank Nord eG" },
            { "code": "sk-fidu-genodef1p17", "caption": "PSD Bank Nürnberg eG" },
            { "code": "sk-fidu-genodef1p05", "caption": "PSD Bank Rhein-Ruhr eG" },
            { "code": "sk-fidu-genodef1p20", "caption": "PSD Bank RheinNeckarSaar eG" },
            { "code": "sk-fidu-genodef1p13", "caption": "PSD Bank West eG" },
            { "code": "sk-fidu-genodef1p15", "caption": "PSD Bank Westfalen-Lippe eG" },
            { "code": "sk-fidu-genoded1pax", "caption": "Pax-Bank eG Kirche und Caritas" },
            { "code": "sk-fidu-genodef1hst", "caption": "Pommersche Volksbank eG" },
            { "code": "pnb-bus", "caption": "Punjab National Bank - Corporate" },
            { "code": "pnb-per", "caption": "Punjab National Bank - Retail" },
            { "code": "sk-fidu-sabudes1xxx", "caption": "RSB Retail+Service Bank GmbH" },
            { "code": "sk-fidu-genodef1fys", "caption": "Raiffeisen - meine Bank eG" },
            { "code": "sk-fidu-genode61wib", "caption": "Raiffeisen Privatbank eG" },
            { "code": "sk-fidu-genodef1lau", "caption": "Raiffeisen Spar+Kreditbank eG" },
            { "code": "sk-fidu-genoded1rsc", "caption": "Raiffeisen-Bank Eschweiler eG" },
            { "code": "sk-fidu-genodef1ab1", "caption": "Raiffeisen-Volksbank Aschaffenburg eG" },
            { "code": "sk-fidu-genodef1sff", "caption": "Raiffeisen-Volksbank Bad Staffelstein eG" },
            { "code": "sk-fidu-genodef1don", "caption": "Raiffeisen-Volksbank Donauwörth eG" },
            { "code": "sk-fidu-genodef1asg", "caption": "Raiffeisen-Volksbank Ebersberg eG" },
            { "code": "sk-fidu-genodef1mar", "caption": "Raiffeisen-Volksbank Fresena eG" },
            { "code": "sk-fidu-genodef1has", "caption": "Raiffeisen-Volksbank Haßberge eG" },
            { "code": "sk-fidu-genodef1hmf", "caption": "Raiffeisen-Volksbank Hermsdorfer Kreuz eG" },
            { "code": "sk-fidu-genodef1nst", "caption": "Raiffeisen-Volksbank Neustadt eG" },
            { "code": "sk-fidu-genodef1bkw", "caption": "Raiffeisen-Volksbank Oder-Spree eG" },
            { "code": "sk-fidu-genodef1noe", "caption": "Raiffeisen-Volksbank Ries eG" },
            { "code": "sk-fidu-genodef1tus", "caption": "Raiffeisen-Volksbank Tüssling-Unterneukirchen eG" },
            { "code": "sk-fidu-genodef1var", "caption": "Raiffeisen-Volksbank Varel - Nordenham eG" },
            { "code": "sk-fidu-genodef1wdn", "caption": "Raiffeisen-Volksbank Wemding eG" },
            { "code": "sk-fidu-genodef1upl", "caption": "Raiffeisen-Volksbank eG" },
            { "code": "sk-fidu-genodef1adz", "caption": "Raiffeisenbank Adelzhausen-Sielenbach eG" },
            { "code": "sk-fidu-genodes1har", "caption": "Raiffeisenbank Aichhalden-Hardt- Sulgen eG" },
            { "code": "sk-fidu-genodes1aid", "caption": "Raiffeisenbank Aidlingen eG" },
            { "code": "sk-fidu-genodef1ail", "caption": "Raiffeisenbank Aindling eG" },
            { "code": "sk-fidu-genodef1ait", "caption": "Raiffeisenbank Aitrang-Ruderatshofen eG" },
            { "code": "sk-fidu-genodef1erg", "caption": "Raiffeisenbank Altdorf-Ergolding eG" },
            { "code": "sk-fidu-genodef1fec", "caption": "Raiffeisenbank Altdorf-Feucht eG" },
            { "code": "sk-fidu-genodef1hga", "caption": "Raiffeisenbank Alteglofsheim-Hagelstadt eG" },
            { "code": "sk-fidu-genode61alr", "caption": "Raiffeisenbank Altschweier eG" },
            { "code": "sk-fidu-genodef1rgs", "caption": "Raiffeisenbank Am Goldenen Steig eG" },
            { "code": "sk-fidu-genodef1age", "caption": "Raiffeisenbank Anger eG" },
            { "code": "sk-fidu-genodef1gsb", "caption": "Raiffeisenbank Aresing-Gerolsbach eG" },
            { "code": "sk-fidu-genodef1arf", "caption": "Raiffeisenbank Arnstorf eG" },
            { "code": "sk-fidu-genodef1asu", "caption": "Raiffeisenbank Aschau-Samerberg eG" },
            { "code": "sk-fidu-genodef1hzh", "caption": "Raiffeisenbank Aschberg eG" },
            { "code": "sk-fidu-genodef1auo", "caption": "Raiffeisenbank Auerbach-Freihung eG" },
            { "code": "sk-fidu-genodef1zus", "caption": "Raiffeisenbank Augsburger Land West eG" },
            { "code": "sk-fidu-genodes1aul", "caption": "Raiffeisenbank Aulendorf eG" },
            { "code": "sk-fidu-genodef1ktz", "caption": "Raiffeisenbank Bad Kötzting eG" },
            { "code": "sk-fidu-genodes1sag", "caption": "Raiffeisenbank Bad Saulgau eG" },
            { "code": "sk-fidu-genodes1rbs", "caption": "Raiffeisenbank Bad Schussenried eG" },
            { "code": "sk-fidu-genodef1wds", "caption": "Raiffeisenbank Bad Windsheim eG" },
            { "code": "sk-fidu-genodef1egb", "caption": "Raiffeisenbank Baisweil-Eggenthal-Friesenried eG" },
            { "code": "sk-fidu-genodef1beh", "caption": "Raiffeisenbank Bechhofen eG" },
            { "code": "sk-fidu-genodef1bln", "caption": "Raiffeisenbank Beilngries eG" },
            { "code": "sk-fidu-genodes1bgh", "caption": "Raiffeisenbank Berghülen eG" },
            { "code": "sk-fidu-genodef1eur", "caption": "Raiffeisenbank Beuerberg-Eurasburg eG" },
            { "code": "sk-fidu-genodes1war", "caption": "Raiffeisenbank Biberach eG" },
            { "code": "sk-fidu-genodef1zir", "caption": "Raiffeisenbank Bibertgrund eG" },
            { "code": "sk-fidu-genodef1bin", "caption": "Raiffeisenbank Bidingen eG" },
            { "code": "sk-fidu-genodef1pbg", "caption": "Raiffeisenbank Biebergrund-Petersberg eG" },
            { "code": "sk-fidu-genodef1bsi", "caption": "Raiffeisenbank Bissingen eG" },
            { "code": "sk-fidu-genodef1boi", "caption": "Raiffeisenbank Bobingen eG" },
            { "code": "sk-fidu-genodef1bor", "caption": "Raiffeisenbank Borken Nordhessen eG" },
            { "code": "sk-fidu-genodef1buk", "caption": "Raiffeisenbank Bruck eG" },
            { "code": "sk-fidu-genodef1ebv", "caption": "Raiffeisenbank Buch-Eching eG" },
            { "code": "sk-fidu-genodef1bgb", "caption": "Raiffeisenbank Burgebrach-Stegaurach eG" },
            { "code": "sk-fidu-genodef1but", "caption": "Raiffeisenbank Butjadingen- Abbehausen eG" },
            { "code": "sk-fidu-genodes1boe", "caption": "Raiffeisenbank Böllingertal eG" },
            { "code": "sk-fidu-genodes1rvg", "caption": "Raiffeisenbank Bühlertal eG" },
            { "code": "sk-fidu-genodef1bhd", "caption": "Raiffeisenbank Bütthard-Gaukönigshofen eG" },
            { "code": "sk-fidu-genodef1cha", "caption": "Raiffeisenbank Chamer Land eG" },
            { "code": "sk-fidu-genodef1sbc", "caption": "Raiffeisenbank Chiemgau-Nord-Obing eG" },
            { "code": "sk-fidu-genode61den", "caption": "Raiffeisenbank Denzlingen-Sexau eG" },
            { "code": "sk-fidu-genodef1dim", "caption": "Raiffeisenbank Dietersheim und Umgebung eG" },
            { "code": "sk-fidu-genodes1rdh", "caption": "Raiffeisenbank Donau-Heuberg eG" },
            { "code": "sk-fidu-genodef1sfd", "caption": "Raiffeisenbank Ebrachgrund eG" },
            { "code": "sk-fidu-genodef1wdf", "caption": "Raiffeisenbank Ehekirchen-Oberhausen eG" },
            { "code": "sk-fidu-genodes1reh", "caption": "Raiffeisenbank Ehingen-Hochsträß eG" },
            { "code": "sk-fidu-genodef1enb", "caption": "Raiffeisenbank Eichenbühl und Umgebung eG" },
            { "code": "sk-fidu-genoded1kai", "caption": "Raiffeisenbank Eifeltor eG" },
            { "code": "sk-fidu-genodef1hte", "caption": "Raiffeisenbank Elbmarsch eG" },
            { "code": "sk-fidu-genodef1eau", "caption": "Raiffeisenbank Elsavatal eG" },
            { "code": "sk-fidu-genodef1kbl", "caption": "Raiffeisenbank Emsland-Mitte eG" },
            { "code": "sk-fidu-genodef1edr", "caption": "Raiffeisenbank Erding eG" },
            { "code": "sk-fidu-genoded1loe", "caption": "Raiffeisenbank Erkelenz eG" },
            { "code": "sk-fidu-genodes1erl", "caption": "Raiffeisenbank Erlenbach eG" },
            { "code": "sk-fidu-genode61ers", "caption": "Raiffeisenbank Ersingen eG" },
            { "code": "sk-fidu-genodef1nkn", "caption": "Raiffeisenbank Eschlkam-Lam- Lohberg-Neukirchen b. Hl. Blut eG" },
            { "code": "sk-fidu-genodef1ena", "caption": "Raiffeisenbank Essenbach eG" },
            { "code": "sk-fidu-genodef1efd", "caption": "Raiffeisenbank Estenfeld-Bergtheim eG" },
            { "code": "sk-fidu-genodef1fks", "caption": "Raiffeisenbank Falkenstein-Wörth eG" },
            { "code": "sk-fidu-genoded1fkh", "caption": "Raiffeisenbank Fischenich-Kendenich eG" },
            { "code": "sk-fidu-genodef1wef", "caption": "Raiffeisenbank Flachsmeer eG" },
            { "code": "sk-fidu-genodef1fls", "caption": "Raiffeisenbank Floß eG" },
            { "code": "sk-fidu-genodes1rfs", "caption": "Raiffeisenbank Frankenhardt-Stimpfach eG" },
            { "code": "sk-fidu-genodef1fwh", "caption": "Raiffeisenbank Frankenwinheim und Umgebung eG" },
            { "code": "sk-fidu-genode61fsh", "caption": "Raiffeisenbank Freinsheim eG" },
            { "code": "sk-fidu-genodef1hwa", "caption": "Raiffeisenbank Fränkische Schweiz eG" },
            { "code": "sk-fidu-genodef1fch", "caption": "Raiffeisenbank Fuchstal-Denklingen eG" },
            { "code": "sk-fidu-genodef1gfs", "caption": "Raiffeisenbank Gefrees eG" },
            { "code": "sk-fidu-genodef1gpf", "caption": "Raiffeisenbank Geiselhöring-Pfaffenberg eG" },
            { "code": "sk-fidu-genodes1gei", "caption": "Raiffeisenbank Geislingen-Rosenfeld eG" },
            { "code": "sk-fidu-genodef1gil", "caption": "Raiffeisenbank Gilching eG" },
            { "code": "sk-fidu-genodef1gmu", "caption": "Raiffeisenbank Gmund am Tegernsee eG" },
            { "code": "sk-fidu-genodef1gth", "caption": "Raiffeisenbank Gotha eG" },
            { "code": "sk-fidu-genodef1grt", "caption": "Raiffeisenbank Grainet eG" },
            { "code": "sk-fidu-genodef1gdg", "caption": "Raiffeisenbank Greding-Thalmässing eG" },
            { "code": "sk-fidu-genodef1hfg", "caption": "Raiffeisenbank Griesstätt-Halfing eG" },
            { "code": "sk-fidu-genodef1gmr", "caption": "Raiffeisenbank Grimma eG" },
            { "code": "sk-fidu-genodes1rgr", "caption": "Raiffeisenbank Gruibingen eG" },
            { "code": "sk-fidu-genode51gwb", "caption": "Raiffeisenbank Grävenwiesbach eG" },
            { "code": "sk-fidu-genoded1egy", "caption": "Raiffeisenbank Gymnich eG" },
            { "code": "sk-fidu-genodef1hma", "caption": "Raiffeisenbank Haag-Gars-Maitenbeth eG" },
            { "code": "sk-fidu-genodef1rht", "caption": "Raiffeisenbank Hallertau eG" },
            { "code": "sk-fidu-genode61det", "caption": "Raiffeisenbank Hardt-Bruhrain eG" },
            { "code": "sk-fidu-genodef1wba", "caption": "Raiffeisenbank Heilsbronn-Windsbach eG" },
            { "code": "sk-fidu-genodef1hbw", "caption": "Raiffeisenbank Hengersberg-Schöllnach eG" },
            { "code": "sk-fidu-genodef1hob", "caption": "Raiffeisenbank Heroldsbach eG" },
            { "code": "sk-fidu-genodef1hsb", "caption": "Raiffeisenbank Hersbruck eG" },
            { "code": "sk-fidu-genodef1woh", "caption": "Raiffeisenbank HessenNord eG" },
            { "code": "sk-fidu-genodef1htf", "caption": "Raiffeisenbank Hiltenfingen eG" },
            { "code": "sk-fidu-genodef1hsc", "caption": "Raiffeisenbank Hirschau eG" },
            { "code": "sk-fidu-genodef1szf", "caption": "Raiffeisenbank Hochfranken West eG" },
            { "code": "sk-fidu-genodes1ibr", "caption": "Raiffeisenbank Hohenloher Land eG" },
            { "code": "sk-fidu-genodef1hzo", "caption": "Raiffeisenbank Holzkirchen-Otterfing eG" },
            { "code": "sk-fidu-genodef1hbg", "caption": "Raiffeisenbank Höchberg eG" },
            { "code": "sk-fidu-genodef1ich", "caption": "Raiffeisenbank Ichenhausen eG" },
            { "code": "sk-fidu-genoded1irr", "caption": "Raiffeisenbank Irrel eG" },
            { "code": "sk-fidu-genodef1hhs", "caption": "Raiffeisenbank Isar-Loisachtal eG" },
            { "code": "sk-fidu-genoded1kaa", "caption": "Raiffeisenbank Kaarst eG" },
            { "code": "sk-fidu-genode61vok", "caption": "Raiffeisenbank Kaiserstuhl eG" },
            { "code": "sk-fidu-genodef1kab", "caption": "Raiffeisenbank Kalbe-Bismark eG" },
            { "code": "sk-fidu-genoded1ksl", "caption": "Raiffeisenbank Kastellaun eG" },
            { "code": "sk-fidu-genoded1keh", "caption": "Raiffeisenbank Kehrig eG" },
            { "code": "sk-fidu-genodef1sfo", "caption": "Raiffeisenbank Kempten-Oberallgäu eG" },
            { "code": "sk-fidu-genode61kbr", "caption": "Raiffeisenbank Kieselbronn eG" },
            { "code": "sk-fidu-genodef1oki", "caption": "Raiffeisenbank Kirchweihtal eG" },
            { "code": "sk-fidu-genode51kif", "caption": "Raiffeisenbank Kirtorf eG" },
            { "code": "sk-fidu-genodef1mri", "caption": "Raiffeisenbank Kissing-Mering eG" },
            { "code": "sk-fidu-genodef1obr", "caption": "Raiffeisenbank Kitzinger Land eG" },
            { "code": "sk-fidu-genodef1n08", "caption": "Raiffeisenbank Knoblauchsland eG" },
            { "code": "sk-fidu-genodef1abs", "caption": "Raiffeisenbank Kreis Kelheim eG" },
            { "code": "sk-fidu-genodef1kc2", "caption": "Raiffeisenbank Küps-Mitwitz-Stockheim eG" },
            { "code": "sk-fidu-genodef1lru", "caption": "Raiffeisenbank Lorup eG" },
            { "code": "sk-fidu-genodef1gem", "caption": "Raiffeisenbank Main-Spessart eG" },
            { "code": "sk-fidu-genodes1rma", "caption": "Raiffeisenbank Maitis eG" },
            { "code": "sk-fidu-genodef1rnm", "caption": "Raiffeisenbank Maßbach eG" },
            { "code": "sk-fidu-genodef1wrn", "caption": "Raiffeisenbank Mecklenburger Seenplatte eG" },
            { "code": "sk-fidu-genoded1mlw", "caption": "Raiffeisenbank Mehring-Leiwen eG" },
            { "code": "sk-fidu-genodes1meh", "caption": "Raiffeisenbank Mehrstetten eG" },
            { "code": "sk-fidu-genodef1bbt", "caption": "Raiffeisenbank Mittelschwaben eG" },
            { "code": "sk-fidu-genodef1mtw", "caption": "Raiffeisenbank Mittenwald eG" },
            { "code": "sk-fidu-genoded1mok", "caption": "Raiffeisenbank Moselkrampen eG" },
            { "code": "sk-fidu-genodes1rml", "caption": "Raiffeisenbank Mutlangen eG" },
            { "code": "sk-fidu-genodes1rmo", "caption": "Raiffeisenbank Mötzingen eG" },
            { "code": "sk-fidu-genodef1m08", "caption": "Raiffeisenbank München-Nord eG" },
            { "code": "sk-fidu-genodef1m03", "caption": "Raiffeisenbank München-Süd eG" },
            { "code": "sk-fidu-genoded1fin", "caption": "Raiffeisenbank Nahe eG" },
            { "code": "sk-fidu-genodef1nm1", "caption": "Raiffeisenbank Neumarkt i.d.OPf. eG" },
            { "code": "sk-fidu-genodef1rwz", "caption": "Raiffeisenbank Neumarkt- St. Veit - Reischach eG" },
            { "code": "sk-fidu-genoded1asn", "caption": "Raiffeisenbank Neustadt eG" },
            { "code": "sk-fidu-genodef1new", "caption": "Raiffeisenbank Neustadt-Vohenstrauß eG" },
            { "code": "sk-fidu-genodes1rba", "caption": "Raiffeisenbank Niedere Alb eG" },
            { "code": "sk-fidu-genode51nwa", "caption": "Raiffeisenbank Niederwallmenach eG" },
            { "code": "sk-fidu-genodef1elb", "caption": "Raiffeisenbank Nordkreis Landsberg eG" },
            { "code": "sk-fidu-genode51abh", "caption": "Raiffeisenbank Nördliche Bergstraße eG" },
            { "code": "sk-fidu-genodef1ndl", "caption": "Raiffeisenbank Nüdlingen eG" },
            { "code": "sk-fidu-genodef1obd", "caption": "Raiffeisenbank Oberaudorf eG" },
            { "code": "sk-fidu-genodes1rog", "caption": "Raiffeisenbank Oberes Gäu eG" },
            { "code": "sk-fidu-genodef1bto", "caption": "Raiffeisenbank Oberferrieden-Burgthann eG" },
            { "code": "sk-fidu-genodef1mga", "caption": "Raiffeisenbank Oberland eG" },
            { "code": "sk-fidu-genodef1alk", "caption": "Raiffeisenbank Obermain Nord eG" },
            { "code": "sk-fidu-genodef1kem", "caption": "Raiffeisenbank Oberpfalz NordWest eG" },
            { "code": "sk-fidu-genodef1dst", "caption": "Raiffeisenbank Oberpfalz Süd eG" },
            { "code": "sk-fidu-genodes1ote", "caption": "Raiffeisenbank Oberteuringen-Meckenbeuren eG" },
            { "code": "sk-fidu-genode51obu", "caption": "Raiffeisenbank Oberursel eG" },
            { "code": "sk-fidu-genodef1ol2", "caption": "Raiffeisenbank Oldenburg eG" },
            { "code": "sk-fidu-genodef1ort", "caption": "Raiffeisenbank Ortenburg-Kirchberg v.W. eG" },
            { "code": "sk-fidu-genodef1npp", "caption": "Raiffeisenbank Ostprignitz - Ruppin eG" },
            { "code": "sk-fidu-genodes1ott", "caption": "Raiffeisenbank Ottenbach eG" },
            { "code": "sk-fidu-genodef1pst", "caption": "Raiffeisenbank Parkstetten eG" },
            { "code": "sk-fidu-genodef1pfa", "caption": "Raiffeisenbank Pfaffenhausen eG" },
            { "code": "sk-fidu-genodef1odz", "caption": "Raiffeisenbank Pfaffenhofen a.d.Glonn eG" },
            { "code": "sk-fidu-genodef1pei", "caption": "Raiffeisenbank Pfaffenwinkel eG" },
            { "code": "sk-fidu-genodef1pff", "caption": "Raiffeisenbank Pfeffenhausen- Rottenburg-Wildenberg eG" },
            { "code": "sk-fidu-genodef1bpl", "caption": "Raiffeisenbank Plankstetten AG" },
            { "code": "sk-fidu-genodef1rme", "caption": "Raiffeisenbank RSA eG" },
            { "code": "sk-fidu-genodef1rig", "caption": "Raiffeisenbank Raisting eG" },
            { "code": "sk-fidu-genodef1rse", "caption": "Raiffeisenbank Rastede eG" },
            { "code": "sk-fidu-genodef1rzk", "caption": "Raiffeisenbank Rattiszell-Konzell eG" },
            { "code": "sk-fidu-genodef1r02", "caption": "Raiffeisenbank Regensburg-Wenzenbach eG" },
            { "code": "sk-fidu-genodef1ref", "caption": "Raiffeisenbank Regenstauf eG" },
            { "code": "sk-fidu-genodef1rli", "caption": "Raiffeisenbank Rehling eG" },
            { "code": "sk-fidu-genodes1rrg", "caption": "Raiffeisenbank Reute-Gaisbeuren eG" },
            { "code": "sk-fidu-genode51rbu", "caption": "Raiffeisenbank Ried eG" },
            { "code": "sk-fidu-genodef1rbl", "caption": "Raiffeisenbank Riedenburg-Lobsing eG" },
            { "code": "sk-fidu-genodes1heu", "caption": "Raiffeisenbank Rosenstein eG" },
            { "code": "sk-fidu-genodef1swr", "caption": "Raiffeisenbank Roth-Schwabach eG" },
            { "code": "sk-fidu-genodef1tei", "caption": "Raiffeisenbank Rupertiwinkel eG" },
            { "code": "sk-fidu-genode51shm", "caption": "Raiffeisenbank Schaafheim eG" },
            { "code": "sk-fidu-genodef1wfn", "caption": "Raiffeisenbank Schrobenhausener Land eG" },
            { "code": "sk-fidu-genodes1sbb", "caption": "Raiffeisenbank Schrozberg- Rot am See eG" },
            { "code": "sk-fidu-genodef1blt", "caption": "Raiffeisenbank Schwaben Mitte eG" },
            { "code": "sk-fidu-genodef1smu", "caption": "Raiffeisenbank Schwabmünchen eG" },
            { "code": "sk-fidu-genodef1hse", "caption": "Raiffeisenbank Seebachgrund eG" },
            { "code": "sk-fidu-genodef1hua", "caption": "Raiffeisenbank Singoldtal eG" },
            { "code": "sk-fidu-genodef1szv", "caption": "Raiffeisenbank Sinzing eG" },
            { "code": "sk-fidu-genodes1rsf", "caption": "Raiffeisenbank Sondelfingen eG" },
            { "code": "sk-fidu-genodef1swo", "caption": "Raiffeisenbank St. Wolfgang-Schwindkirchen eG" },
            { "code": "sk-fidu-genodef1lst", "caption": "Raiffeisenbank Stauden eG" },
            { "code": "sk-fidu-genodef1sga", "caption": "Raiffeisenbank Steingaden eG" },
            { "code": "sk-fidu-genodes1saa", "caption": "Raiffeisenbank Steinheim eG" },
            { "code": "sk-fidu-genodef1sr2", "caption": "Raiffeisenbank Straubing eG" },
            { "code": "sk-fidu-genodef1orf", "caption": "Raiffeisenbank Strücklingen- Idafehn eG" },
            { "code": "sk-fidu-genodef1szh", "caption": "Raiffeisenbank Sulzbach-Rosenberg eG" },
            { "code": "sk-fidu-genode61dur", "caption": "Raiffeisenbank Südhardt eG" },
            { "code": "sk-fidu-genodef1hzn", "caption": "Raiffeisenbank Südl. Bayerischer Wald eG" },
            { "code": "sk-fidu-genodef1rhp", "caption": "Raiffeisenbank Südliches Ostallgäu eG" },
            { "code": "sk-fidu-genodef1grs", "caption": "Raiffeisenbank Südstormarn Mölln eG" },
            { "code": "sk-fidu-genodef1tae", "caption": "Raiffeisenbank Taufkirchen-Oberneukirchen eG" },
            { "code": "sk-fidu-genodef1ths", "caption": "Raiffeisenbank Thannhausen eG" },
            { "code": "sk-fidu-genodef1tha", "caption": "Raiffeisenbank Thurnauer Land eG" },
            { "code": "sk-fidu-genodes1tun", "caption": "Raiffeisenbank Tüngental eG" },
            { "code": "sk-fidu-genodef1trh", "caption": "Raiffeisenbank Türkheim eG" },
            { "code": "sk-fidu-genodef1nui", "caption": "Raiffeisenbank Unteres Inntal eG" },
            { "code": "sk-fidu-genodef1sdm", "caption": "Raiffeisenbank Unteres Vilstal eG" },
            { "code": "sk-fidu-genodef1bwi", "caption": "Raiffeisenbank Unteres Zusamtal eG" },
            { "code": "sk-fidu-genodef1wed", "caption": "Raiffeisenbank Volkacher Mainschleife - Wiesentheid eG" },
            { "code": "sk-fidu-genodef1vlm", "caption": "Raiffeisenbank Volkmarsen eG" },
            { "code": "sk-fidu-genodes1rvs", "caption": "Raiffeisenbank Vordersteinenberg eG" },
            { "code": "sk-fidu-genoded1rbc", "caption": "Raiffeisenbank Voreifel eG" },
            { "code": "sk-fidu-genodef1wgo", "caption": "Raiffeisenbank Wald-Görisried eG" },
            { "code": "sk-fidu-genodef1waa", "caption": "Raiffeisenbank Waldaschaff-Heigenbrücken eG" },
            { "code": "sk-fidu-genodef1wak", "caption": "Raiffeisenbank Wallgau-Krün eG" },
            { "code": "sk-fidu-genodes1rwa", "caption": "Raiffeisenbank Wangen eG" },
            { "code": "sk-fidu-genodef1wsd", "caption": "Raiffeisenbank Wegscheid eG" },
            { "code": "sk-fidu-genodef1gu1", "caption": "Raiffeisenbank Weißenburg-Gunzenhausen eG" },
            { "code": "sk-fidu-genoded1wlg", "caption": "Raiffeisenbank Welling eG" },
            { "code": "sk-fidu-genodef1raw", "caption": "Raiffeisenbank Werratal-Landeck eG" },
            { "code": "sk-fidu-genodef1brn", "caption": "Raiffeisenbank Wesermarsch-Süd eG" },
            { "code": "sk-fidu-genodef1wwa", "caption": "Raiffeisenbank Westallgäu eG" },
            { "code": "sk-fidu-genoded1wsc", "caption": "Raiffeisenbank Westeifel eG" },
            { "code": "sk-fidu-genodes1rwn", "caption": "Raiffeisenbank Westhausen eG" },
            { "code": "sk-fidu-genodef1moo", "caption": "Raiffeisenbank Westkreis Fürstenfeldbruck eG" },
            { "code": "sk-fidu-genodef1wwm", "caption": "Raiffeisenbank Wiesedermeer- Wiesede-Marcardsmoor eG" },
            { "code": "sk-fidu-genodes1wim", "caption": "Raiffeisenbank Wimsheim-Mönsheim eG" },
            { "code": "sk-fidu-genode61wyh", "caption": "Raiffeisenbank Wyhl eG" },
            { "code": "sk-fidu-genodef1wsz", "caption": "Raiffeisenbank Wüstenselbitz eG" },
            { "code": "sk-fidu-genoded1bpu", "caption": "Raiffeisenbank Zeller Land eG" },
            { "code": "sk-fidu-genodef1zor", "caption": "Raiffeisenbank Zorneding eG" },
            { "code": "sk-fidu-genodef1nhd", "caption": "Raiffeisenbank am Dreisessel eG" },
            { "code": "sk-fidu-genodef1spk", "caption": "Raiffeisenbank am Kulm eG" },
            { "code": "sk-fidu-genodef1bta", "caption": "Raiffeisenbank eG Baunatal" },
            { "code": "sk-fidu-genodef1bch", "caption": "Raiffeisenbank eG Büchen-Crivitz-Hagenow-Plate" },
            { "code": "sk-fidu-genodef1deg", "caption": "Raiffeisenbank eG Deggendorf-Plattling-Sonnenwald" },
            { "code": "sk-fidu-genode61elz", "caption": "Raiffeisenbank eG Elztal" },
            { "code": "sk-fidu-genode51of2", "caption": "Raiffeisenbank eG Offenbach / M.-Bieber" },
            { "code": "sk-fidu-genodef1san", "caption": "Raiffeisenbank eG Scharrel" },
            { "code": "sk-fidu-genode51arz", "caption": "Raiffeisenbank eG Unterwesterwald" },
            { "code": "sk-fidu-genodef1hdw", "caption": "Raiffeisenbank eG, Handewitt" },
            { "code": "sk-fidu-genodef1rlb", "caption": "Raiffeisenbank eG, Lauenburg" },
            { "code": "sk-fidu-genodef1lzn", "caption": "Raiffeisenbank eG, Leezen" },
            { "code": "sk-fidu-genodef1mml", "caption": "Raiffeisenbank eG, Moormerland" },
            { "code": "sk-fidu-genodef1ows", "caption": "Raiffeisenbank eG, Owschlag" },
            { "code": "sk-fidu-genodef1rrz", "caption": "Raiffeisenbank eG, Ratzeburg" },
            { "code": "sk-fidu-genodef1rdb", "caption": "Raiffeisenbank eG, Rodenbach" },
            { "code": "sk-fidu-genodef1sst", "caption": "Raiffeisenbank eG, Seestermühe" },
            { "code": "sk-fidu-genoded1smr", "caption": "Raiffeisenbank eG, Simmerath" },
            { "code": "sk-fidu-genodef1stv", "caption": "Raiffeisenbank eG, Struvenhütten" },
            { "code": "sk-fidu-genodef1tob", "caption": "Raiffeisenbank eG, Todenbüttel" },
            { "code": "sk-fidu-genodef1dta", "caption": "Raiffeisenbank im Allgäuer Land eG" },
            { "code": "sk-fidu-genode61gun", "caption": "Raiffeisenbank im Breisgau eG" },
            { "code": "sk-fidu-genodef1gah", "caption": "Raiffeisenbank im Donautal eG" },
            { "code": "sk-fidu-genodef1glu", "caption": "Raiffeisenbank im Fuldaer Land eG" },
            { "code": "sk-fidu-genodef1slz", "caption": "Raiffeisenbank im Grabfeld eG" },
            { "code": "sk-fidu-genodes1rcw", "caption": "Raiffeisenbank im Kreis Calw eG" },
            { "code": "sk-fidu-genodef1tie", "caption": "Raiffeisenbank im Landkreis Passau-Nord eG" },
            { "code": "sk-fidu-genodef1mib", "caption": "Raiffeisenbank im Oberland eG" },
            { "code": "sk-fidu-genodef1hem", "caption": "Raiffeisenbank im Oberpfälzer Jura eG" },
            { "code": "sk-fidu-genodef1dsb", "caption": "Raiffeisenbank Ühlfeld-Dachsbach eG" },
            { "code": "sk-fidu-genodef1gkt", "caption": "Raiffeisenbank. Tattenhausen-Großkarolinenfeld eG" },
            { "code": "sk-fidu-genode51erb", "caption": "Raiffeisenkasse Erbes-Büdesheim und Umgebung eG" },
            { "code": "ob-revolut", "caption": "Revolut" },
            { "code": "ob-revolut-eea", "caption": "Revolut" },
            { "code": "sk-fidu-genode51rgg", "caption": "Rheingauer Volksbank eG" },
            { "code": "sk-84050000", "caption": "Rhön-Rennsteig SPK" },
            { "code": "sk-fidu-genoded1wnd", "caption": "Rosbacher Raiffeisenbank eG" },
            { "code": "sk-fidu-genodef1hr1", "caption": "Rostocker Volks- und Raiffeisenbank eG" },
            { "code": "sk-fidu-genodef1poc", "caption": "Rottaler Raiffeisenbank eG" },
            { "code": "ob-rbs-bus", "caption": "Royal Bank of Scotland Bankline" },
            { "code": "ob-rbs-com", "caption": "Royal Bank of Scotland ClearSpend" },
            { "code": "sk-fidu-genode51rus", "caption": "Rüsselsheimer Volksbank eG" },
            { "code": "sk-39050000", "caption": "SPK Aachen" },
            { "code": "sk-72051210", "caption": "SPK Aichach-Schrobenhausen" },
            { "code": "sk-73350000", "caption": "SPK Allgäu" },
            { "code": "sk-83050200", "caption": "SPK Altenburger Land" },
            { "code": "sk-81055555", "caption": "SPK Altmark West" },
            { "code": "sk-71151020", "caption": "SPK Altötting-Mühldorf" },
            { "code": "sk-75250000", "caption": "SPK Amberg-Sulzbach" },
            { "code": "sk-76550000", "caption": "SPK Ansbach" },
            { "code": "sk-46650005", "caption": "SPK Arnsberg-Sundern" },
            { "code": "sk-84051010", "caption": "SPK Arnstadt-Ilmenau" },
            { "code": "sk-79550000", "caption": "SPK Aschaffenburg-Alzenau" },
            { "code": "sk-46251630", "caption": "SPK Attendorn-Lennestadt-Kirchhundem" },
            { "code": "sk-28350000", "caption": "SPK Aurich-Norden" },
            { "code": "sk-53250000", "caption": "SPK Bad Hersfeld-Rotenburg" },
            { "code": "sk-79351010", "caption": "SPK Bad Kissingen" },
            { "code": "sk-79353090", "caption": "SPK Bad Neustadt a.d. Saale" },
            { "code": "sk-49051285", "caption": "SPK Bad Oeynhausen - Porta Westfalica" },
            { "code": "sk-70054306", "caption": "SPK Bad Tölz-Wolfratshausen" },
            { "code": "sk-66250030", "caption": "SPK Baden-Baden Gaggenau" },
            { "code": "sk-77050000", "caption": "SPK Bamberg" },
            { "code": "sk-17052000", "caption": "SPK Barnim" },
            { "code": "sk-51752267", "caption": "SPK Battenberg" },
            { "code": "sk-77350110", "caption": "SPK Bayreuth" },
            { "code": "sk-41250035", "caption": "SPK Beckum-Wadersloh" },
            { "code": "sk-50950068", "caption": "SPK Bensheim" },
            { "code": "sk-71050000", "caption": "SPK Berchtesgadener Land" },
            { "code": "sk-41051845", "caption": "SPK Bergkamen-Bönen" },
            { "code": "sk-48050161", "caption": "SPK Bielefeld" },
            { "code": "sk-43050001", "caption": "SPK Bochum" },
            { "code": "sk-69050001", "caption": "SPK Bodensee" },
            { "code": "sk-68051207", "caption": "SPK Bonndorf-Stühlingen" },
            { "code": "sk-42451220", "caption": "SPK Bottrop" },
            { "code": "sk-29050101", "caption": "SPK Bremen" },
            { "code": "sk-46051240", "caption": "SPK Burbach-Neunkirchen" },
            { "code": "sk-80053000", "caption": "SPK Burgenlandkreis" },
            { "code": "sk-66251434", "caption": "SPK Bühl" },
            { "code": "sk-87050000", "caption": "SPK Chemnitz" },
            { "code": "sk-78350000", "caption": "SPK Coburg-Lichtenfels" },
            { "code": "sk-70051540", "caption": "SPK Dachau" },
            { "code": "sk-50850150", "caption": "SPK Darmstadt" },
            { "code": "sk-74150000", "caption": "SPK Deggendorf" },
            { "code": "sk-50852651", "caption": "SPK Dieburg" },
            { "code": "sk-51650045", "caption": "SPK Dillenburg" },
            { "code": "sk-72250160", "caption": "SPK Donauwörth" },
            { "code": "sk-54051990", "caption": "SPK Donnersberg" },
            { "code": "sk-44050199", "caption": "SPK Dortmund" },
            { "code": "sk-26051260", "caption": "SPK Duderstadt" },
            { "code": "sk-35050000", "caption": "SPK Duisburg" },
            { "code": "sk-39550110", "caption": "SPK Düren" },
            { "code": "sk-26251425", "caption": "SPK Einbeck" },
            { "code": "sk-18051000", "caption": "SPK Elbe-Elster" },
            { "code": "sk-22150000", "caption": "SPK Elmshorn" },
            { "code": "sk-28450000", "caption": "SPK Emden" },
            { "code": "sk-26650001", "caption": "SPK Emsland" },
            { "code": "sk-69251445", "caption": "SPK Engen-Gottmadingen" },
            { "code": "sk-45451060", "caption": "SPK Ennepetal-Breckerfeld" },
            { "code": "sk-70051995", "caption": "SPK Erding-Dorfen" },
            { "code": "sk-76350000", "caption": "SPK Erlangen Höchstadt Herzogenaurach" },
            { "code": "sk-75351960", "caption": "SPK Eschenbach Neustadt (a.d. Waldnaab)" },
            { "code": "sk-36050105", "caption": "SPK Essen" },
            { "code": "sk-46251590", "caption": "SPK Finnentrop" },
            { "code": "sk-76351040", "caption": "SPK Forchheim" },
            { "code": "sk-68050101", "caption": "SPK Freiburg-Nördlicher Breisgau" },
            { "code": "sk-70051003", "caption": "SPK Freising" },
            { "code": "sk-74051230", "caption": "SPK Freyung-Grafenau" },
            { "code": "sk-53050180", "caption": "SPK Fulda" },
            { "code": "sk-70053070", "caption": "SPK Fürstenfeldbruck" },
            { "code": "sk-76250000", "caption": "SPK Fürth" },
            { "code": "sk-42050001", "caption": "SPK Gelsenkirchen" },
            { "code": "sk-83050000", "caption": "SPK Gera-Greiz" },
            { "code": "sk-41651965", "caption": "SPK Geseke" },
            { "code": "sk-45450050", "caption": "SPK Gevelsberg-Wetter" },
            { "code": "sk-51350025", "caption": "SPK Gießen" },
            { "code": "sk-26951311", "caption": "SPK Gifhorn-Wolfsburg" },
            { "code": "sk-42450040", "caption": "SPK Gladbeck" },
            { "code": "sk-51351526", "caption": "SPK Grünberg" },
            { "code": "sk-38450000", "caption": "SPK Gummersbach-Bergneustadt" },
            { "code": "sk-76551540", "caption": "SPK Gunzenhausen" },
            { "code": "sk-26050001", "caption": "SPK Göttingen" },
            { "code": "sk-72051840", "caption": "SPK Günzburg-Krumbach" },
            { "code": "sk-47850065", "caption": "SPK Gütersloh-Rietberg" },
            { "code": "sk-45050001", "caption": "SPK HagenHerdecke" },
            { "code": "sk-25450110", "caption": "SPK Hameln-Weserbergland" },
            { "code": "sk-41050095", "caption": "SPK Hamm" },
            { "code": "sk-50650023", "caption": "SPK Hanau" },
            { "code": "sk-66451862", "caption": "SPK Hanauerland" },
            { "code": "sk-25050180", "caption": "SPK Hannover" },
            { "code": "sk-20750000", "caption": "SPK Harburg-Buxtehude" },
            { "code": "sk-66451548", "caption": "SPK Haslach-Zell" },
            { "code": "sk-43051040", "caption": "SPK Hattingen" },
            { "code": "sk-69250035", "caption": "SPK Hegau-Bodensee" },
            { "code": "sk-67250020", "caption": "SPK Heidelberg" },
            { "code": "sk-49450120", "caption": "SPK Herford" },
            { "code": "sk-43250030", "caption": "SPK Herne" },
            { "code": "sk-33450000", "caption": "SPK Hilden-Ratingen-Velbert" },
            { "code": "sk-25950130", "caption": "SPK Hildesheim Goslar Peine" },
            { "code": "sk-78050000", "caption": "SPK Hochfranken" },
            { "code": "sk-68452290", "caption": "SPK Hochrhein" },
            { "code": "sk-41651770", "caption": "SPK Hochsauerland" },
            { "code": "sk-68051004", "caption": "SPK Hochschwarzwald" },
            { "code": "sk-62251550", "caption": "SPK Hohenlohekreis" },
            { "code": "sk-21352240", "caption": "SPK Holstein" },
            { "code": "sk-47251550", "caption": "SPK Höxter" },
            { "code": "sk-72150000", "caption": "SPK IngolstadtEichstätt" },
            { "code": "sk-44550045", "caption": "SPK Iserlohn" },
            { "code": "sk-83053030", "caption": "SPK Jena-Saale-Holzland" },
            { "code": "sk-66050101", "caption": "SPK Karlsruhe" },
            { "code": "sk-45851665", "caption": "SPK Kierspe-Meinerzhagen" },
            { "code": "sk-57050120", "caption": "SPK Koblenz" },
            { "code": "sk-66350036", "caption": "SPK Kraichgau" },
            { "code": "sk-32050000", "caption": "SPK Krefeld" },
            { "code": "sk-77150000", "caption": "SPK Kulmbach-Kronach" },
            { "code": "sk-37050198", "caption": "SPK KölnBonn" },
            { "code": "sk-70052060", "caption": "SPK Landsberg-Dießen" },
            { "code": "sk-74350000", "caption": "SPK Landshut" },
            { "code": "sk-50652124", "caption": "SPK Langen-Seligenstadt" },
            { "code": "sk-51352227", "caption": "SPK Laubach-Hungen" },
            { "code": "sk-28550000", "caption": "SPK LeerWittmund" },
            { "code": "sk-48250110", "caption": "SPK Lemgo" },
            { "code": "sk-37551440", "caption": "SPK Leverkusen" },
            { "code": "sk-41650001", "caption": "SPK Lippstadt" },
            { "code": "sk-68350048", "caption": "SPK Lörrach-Rheinfelden" },
            { "code": "sk-45850005", "caption": "SPK Lüdenscheid" },
            { "code": "sk-24050110", "caption": "SPK Lüneburg" },
            { "code": "sk-79050000", "caption": "SPK Mainfranken Würzburg" },
            { "code": "sk-55050120", "caption": "SPK Mainz" },
            { "code": "sk-80055008", "caption": "SPK Mansfeld-Südharz" },
            { "code": "sk-53350000", "caption": "SPK Marburg-Biedenkopf" },
            { "code": "sk-68351865", "caption": "SPK Markgräflerland" },
            { "code": "sk-14051000", "caption": "SPK Mecklenburg-Nordwest" },
            { "code": "sk-14052000", "caption": "SPK Mecklenburg-Schwerin" },
            { "code": "sk-15051732", "caption": "SPK Mecklenburg-Strelitz" },
            { "code": "sk-85055000", "caption": "SPK Meißen" },
            { "code": "sk-73150000", "caption": "SPK Memmingen-Lindau-Mindelheim" },
            { "code": "sk-59351040", "caption": "SPK Merzig-Wadern" },
            { "code": "sk-46451012", "caption": "SPK Meschede" },
            { "code": "sk-79650000", "caption": "SPK Miltenberg-Obernburg" },
            { "code": "sk-49050101", "caption": "SPK Minden-Lübbecke" },
            { "code": "sk-76450000", "caption": "SPK Mittelfranken-Süd" },
            { "code": "sk-21450000", "caption": "SPK Mittelholstein AG" },
            { "code": "sk-58751230", "caption": "SPK Mittelmosel-Eifel-Mosel-Hunsrück" },
            { "code": "sk-87052000", "caption": "SPK Mittelsachsen" },
            { "code": "sk-82051000", "caption": "SPK Mittelthüringen" },
            { "code": "sk-74351740", "caption": "SPK Moosburg" },
            { "code": "sk-86050200", "caption": "SPK Muldental" },
            { "code": "sk-44551210", "caption": "SPK Märkisches Sauerland Hemer - Menden" },
            { "code": "sk-36250000", "caption": "SPK Mülheim a.d. Ruhr" },
            { "code": "sk-40050150", "caption": "SPK Münsterland-Ost" },
            { "code": "sk-67450048", "caption": "SPK Neckartal-Odenwald" },
            { "code": "sk-73050000", "caption": "SPK Neu-Ulm-Illertissen" },
            { "code": "sk-15050200", "caption": "SPK Neubrandenburg-Demmin" },
            { "code": "sk-72152070", "caption": "SPK Neuburg-Rain" },
            { "code": "sk-76052080", "caption": "SPK Neumarkt i.d.OPf.-Parsberg" },
            { "code": "sk-59252046", "caption": "SPK Neunkirchen" },
            { "code": "sk-30550000", "caption": "SPK Neuss" },
            { "code": "sk-76251020", "caption": "SPK Neustadt a.d. Aisch-Bad Windsheim" },
            { "code": "sk-57450120", "caption": "SPK Neuwied" },
            { "code": "sk-74250000", "caption": "SPK Niederbayern-Mitte" },
            { "code": "sk-18055000", "caption": "SPK Niederlausitz" },
            { "code": "sk-25650106", "caption": "SPK Nienburg" },
            { "code": "sk-72250000", "caption": "SPK Nördlingen" },
            { "code": "sk-76050101", "caption": "SPK Nürnberg" },
            { "code": "sk-51850079", "caption": "SPK Oberhessen" },
            { "code": "sk-70351030", "caption": "SPK Oberland" },
            { "code": "sk-85050100", "caption": "SPK Oberlausitz-Niederschlesien" },
            { "code": "sk-75350000", "caption": "SPK Oberpfalz Nord" },
            { "code": "sk-50851952", "caption": "SPK Odenwaldkreis" },
            { "code": "sk-17055050", "caption": "SPK Oder-Spree" },
            { "code": "sk-66450050", "caption": "SPK Offenburg/Ortenau" },
            { "code": "sk-46250049", "caption": "SPK Olpe-Drolshagen-Wenden" },
            { "code": "sk-26550105", "caption": "SPK Osnabrück" },
            { "code": "sk-26351015", "caption": "SPK Osterode am Harz" },
            { "code": "sk-16050202", "caption": "SPK Ostprignitz-Ruppin" },
            { "code": "sk-47650130", "caption": "SPK Paderborn-Detmold" },
            { "code": "sk-74050000", "caption": "SPK Passau" },
            { "code": "sk-72151650", "caption": "SPK Pfaffenhofen" },
            { "code": "sk-66650085", "caption": "SPK Pforzheim Calw" },
            { "code": "sk-69051620", "caption": "SPK Pfullendorf-Meßkirch" },
            { "code": "sk-16050101", "caption": "SPK Prignitz" },
            { "code": "sk-34051350", "caption": "SPK Radevormwald-Hückeswagen" },
            { "code": "sk-66550070", "caption": "SPK Rastatt-Gernsbach" },
            { "code": "sk-74151450", "caption": "SPK Regen-Viechtach" },
            { "code": "sk-75050000", "caption": "SPK Regensburg" },
            { "code": "sk-67050505", "caption": "SPK Rhein Neckar Nord" },
            { "code": "sk-54651240", "caption": "SPK Rhein-Haardt" },
            { "code": "sk-32450000", "caption": "SPK Rhein-Maas" },
            { "code": "sk-56050180", "caption": "SPK Rhein-Nahe" },
            { "code": "sk-71150000", "caption": "SPK Rosenheim-Bad Aibling" },
            { "code": "sk-24151235", "caption": "SPK Rotenburg Osterholz" },
            { "code": "sk-74351430", "caption": "SPK Rottal-Inn" },
            { "code": "sk-59050101", "caption": "SPK Saarbrücken" },
            { "code": "sk-69051725", "caption": "SPK Salem-Heiligenberg" },
            { "code": "sk-25551480", "caption": "SPK Schaumburg" },
            { "code": "sk-29152550", "caption": "SPK Scheeßel" },
            { "code": "sk-69450065", "caption": "SPK Schwarzwald-Baar" },
            { "code": "sk-79350101", "caption": "SPK Schweinfurt-Haßberge" },
            { "code": "sk-62250030", "caption": "SPK Schwäbisch Hall-Crailsheim" },
            { "code": "sk-46050001", "caption": "SPK Siegen" },
            { "code": "sk-41450075", "caption": "SPK SoestWerl" },
            { "code": "sk-84054722", "caption": "SPK Sonneberg" },
            { "code": "sk-18050000", "caption": "SPK Spree-Neiße" },
            { "code": "sk-45251515", "caption": "SPK Sprockhövel" },
            { "code": "sk-68052230", "caption": "SPK St.Blasien" },
            { "code": "sk-24151005", "caption": "SPK Stade - Altes Land" },
            { "code": "sk-50951469", "caption": "SPK Starkenburg" },
            { "code": "sk-68052328", "caption": "SPK Staufen-Breisach" },
            { "code": "sk-54250010", "caption": "SPK Suedwestpfalz" },
            { "code": "sk-23051030", "caption": "SPK Südholstein" },
            { "code": "sk-54850010", "caption": "SPK Südl. Weinstr. Landau" },
            { "code": "sk-67352565", "caption": "SPK Tauberfranken" },
            { "code": "sk-58550130", "caption": "SPK Trier" },
            { "code": "sk-17056060", "caption": "SPK Uckermark" },
            { "code": "sk-15050400", "caption": "SPK Uecker-Randow" },
            { "code": "sk-25850110", "caption": "SPK Uelzen Lüchow-Dannenberg" },
            { "code": "sk-63050000", "caption": "SPK Ulm" },
            { "code": "sk-44350060", "caption": "SPK UnnaKamen" },
            { "code": "sk-82056060", "caption": "SPK Unstrut-Hainich" },
            { "code": "sk-42650150", "caption": "SPK Vest Recklinghausen" },
            { "code": "sk-87058000", "caption": "SPK Vogtland" },
            { "code": "sk-54550010", "caption": "SPK Vorderpfalz" },
            { "code": "sk-15050500", "caption": "SPK Vorpommern" },
            { "code": "sk-52350005", "caption": "SPK Waldeck-Frankenberg" },
            { "code": "sk-52250030", "caption": "SPK Werra-Meißner" },
            { "code": "sk-57351030", "caption": "SPK Westerwald-Sieg" },
            { "code": "sk-22250020", "caption": "SPK Westholstein" },
            { "code": "sk-40154530", "caption": "SPK Westmünsterland" },
            { "code": "sk-51550035", "caption": "SPK Wetzlar" },
            { "code": "sk-68351557", "caption": "SPK Wiesental" },
            { "code": "sk-28250110", "caption": "SPK Wilhelmshaven" },
            { "code": "sk-45250035", "caption": "SPK Witten" },
            { "code": "sk-80550101", "caption": "SPK Wittenberg" },
            { "code": "sk-46053480", "caption": "SPK Wittgenstein" },
            { "code": "sk-66452776", "caption": "SPK Wolfach" },
            { "code": "sk-55350010", "caption": "SPK Worms-Alzey-Ried" },
            { "code": "sk-65351260", "caption": "SPK Zollernalb" },
            { "code": "sk-87055000", "caption": "SPK Zwickau" },
            { "code": "sk-35450000", "caption": "SPK am Niederrhein" },
            { "code": "sk-74251020", "caption": "SPK im Landkreis Cham" },
            { "code": "sk-75051040", "caption": "SPK im Landkreis Schwandorf" },
            { "code": "sk-23050101", "caption": "SPK zu Lübeck AG" },
            { "code": "sk-72050000", "caption": "SSK Augsburg" },
            { "code": "sk-25451345", "caption": "SSK Bad Pyrmont" },
            { "code": "sk-25151270", "caption": "SSK Barsinghausen" },
            { "code": "sk-42850035", "caption": "SSK Bocholt" },
            { "code": "sk-52051373", "caption": "SSK Borken" },
            { "code": "sk-25151371", "caption": "SSK Burgdorf" },
            { "code": "sk-24150001", "caption": "SSK Cuxhaven" },
            { "code": "sk-47251740", "caption": "SSK Delbrück" },
            { "code": "sk-80053572", "caption": "SSK Dessau" },
            { "code": "sk-30050110", "caption": "SSK Düsseldorf" },
            { "code": "sk-52051877", "caption": "SSK Grebenstein" },
            { "code": "sk-30351220", "caption": "SSK Haan (Rheinland)" },
            { "code": "sk-42651315", "caption": "SSK Haltern am See" },
            { "code": "sk-37551780", "caption": "SSK Langenfeld (Rheinland)" },
            { "code": "sk-40154476", "caption": "SSK Lengerich" },
            { "code": "sk-81053272", "caption": "SSK Magdeburg" },
            { "code": "sk-31050000", "caption": "SSK Mönchengladbach" },
            { "code": "sk-70150000", "caption": "SSK München" },
            { "code": "sk-36550000", "caption": "SSK Oberhausen" },
            { "code": "sk-50550020", "caption": "SSK Offenbach" },
            { "code": "sk-49051065", "caption": "SSK Rahden" },
            { "code": "sk-34050000", "caption": "SSK Remscheid" },
            { "code": "sk-40350005", "caption": "SSK Rheine" },
            { "code": "sk-52053458", "caption": "SSK Schwalmstadt" },
            { "code": "sk-17052302", "caption": "SSK Schwedt" },
            { "code": "sk-45451555", "caption": "SSK Schwelm" },
            { "code": "sk-44152490", "caption": "SSK Schwerte" },
            { "code": "sk-34250000", "caption": "SSK Solingen" },
            { "code": "sk-47853355", "caption": "SSK Versmold" },
            { "code": "sk-22151730", "caption": "SSK Wedel" },
            { "code": "sk-34051570", "caption": "SSK Wermelskirchen" },
            { "code": "sk-25152490", "caption": "SSK Wunstorf" },
            { "code": "sk-33050000", "caption": "SSK Wuppertal" },
            { "code": "sk-80053762", "caption": "Saalesparkasse" },
            { "code": "sk-59050000", "caption": "SaarLB" },
            { "code": "sk-80055500", "caption": "Salzlandsparkasse" },
            { "code": "ngp-scfb", "caption": "Santander" },
            { "code": "pa-santander", "caption": "Santander Bank Polska" },
            { "code": "pa-santander-bus", "caption": "Santander Bank Polska (Corporate)" },
            { "code": "sbsi", "caption": "Sberbank d.d." },
            { "code": "sk-fidu-genodes1sca", "caption": "Scharnhauser Bank eG" },
            { "code": "sk-fidu-genodef1sbn", "caption": "Schrobenhausener Bank eG" },
            { "code": "season", "caption": "Seasons" },
            { "code": "stet-smct", "caption": "Société Marseillaise de Crédit" },
            { "code": "soldo-prod", "caption": "Soldo" },
            { "code": "soldoirl-prod", "caption": "Soldo IE" },
            { "code": "sk-fidu-genodef1gmd", "caption": "Spar- u. Kredit-Bank eG" },
            { "code": "sk-fidu-genodem1hbh", "caption": "Spar- und Darlehnskasse Bockum-Hövel eG" },
            { "code": "sk-fidu-genodef1las", "caption": "Spar- und Darlehnskasse Börde Lamstedt-Hechthausen eG" },
            { "code": "sk-fidu-genodef1foy", "caption": "Spar- und Darlehnskasse eG, Friesoythe" },
            { "code": "sk-fidu-genode61bht", "caption": "Spar- und Kreditbank Bühlertal eG" },
            { "code": "sk-fidu-genode51bh2", "caption": "Spar- und Kreditbank Evangelisch-Freikirchlicher Gemeinden eG" },
            { "code": "sk-fidu-genode61rh2", "caption": "Spar- und Kreditbank Rheinstetten eG" },
            { "code": "sk-fidu-genodem1bfg", "caption": "Spar- und Kreditbank des Bundes Freier evangelischer Gemeinden eG" },
            { "code": "sk-fidu-genodef1haa", "caption": "Spar- und Kreditbank eG, Hammah" },
            { "code": "ngp-spad", "caption": "Sparda Bank" },
            { "code": "sk-fidu-genodef1s10", "caption": "Sparda-Bank Berlin eG" },
            { "code": "sk-fidu-genodef1s11", "caption": "Sparda-Bank Hamburg eG" },
            { "code": "sk-fidu-genodef1s09", "caption": "Sparda-Bank Hannover eG" },
            { "code": "sk-fidu-genodef1s01", "caption": "Sparda-Bank Südwest eG" },
            { "code": "sk-38452490", "caption": "Sparkasse der Homburgischen Gemeinden" },
            { "code": "sk-fidu-genodef1ln1", "caption": "Spreewaldbank eG" },
            { "code": "sk-fidu-gakddem1xxx", "caption": "St. Galler Kantonalbank Deutschland AG" },
            { "code": "sk-86055592", "caption": "Stadt- und Kreissparkasse Leipzig" },
            { "code": "starling", "caption": "Starling Bank" },
            { "code": "sk-fidu-genoded1stb", "caption": "Steyler Bank GmbH" },
            { "code": "suitme", "caption": "Suits Me Non DD" },
            { "code": "sumup", "caption": "SumUp" },
            { "code": "ngp-habalt", "caption": "Swedbank" },
            { "code": "ngp-habalv", "caption": "Swedbank" },
            { "code": "sk-fidu-genodef1syl", "caption": "Sylter Bank eG" },
            { "code": "sk-fidu-suskdem1xxx", "caption": "Südtiroler Sparkasse AG Niederlassung München" },
            { "code": "sk-fidu-swbsdessxxx", "caption": "Südwestbank AG" },
            { "code": "ob-tsb", "caption": "TSB Bank" },
            { "code": "tailrd", "caption": "Tailord" },
            { "code": "talkremit", "caption": "TalkRemit" },
            { "code": "tandem", "caption": "Tandem Bank" },
            { "code": "stet-cmcies", "caption": "Targobank" },
            { "code": "sk-51250000", "caption": "Taunus Sparkasse" },
            { "code": "ob-tesco", "caption": "Tesco Bank" },
            { "code": "sk-fidu-troddef1xxx", "caption": "Triodos Bank N.V. Deutschland" },
            { "code": "tuba", "caption": "TurkishBank UK" },
            { "code": "ngp-ubswd", "caption": "UBS Germany" },
            { "code": "sk-fidu-unbnde21xxx", "caption": "UNION-BANK AG" },
            { "code": "ob-ulster", "caption": "Ulster Bank" },
            { "code": "ob-ulster-bus", "caption": "Ulster Bank Bankline" },
            { "code": "ob-ulster-com", "caption": "Ulster Bank ClearSpend" },
            { "code": "unity", "caption": "Unity Trust Bank" },
            { "code": "sk-fidu-genodes1vls", "caption": "VBU Volksbank im Unterland eG" },
            { "code": "sk-fidu-genodef1dik", "caption": "VR BANK Dinklage-Steinfeld eG" },
            { "code": "sk-fidu-genode51abo", "caption": "VR Bank Alzey-Land-Schwabenheim eG" },
            { "code": "sk-fidu-genodef1aub", "caption": "VR Bank Augsburg-Ostallgäu eG" },
            { "code": "sk-fidu-genode51gel", "caption": "VR Bank Bad Orb-Gelnhausen eG" },
            { "code": "sk-fidu-genodef1foh", "caption": "VR Bank Bamberg-Forchheim eG" },
            { "code": "sk-fidu-genodef1ho1", "caption": "VR Bank Bayreuth-Hof eG" },
            { "code": "sk-fidu-genodef1blf", "caption": "VR Bank Burglengenfeld eG" },
            { "code": "sk-fidu-genode61wir", "caption": "VR Bank Enz plus eG" },
            { "code": "sk-fidu-genode51ful", "caption": "VR Bank Fulda eG" },
            { "code": "sk-fidu-genode51als", "caption": "VR Bank HessenLand eG" },
            { "code": "sk-fidu-genodes1hon", "caption": "VR Bank Hohenneuffen-Teck eG" },
            { "code": "sk-fidu-genodef1kt1", "caption": "VR Bank Kitzingen eG" },
            { "code": "sk-fidu-genode51bik", "caption": "VR Bank Lahn-Dill eG" },
            { "code": "sk-fidu-genodef1fwa", "caption": "VR Bank Lausitz eG" },
            { "code": "sk-fidu-genodef1lsr", "caption": "VR Bank Main-Kinzig-Büdingen eG" },
            { "code": "sk-fidu-genode61duw", "caption": "VR Bank Mittelhaardt eG" },
            { "code": "sk-fidu-genodef1swd", "caption": "VR Bank Mittlere Oberpfalz eG" },
            { "code": "sk-fidu-genodef1ohc", "caption": "VR Bank München Land eG" },
            { "code": "sk-fidu-genodef1nd2", "caption": "VR Bank Neuburg-Rain eG" },
            { "code": "sk-fidu-genodef1nms", "caption": "VR Bank Neumünster eG" },
            { "code": "sk-fidu-genodef1p18", "caption": "VR Bank Niederbayern-Oberpfalz eG" },
            { "code": "sk-fidu-genodef1bds", "caption": "VR Bank Nord eG" },
            { "code": "sk-fidu-genodef1ku1", "caption": "VR Bank Oberfranken Mitte eG" },
            { "code": "sk-fidu-genodef1wdh", "caption": "VR Bank Oldenburg Land eG" },
            { "code": "sk-fidu-genodef1nsh", "caption": "VR Bank Ostholstein Nord - Plön eG" },
            { "code": "sk-fidu-genodes1rrv", "caption": "VR Bank Ravensburg-Weingarten eG" },
            { "code": "sk-fidu-genoded1nwd", "caption": "VR Bank Rhein-Mosel eG" },
            { "code": "sk-fidu-genode61ma2", "caption": "VR Bank Rhein-Neckar eG" },
            { "code": "sk-fidu-genodef1slw", "caption": "VR Bank Schleswig-Mittelholstein eG" },
            { "code": "sk-fidu-genodes1sha", "caption": "VR Bank Schwäbisch Hall-Crailsheim eG" },
            { "code": "sk-fidu-genodef1sth", "caption": "VR Bank Starnberg-Herrsching-Landsberg eG" },
            { "code": "sk-fidu-genode61bza", "caption": "VR Bank Südliche Weinstraße-Wasgau eG" },
            { "code": "sk-fidu-genode61suw", "caption": "VR Bank Südpfalz eG" },
            { "code": "sk-fidu-genodes1stw", "caption": "VR Bank Tübingen eG" },
            { "code": "sk-fidu-genodef1we1", "caption": "VR Bank Weimar eG" },
            { "code": "sk-fidu-genodef1hum", "caption": "VR Bank Westküste eG" },
            { "code": "sk-fidu-genodef1mu2", "caption": "VR Bank Westthüringen eG" },
            { "code": "sk-fidu-genoded1paf", "caption": "VR Bank eG Bergisch Gladbach-Leverkusen" },
            { "code": "sk-fidu-genodes1wlb", "caption": "VR Bank eG Heuberg-Winterlingen" },
            { "code": "sk-fidu-genode51ahm", "caption": "VR Bank eG, Alsheim" },
            { "code": "sk-fidu-genoded1nld", "caption": "VR Bank eG, Monheim am Rhein" },
            { "code": "sk-fidu-genodef1pin", "caption": "VR Bank in Holstein eG" },
            { "code": "sk-fidu-genodef1dgv", "caption": "VR GenoBank DonauWald eG" },
            { "code": "sk-fidu-genodef1wot", "caption": "VR PLUS Altmark-Wendland eG" },
            { "code": "sk-fidu-genodef1hrv", "caption": "VR PartnerBank eG Chattengau-Schwalm-Eder" },
            { "code": "sk-fidu-genodef1nea", "caption": "VR meine Bank eG" },
            { "code": "sk-fidu-genodef1slr", "caption": "VR-Bank Altenburger Land eG" },
            { "code": "sk-fidu-genodes1amt", "caption": "VR-Bank Asperg-Markgröningen eG" },
            { "code": "sk-fidu-genodef1sal", "caption": "VR-Bank Bad Salzungen Schmalkalden eG" },
            { "code": "sk-fidu-genoded1hbo", "caption": "VR-Bank Bonn eG" },
            { "code": "sk-fidu-genodef1cos", "caption": "VR-Bank Coburg eG" },
            { "code": "sk-fidu-genodef1gz2", "caption": "VR-Bank Donau-Mindel eG" },
            { "code": "sk-fidu-genodes1vds", "caption": "VR-Bank Dornstetten-Horb eG" },
            { "code": "sk-fidu-genodes1ehn", "caption": "VR-Bank Ehningen-Nufringen eG" },
            { "code": "sk-fidu-genodef1esa", "caption": "VR-Bank Eisenach-Ronshausen eG" },
            { "code": "sk-fidu-genodes1ell", "caption": "VR-Bank Ellwangen eG" },
            { "code": "sk-fidu-genodef1ise", "caption": "VR-Bank Erding eG" },
            { "code": "sk-fidu-genodef1er1", "caption": "VR-Bank Erlangen-Höchstadt- Herzogenaurach eG" },
            { "code": "sk-fidu-genodef1dkv", "caption": "VR-Bank Feuchtwangen-Dinkelsbühl eG" },
            { "code": "sk-fidu-genodef1mak", "caption": "VR-Bank Fichtelgebirge- Frankenwald eG" },
            { "code": "sk-fidu-genodef1luk", "caption": "VR-Bank Fläming eG" },
            { "code": "sk-fidu-genodem1frf", "caption": "VR-Bank Freudenberg-Niederfischbach eG" },
            { "code": "sk-fidu-genodef1gzh", "caption": "VR-Bank Gerolzhofen eG" },
            { "code": "sk-fidu-genodef1mtg", "caption": "VR-Bank Handels- und Gewerbebank eG" },
            { "code": "sk-fidu-genoded1mba", "caption": "VR-Bank Hunsrück-Mosel eG" },
            { "code": "sk-fidu-genodef1vbv", "caption": "VR-Bank Isar-Vils eG" },
            { "code": "sk-fidu-genodef1isv", "caption": "VR-Bank Ismaning Hallbergmoos Neufahrn eG" },
            { "code": "sk-fidu-genodef1lnd", "caption": "VR-Bank Landau-Mengkofen eG" },
            { "code": "sk-fidu-genodef1dss", "caption": "VR-Bank Landsberg-Ammersee eG" },
            { "code": "sk-fidu-genodef1lh1", "caption": "VR-Bank Landshut eG" },
            { "code": "sk-fidu-genodes1lbk", "caption": "VR-Bank Langenau-Ulmer Alb eG" },
            { "code": "sk-fidu-genodef1lif", "caption": "VR-Bank Lichtenfels-Ebern eG" },
            { "code": "sk-fidu-genodef1mm1", "caption": "VR-Bank Memmingen eG" },
            { "code": "sk-fidu-genodef1esw", "caption": "VR-Bank Mitte eG" },
            { "code": "sk-fidu-genodef1ans", "caption": "VR-Bank Mittelfranken West eG" },
            { "code": "sk-fidu-genodef1dl1", "caption": "VR-Bank Mittelsachsen eG" },
            { "code": "sk-fidu-genodes1vbb", "caption": "VR-Bank Neckar-Enz eG" },
            { "code": "sk-fidu-genodef1nu1", "caption": "VR-Bank Neu-Ulm eG" },
            { "code": "sk-fidu-genodef1hue", "caption": "VR-Bank NordRhön eG" },
            { "code": "sk-fidu-genoded1sle", "caption": "VR-Bank Nordeifel eG" },
            { "code": "sk-fidu-genodes1aav", "caption": "VR-Bank Ostalb eG" },
            { "code": "sk-fidu-genodef1sr1", "caption": "VR-Bank Ostbayern-Mitte eG" },
            { "code": "sk-fidu-genodef1pa1", "caption": "VR-Bank Passau eG" },
            { "code": "sk-fidu-genoded1rst", "caption": "VR-Bank Rhein-Sieg eG" },
            { "code": "sk-fidu-genodef1pfk", "caption": "VR-Bank Rottal-Inn eG" },
            { "code": "sk-fidu-genodef1ate", "caption": "VR-Bank Schweinfurt eG" },
            { "code": "sk-fidu-genodef1spb", "caption": "VR-Bank Spangenberg-Morschen eG" },
            { "code": "sk-fidu-genode61roa", "caption": "VR-Bank Südwestpfalz eG Pirmasens - Zweibrücken" },
            { "code": "sk-fidu-genodef1tav", "caption": "VR-Bank Taufkirchen-Dorfen eG" },
            { "code": "sk-fidu-genodef1pz1", "caption": "VR-Bank Uckermark-Randow eG" },
            { "code": "sk-fidu-genodef1gap", "caption": "VR-Bank Werdenfels eG" },
            { "code": "sk-fidu-genodem1bob", "caption": "VR-Bank Westmünsterland eG" },
            { "code": "sk-fidu-genodes1mag", "caption": "VR-Bank eG Magstadt-Weissach" },
            { "code": "sk-fidu-genodef1nop", "caption": "VR-Bank eG Osnabrücker Nordland" },
            { "code": "sk-fidu-genode61spf", "caption": "VR-Bank eG Schopfheim-Maulburg" },
            { "code": "sk-fidu-genodef1alz", "caption": "VR-Bank eG, Alzenau" },
            { "code": "sk-fidu-genoded1wur", "caption": "VR-Bank eG, Würselen" },
            { "code": "sk-fidu-genode61iff", "caption": "VR-Bank in Mittelbaden eG" },
            { "code": "sk-fidu-genodef1dra", "caption": "VR-Bank in Südniedersachsen eG" },
            { "code": "sk-fidu-genodef1clp", "caption": "VR-Bank in Südoldenburg eG" },
            { "code": "sk-fidu-genode51bhe", "caption": "VR-Bankverein Bad Hersfeld-Rotenburg eG" },
            { "code": "sk-40153768", "caption": "VSK Emsdetten-Ochtrup" },
            { "code": "sk-32250050", "caption": "VSK Goch-Kevelaer-Weeze" },
            { "code": "sk-fidu-tvbadeffxxx", "caption": "VakifBank International AG, Wien ZN Deutschland" },
            { "code": "sk-fidu-dgpbde3mxxx", "caption": "VerbundVolksbank OWL eG" },
            { "code": "sk-fidu-genodef1bst", "caption": "Vereinigte Raiffeisenbank Burgstädt eG" },
            { "code": "sk-fidu-genodef1gbf", "caption": "Vereinigte Raiffeisenbanken Gräfenberg-Forchheim- Eschenau-Heroldsberg eG" },
            { "code": "sk-45851020", "caption": "Vereinigte Sparkasse im Märkischen Kreis" },
            { "code": "sk-fidu-genode61spe", "caption": "Vereinigte VR Bank Kur- und Rheinpfalz eG" },
            { "code": "sk-fidu-genodef1wyk", "caption": "Vereinigte VR Bank eG, Wyk auf Föhr" },
            { "code": "sk-fidu-genode51mic", "caption": "Vereinigte Volksbank Raiffeisenbank eG, Reinheim" },
            { "code": "sk-fidu-genoded1wtl", "caption": "Vereinigte Volksbank Raiffeisenbank eG, Wittlich" },
            { "code": "sk-fidu-genodef1hud", "caption": "Vereinigte Volksbank eG Ganderkesee-Hude-Bookholzberg- Lemwerder" },
            { "code": "sk-fidu-genode51sb2", "caption": "Vereinigte Volksbank eG Saarlouis - Losheim am See - Sulzbach/Saar" },
            { "code": "sk-fidu-genodem1stm", "caption": "Vereinigte Volksbank eG, Brakel" },
            { "code": "sk-fidu-genodes1bbv", "caption": "Vereinigte Volksbank eG, Sindelfingen" },
            { "code": "sk-fidu-genodem1kih", "caption": "Vereinte Volksbank eG" },
            { "code": "ob-virginmoney", "caption": "Virgin Money" },
            { "code": "sk-fidu-genodef1fw1", "caption": "Volks- und Raiffeisenbank Fürstenwalde Seelow-Wriezen eG" },
            { "code": "sk-fidu-genodef1gmv", "caption": "Volks- und Raiffeisenbank Muldental eG" },
            { "code": "sk-fidu-genodef1per", "caption": "Volks- und Raiffeisenbank Prignitz eG" },
            { "code": "sk-fidu-genodef1nmb", "caption": "Volks- und Raiffeisenbank Saale-Unstrut eG" },
            { "code": "sk-fidu-genode51bex", "caption": "Volks- und Raiffeisenbank Saarpfalz eG" },
            { "code": "sk-fidu-genodef1vir", "caption": "Volksbank - Raiffeisenbank Vilshofen eG" },
            { "code": "sk-fidu-genodes1ebi", "caption": "Volksbank Albstadt eG" },
            { "code": "sk-fidu-genodef1hoy", "caption": "Volksbank Aller-Weser eG" },
            { "code": "sk-fidu-genodes1leu", "caption": "Volksbank Allgäu-Oberschwaben eG" },
            { "code": "sk-fidu-genodes1vah", "caption": "Volksbank Altshausen eG" },
            { "code": "sk-fidu-genode61azy", "caption": "Volksbank Alzey-Worms eG" },
            { "code": "sk-fidu-genodes1amm", "caption": "Volksbank Ammerbuch eG" },
            { "code": "sk-fidu-genodem1anr", "caption": "Volksbank Anröchte eG" },
            { "code": "sk-fidu-genodem1can", "caption": "Volksbank Ascheberg-Herbern eG" },
            { "code": "sk-fidu-genodes1vbk", "caption": "Volksbank Backnang eG" },
            { "code": "sk-fidu-genodem1bsu", "caption": "Volksbank Bad Salzuflen eG" },
            { "code": "sk-fidu-genodes1slg", "caption": "Volksbank Bad Saulgau eG" },
            { "code": "sk-fidu-vbrade6kxxx", "caption": "Volksbank Baden-Baden Rastatt eG" },
            { "code": "sk-fidu-genodef1bam", "caption": "Volksbank Bakum eG" },
            { "code": "sk-fidu-genodem1bau", "caption": "Volksbank Baumberge eG" },
            { "code": "sk-fidu-genodem1lps", "caption": "Volksbank Beckum-Lippstadt eG" },
            { "code": "sk-fidu-genodes1bia", "caption": "Volksbank Beilstein-Ilsfeld- Abstatt eG" },
            { "code": "sk-fidu-genoded1rko", "caption": "Volksbank Berg eG" },
            { "code": "sk-fidu-genodem1gtl", "caption": "Volksbank Bielefeld-Gütersloh eG" },
            { "code": "sk-fidu-genodem1sma", "caption": "Volksbank Bigge-Lenne eG" },
            { "code": "sk-fidu-genodes1bla", "caption": "Volksbank Blaubeuren eG" },
            { "code": "sk-fidu-genodem1boh", "caption": "Volksbank Bocholt eG" },
            { "code": "sk-fidu-genodem1boc", "caption": "Volksbank Bochum Witten eG" },
            { "code": "sk-fidu-genodef1who", "caption": "Volksbank Bramgau-Wittlage eG" },
            { "code": "sk-fidu-genode51wbo", "caption": "Volksbank Brandoberndorf eG" },
            { "code": "sk-fidu-genodef1blg", "caption": "Volksbank Braunlage eG" },
            { "code": "sk-fidu-genode61emm", "caption": "Volksbank Breisgau Nord eG" },
            { "code": "sk-fidu-genode61ihr", "caption": "Volksbank Breisgau- Markgräflerland eG" },
            { "code": "sk-fidu-genodef1hb2", "caption": "Volksbank Bremen-Nord eG" },
            { "code": "sk-fidu-genodes1rns", "caption": "Volksbank Brenztal eG" },
            { "code": "sk-fidu-genodem1bus", "caption": "Volksbank Brilon-Büren-Salzkotten eG" },
            { "code": "sk-fidu-genode61btt", "caption": "Volksbank Bruchsal-Bretten eG" },
            { "code": "sk-fidu-genode61orh", "caption": "Volksbank Bruhrain-Kraich-Hardt eG" },
            { "code": "sk-fidu-genode51but", "caption": "Volksbank Butzbach eG" },
            { "code": "sk-fidu-genodem1bo1", "caption": "Volksbank Bönen eG" },
            { "code": "sk-fidu-genodef1wzl", "caption": "Volksbank Börde-Bernburg eG" },
            { "code": "sk-fidu-genodef1boh", "caption": "Volksbank Börßum-Hornburg eG" },
            { "code": "sk-fidu-genodef1bsl", "caption": "Volksbank Bösel eG" },
            { "code": "sk-fidu-genode61bhl", "caption": "Volksbank Bühl eG" },
            { "code": "sk-fidu-genodef1ch1", "caption": "Volksbank Chemnitz eG" },
            { "code": "sk-fidu-genode51daa", "caption": "Volksbank Daaden eG" },
            { "code": "sk-fidu-genodef1dam", "caption": "Volksbank Dammer Berge eG" },
            { "code": "sk-fidu-genodef1vbd", "caption": "Volksbank Darmstadt - Südhessen eG" },
            { "code": "sk-fidu-genodes1vdl", "caption": "Volksbank Deisslingen eG" },
            { "code": "sk-fidu-genodem1dlb", "caption": "Volksbank Delbrück-Hövelhof eG" },
            { "code": "sk-fidu-genodef1dz1", "caption": "Volksbank Delitzsch eG" },
            { "code": "sk-fidu-genodef1dm1", "caption": "Volksbank Demmin eG" },
            { "code": "sk-fidu-genodef1ds1", "caption": "Volksbank Dessau-Anhalt eG" },
            { "code": "sk-fidu-genodes1deh", "caption": "Volksbank Dettenhausen eG" },
            { "code": "sk-fidu-genodem1dnw", "caption": "Volksbank Dortmund-Nordwest eG" },
            { "code": "sk-fidu-genode51dre", "caption": "Volksbank Dreieich eG" },
            { "code": "sk-fidu-volode66xxx", "caption": "Volksbank Dreiländereck eG" },
            { "code": "sk-fidu-genodef1drs", "caption": "Volksbank Dresden-Bautzen eG" },
            { "code": "sk-fidu-genoded1dhk", "caption": "Volksbank Dünnwald-Holweide eG" },
            { "code": "sk-fidu-genoded1due", "caption": "Volksbank Düren eG" },
            { "code": "sk-fidu-genoded1dne", "caption": "Volksbank Düsseldorf Neuss eG" },
            { "code": "sk-fidu-genoded1bit", "caption": "Volksbank Eifel eG" },
            { "code": "sk-fidu-genodef1esn", "caption": "Volksbank Eisenberg eG" },
            { "code": "sk-fidu-genodem1ewb", "caption": "Volksbank Elsen-Wewer-Borchen eG" },
            { "code": "sk-fidu-genodef1je1", "caption": "Volksbank Elsterland eG" },
            { "code": "sk-fidu-genoded1emr", "caption": "Volksbank Emmerich-Rees eG" },
            { "code": "sk-fidu-genodef1lth", "caption": "Volksbank Emstal eG" },
            { "code": "sk-fidu-genodef1emk", "caption": "Volksbank Emstek eG" },
            { "code": "sk-fidu-genodem1eow", "caption": "Volksbank Enniger-Ostenfelde-Westkirchen eG" },
            { "code": "sk-fidu-genoded1ere", "caption": "Volksbank Erft eG" },
            { "code": "sk-fidu-genodes1mtz", "caption": "Volksbank Ermstal-Alb eG" },
            { "code": "sk-fidu-genodef1ese", "caption": "Volksbank Esens eG" },
            { "code": "sk-fidu-genodef1eso", "caption": "Volksbank Essen-Cappeln eG" },
            { "code": "sk-fidu-genodes1ess", "caption": "Volksbank Esslingen eG" },
            { "code": "sk-fidu-genode61ett", "caption": "Volksbank Ettlingen eG" },
            { "code": "sk-fidu-genoded1evb", "caption": "Volksbank Euskirchen eG" },
            { "code": "sk-fidu-genodef1eut", "caption": "Volksbank Eutin Raiffeisenbank eG" },
            { "code": "sk-fidu-genode51fel", "caption": "Volksbank Feldatal eG" },
            { "code": "sk-fidu-genodes1nhb", "caption": "Volksbank Filder eG" },
            { "code": "sk-fidu-genodes1vft", "caption": "Volksbank Flein-Talheim eG" },
            { "code": "sk-fidu-genode61buc", "caption": "Volksbank Franken eG" },
            { "code": "sk-fidu-genode61fr1", "caption": "Volksbank Freiburg eG" },
            { "code": "sk-fidu-genodes1tet", "caption": "Volksbank Friedrichshafen-Tettnang eG" },
            { "code": "sk-fidu-genodef1hgm", "caption": "Volksbank GMHütte-Hagen-Bissendorf eG" },
            { "code": "sk-fidu-genoded1gbs", "caption": "Volksbank Gebhardshain eG" },
            { "code": "sk-fidu-genodef1ape", "caption": "Volksbank Geest eG" },
            { "code": "sk-fidu-genodef1brv", "caption": "Volksbank Geeste-Nord eG" },
            { "code": "sk-fidu-genodem1bog", "caption": "Volksbank Gemen eG" },
            { "code": "sk-fidu-genodem1ge1", "caption": "Volksbank Gescher eG" },
            { "code": "sk-fidu-genode61glm", "caption": "Volksbank Glan-Münchweiler eG" },
            { "code": "sk-fidu-genodem1grn", "caption": "Volksbank Gronau-Ahaus eG" },
            { "code": "sk-fidu-genodes1vgp", "caption": "Volksbank Göppingen eG" },
            { "code": "sk-fidu-genoded1haw", "caption": "Volksbank Haaren eG" },
            { "code": "sk-fidu-genodef1hal", "caption": "Volksbank Halle (Saale) eG" },
            { "code": "sk-fidu-genodem1hlw", "caption": "Volksbank Halle/Westf. eG" },
            { "code": "sk-fidu-genodef1hmp", "caption": "Volksbank Hameln-Stadthagen eG" },
            { "code": "sk-fidu-genode51ham", "caption": "Volksbank Hamm / Sieg eG" },
            { "code": "sk-fidu-genodef1hln", "caption": "Volksbank Haselünne eG" },
            { "code": "sk-fidu-genodem1hei", "caption": "Volksbank Heiden eG" },
            { "code": "sk-fidu-genodes1vhn", "caption": "Volksbank Heilbronn eG" },
            { "code": "sk-fidu-genoded1hmb", "caption": "Volksbank Heimbach eG" },
            { "code": "sk-fidu-genoded1hrb", "caption": "Volksbank Heinsberg eG" },
            { "code": "sk-fidu-genodem1soe", "caption": "Volksbank Hellweg eG" },
            { "code": "sk-fidu-genodem1hfv", "caption": "Volksbank Herford-Mindener Land eG" },
            { "code": "sk-fidu-genodes1vbh", "caption": "Volksbank Herrenberg-Nagold- Rottenburg eG" },
            { "code": "sk-fidu-genode51hhe", "caption": "Volksbank Heuchelheim eG" },
            { "code": "sk-fidu-genode61wt1", "caption": "Volksbank Hochrhein eG" },
            { "code": "sk-fidu-genodem1hlh", "caption": "Volksbank Hohenlimburg eG" },
            { "code": "sk-fidu-genodes1vhl", "caption": "Volksbank Hohenlohe eG" },
            { "code": "sk-fidu-genodes1vhz", "caption": "Volksbank Hohenzollern-Balingen eG" },
            { "code": "sk-fidu-genoded1khk", "caption": "Volksbank Hunsrück-Nahe eG" },
            { "code": "sk-fidu-genodef1imv", "caption": "Volksbank Immenstadt eG" },
            { "code": "sk-fidu-genodef1brg", "caption": "Volksbank Jerichower Land eG" },
            { "code": "sk-fidu-genodef1jev", "caption": "Volksbank Jever eG" },
            { "code": "sk-fidu-genode61kl1", "caption": "Volksbank Kaiserslautern eG" },
            { "code": "sk-fidu-genodem1kwk", "caption": "Volksbank Kamen-Werne eG" },
            { "code": "sk-fidu-genode61ka1", "caption": "Volksbank Karlsruhe eG" },
            { "code": "sk-fidu-genode51ks1", "caption": "Volksbank Kassel Göttingen eG" },
            { "code": "sk-fidu-genoded1kmp", "caption": "Volksbank Kempen-Grefrath eG" },
            { "code": "sk-fidu-genodem1kie", "caption": "Volksbank Kierspe eG" },
            { "code": "sk-fidu-genode61rng", "caption": "Volksbank Kirnau eG" },
            { "code": "sk-fidu-genode61wut", "caption": "Volksbank Klettgau-Wutöschingen eG" },
            { "code": "sk-fidu-genoded1kll", "caption": "Volksbank Kleverland eG" },
            { "code": "sk-fidu-genode61wie", "caption": "Volksbank Kraichgau eG" },
            { "code": "sk-fidu-genode61kth", "caption": "Volksbank Krautheim eG" },
            { "code": "sk-fidu-genoded1htk", "caption": "Volksbank Krefeld eG" },
            { "code": "sk-fidu-genode61wnm", "caption": "Volksbank Kurpfalz eG " },
            { "code": "sk-fidu-genoded1brs", "caption": "Volksbank Köln Bonn eG" },
            { "code": "sk-fidu-genode61lah", "caption": "Volksbank Lahr eG" },
            { "code": "sk-fidu-genodes1lai", "caption": "Volksbank Laichinger Alb eG" },
            { "code": "sk-fidu-genode51ldd", "caption": "Volksbank Langendernbach eG" },
            { "code": "sk-fidu-genodef1lap", "caption": "Volksbank Lastrup eG" },
            { "code": "sk-fidu-genode51lb1", "caption": "Volksbank Lauterbach-Schlitz eG" },
            { "code": "sk-fidu-genode61lek", "caption": "Volksbank Lauterecken eG" },
            { "code": "sk-fidu-genodes1leo", "caption": "Volksbank Leonberg-Strohgäu eG" },
            { "code": "sk-fidu-genode61lmb", "caption": "Volksbank Limbach eG" },
            { "code": "sk-fidu-genodef1lia", "caption": "Volksbank Lindenberg eG" },
            { "code": "sk-fidu-genodef1lig", "caption": "Volksbank Lingen eG" },
            { "code": "sk-fidu-genodef1lon", "caption": "Volksbank Lohne-Mühlen eG" },
            { "code": "sk-fidu-genodes1lbg", "caption": "Volksbank Ludwigsburg eG" },
            { "code": "sk-fidu-genodef1ngs", "caption": "Volksbank Löbau-Zittau eG" },
            { "code": "sk-fidu-genodem1lub", "caption": "Volksbank Lübbecker Land eG" },
            { "code": "sk-fidu-genodef1hlu", "caption": "Volksbank Lübeck eG" },
            { "code": "sk-fidu-genodef1nbu", "caption": "Volksbank Lüneburger Heide eG" },
            { "code": "sk-fidu-genodef1md1", "caption": "Volksbank Magdeburg eG" },
            { "code": "sk-fidu-genode61wth", "caption": "Volksbank Main-Tauber eG" },
            { "code": "sk-fidu-genode51gin", "caption": "Volksbank Mainspitze eG" },
            { "code": "sk-fidu-genodem1mrl", "caption": "Volksbank Marl-Recklinghausen eG" },
            { "code": "sk-fidu-genode61mes", "caption": "Volksbank Meßkirch eG Raiffeisenbank" },
            { "code": "sk-fidu-vbmhde5fxxx", "caption": "Volksbank Mittelhessen eG" },
            { "code": "sk-fidu-genodes1nue", "caption": "Volksbank Mittlerer Neckar eG" },
            { "code": "sk-fidu-genode61kzt", "caption": "Volksbank Mittlerer Schwarzwald eG" },
            { "code": "sk-fidu-genodef1mbg", "caption": "Volksbank Mittleres Erzgebirge eG" },
            { "code": "sk-fidu-genodef1miw", "caption": "Volksbank Mittweida eG" },
            { "code": "sk-fidu-genodes1vmn", "caption": "Volksbank Möckmühl eG" },
            { "code": "sk-fidu-genoded1mrb", "caption": "Volksbank Mönchengladbach eG" },
            { "code": "sk-fidu-genodes1mun", "caption": "Volksbank Münsingen eG" },
            { "code": "sk-fidu-genodem1ibb", "caption": "Volksbank Münsterland Nord eG" },
            { "code": "sk-fidu-genode61ngd", "caption": "Volksbank Neckartal eG" },
            { "code": "sk-fidu-genodef1neo", "caption": "Volksbank Neuenkirchen-Vörden eG" },
            { "code": "sk-fidu-genodef1hoo", "caption": "Volksbank Niedergrafschaft eG" },
            { "code": "sk-fidu-genoded1nrh", "caption": "Volksbank Niederrhein eG" },
            { "code": "ngp-vbno", "caption": "Volksbank Niederösterreich" },
            { "code": "sk-fidu-genodef1vnh", "caption": "Volksbank Nordharz eG" },
            { "code": "sk-fidu-genodef1bog", "caption": "Volksbank Nordhümmling eG" },
            { "code": "sk-fidu-genodes1pgw", "caption": "Volksbank Nordschwarzwald eG" },
            { "code": "sk-fidu-genodem1cno", "caption": "Volksbank Nottuln eG" },
            { "code": "sk-fidu-genode51obm", "caption": "Volksbank Ober-Mörlen eG" },
            { "code": "sk-fidu-genoded1wil", "caption": "Volksbank Oberberg eG" },
            { "code": "ngp-vboo", "caption": "Volksbank Oberösterreich" },
            { "code": "sk-fidu-genodem1otr", "caption": "Volksbank Ochtrup-Laer eG" },
            { "code": "sk-fidu-genodef1ede", "caption": "Volksbank Oldenburg eG" },
            { "code": "sk-fidu-genodem1wdd", "caption": "Volksbank Olpe-Wenden-Drolshagen eG" },
            { "code": "sk-fidu-genodef1osv", "caption": "Volksbank Osnabrück eG" },
            { "code": "sk-fidu-genodem1olb", "caption": "Volksbank Ostlippe eG" },
            { "code": "sk-fidu-genodef1oyt", "caption": "Volksbank Oyten eG" },
            { "code": "sk-fidu-vbpfde66xxx", "caption": "Volksbank Pforzheim eG" },
            { "code": "sk-fidu-genode61pfd", "caption": "Volksbank Pfullendorf eG" },
            { "code": "sk-fidu-genodef1pr2", "caption": "Volksbank Pirna eG" },
            { "code": "sk-fidu-genodes1vbp", "caption": "Volksbank Plochingen eG" },
            { "code": "sk-fidu-genodem1rae", "caption": "Volksbank Raesfeld eG" },
            { "code": "sk-fidu-genodef1brk", "caption": "Volksbank Raiffeisenbank Bad Kissingen eG" },
            { "code": "sk-fidu-genodef1inp", "caption": "Volksbank Raiffeisenbank Bayern Mitte eG" },
            { "code": "sk-fidu-genodef1dca", "caption": "Volksbank Raiffeisenbank Dachau eG" },
            { "code": "sk-fidu-genodef1ffb", "caption": "Volksbank Raiffeisenbank Fürstenfeldbruck eG" },
            { "code": "sk-fidu-genodef1mei", "caption": "Volksbank Raiffeisenbank Meißen Großenhain eG" },
            { "code": "sk-fidu-genodef1gr1", "caption": "Volksbank Raiffeisenbank Niederschlesien eG" },
            { "code": "sk-fidu-genodef1wev", "caption": "Volksbank Raiffeisenbank Nordoberpfalz eG" },
            { "code": "sk-fidu-genodef1n02", "caption": "Volksbank Raiffeisenbank Nürnberg eG" },
            { "code": "sk-fidu-genodef1bgl", "caption": "Volksbank Raiffeisenbank Oberbayern Südost eG" },
            { "code": "sk-fidu-genodef1r01", "caption": "Volksbank Raiffeisenbank Regensburg-Schwandorf eG" },
            { "code": "sk-fidu-genodef1mlv", "caption": "Volksbank Raiffeisenbank Rhön-Grabfeld eG" },
            { "code": "sk-fidu-genodef1vrr", "caption": "Volksbank Raiffeisenbank Rosenheim-Chiemsee eG" },
            { "code": "sk-fidu-genodef1wu1", "caption": "Volksbank Raiffeisenbank Würzburg eG" },
            { "code": "sk-fidu-genodef1vit", "caption": "Volksbank Raiffeisenbank eG Itzehoe Norderstedt Hohenwestedt" },
            { "code": "sk-fidu-genodef1hh4", "caption": "Volksbank Raiffeisenbank eG, Bad Oldesloe" },
            { "code": "sk-fidu-genodef1rn1", "caption": "Volksbank Rathenow eG" },
            { "code": "sk-fidu-genodes1rem", "caption": "Volksbank Remseck eG" },
            { "code": "sk-fidu-genodem1rhd", "caption": "Volksbank Rhede eG" },
            { "code": "sk-fidu-genoded1fhh", "caption": "Volksbank Rhein-Erft-Köln eG" },
            { "code": "sk-fidu-genode51die", "caption": "Volksbank Rhein-Lahn-Limburg eG" },
            { "code": "sk-fidu-genoded1rlw", "caption": "Volksbank Rhein-Lippe eG" },
            { "code": "sk-fidu-genode51kre", "caption": "Volksbank Rhein-Nahe-Hunsrück eG" },
            { "code": "sk-fidu-genoded1vrr", "caption": "Volksbank Rhein-Ruhr eG" },
            { "code": "sk-fidu-genode61bsk", "caption": "Volksbank Rhein-Wehra eG" },
            { "code": "sk-fidu-genoded1bna", "caption": "Volksbank RheinAhrEifel eG" },
            { "code": "sk-fidu-genoded1rbo", "caption": "Volksbank Rheinböllen eG" },
            { "code": "sk-fidu-genodef1rie", "caption": "Volksbank Riesa eG" },
            { "code": "sk-fidu-genodem1rne", "caption": "Volksbank Rietberg eG" },
            { "code": "sk-fidu-genode61lro", "caption": "Volksbank Rot eG" },
            { "code": "sk-fidu-genodes1vrw", "caption": "Volksbank Rottweil eG" },
            { "code": "sk-fidu-genodem1gbu", "caption": "Volksbank Ruhr Mitte eG" },
            { "code": "ngp-vboe", "caption": "Volksbank Salzburg" },
            { "code": "sk-fidu-genode61ma3", "caption": "Volksbank Sandhofen eG" },
            { "code": "sk-fidu-genodem1neh", "caption": "Volksbank Sauerland eG" },
            { "code": "sk-fidu-genodem1smb", "caption": "Volksbank Schermbeck eG" },
            { "code": "sk-fidu-genodem1sln", "caption": "Volksbank Schlangen eG" },
            { "code": "sk-fidu-genodem1sna", "caption": "Volksbank Schnathorst eG" },
            { "code": "sk-fidu-genode51sbh", "caption": "Volksbank Schupbach eG" },
            { "code": "sk-fidu-genodef1sww", "caption": "Volksbank Schwanewede eG" },
            { "code": "sk-fidu-genodes1tut", "caption": "Volksbank Schwarzwald-Donau-Neckar eG" },
            { "code": "sk-fidu-genode51sel", "caption": "Volksbank Seligenstadt eG" },
            { "code": "sk-fidu-genodem1sem", "caption": "Volksbank Selm-Bork eG" },
            { "code": "sk-fidu-genodem1sdn", "caption": "Volksbank Senden eG" },
            { "code": "sk-fidu-genodef1hdg", "caption": "Volksbank Solling eG" },
            { "code": "sk-fidu-genodef1spm", "caption": "Volksbank Spree-Neiße eG" },
            { "code": "sk-fidu-genodem1spo", "caption": "Volksbank Sprockhövel eG" },
            { "code": "sk-fidu-genodef1sde", "caption": "Volksbank Stade-Cuxhaven eG" },
            { "code": "sk-fidu-genode61stf", "caption": "Volksbank Staufen eG" },
            { "code": "ngp-vbsm", "caption": "Volksbank Steiermark" },
            { "code": "sk-fidu-genodef1sdl", "caption": "Volksbank Stendal eG" },
            { "code": "sk-fidu-vobadessxxx", "caption": "Volksbank Stuttgart eG" },
            { "code": "sk-fidu-genodem1sge", "caption": "Volksbank Störmede-Hörste eG" },
            { "code": "sk-fidu-genodes1vos", "caption": "Volksbank Sulmtal eG" },
            { "code": "sk-fidu-genodef1spl", "caption": "Volksbank Süd-Emsland eG" },
            { "code": "sk-fidu-genodem1scn", "caption": "Volksbank Südkirchen-Capelle-Nordkirchen eG" },
            { "code": "sk-fidu-genodem1lhn", "caption": "Volksbank Südmünsterland-Mitte eG" },
            { "code": "ngp-vbtl", "caption": "Volksbank Tirol" },
            { "code": "sk-fidu-genoded1tvb", "caption": "Volksbank Trier eG" },
            { "code": "sk-fidu-genodes1tro", "caption": "Volksbank Trossingen eG" },
            { "code": "sk-fidu-genodef1eub", "caption": "Volksbank Uelzen-Salzwedel eG" },
            { "code": "sk-fidu-ulmvde66xxx", "caption": "Volksbank Ulm-Biberach eG" },
            { "code": "sk-fidu-genode51ulr", "caption": "Volksbank Ulrichstein eG" },
            { "code": "sk-fidu-genodef1vec", "caption": "Volksbank Vechta eG" },
            { "code": "sk-fidu-genodem1vmd", "caption": "Volksbank Versmold eG" },
            { "code": "sk-fidu-genoded1vsn", "caption": "Volksbank Viersen eG" },
            { "code": "sk-fidu-genodef1vis", "caption": "Volksbank Visbek eG" },
            { "code": "sk-fidu-genodef1pl1", "caption": "Volksbank Vogtland eG" },
            { "code": "ngp-vbva", "caption": "Volksbank Vorarlberg" },
            { "code": "sk-fidu-genodef1ank", "caption": "Volksbank Vorpommern eG" },
            { "code": "sk-fidu-genodes1wel", "caption": "Volksbank Welzheim eG" },
            { "code": "sk-fidu-genode51fho", "caption": "Volksbank Weschnitztal eG" },
            { "code": "sk-fidu-genodem1wde", "caption": "Volksbank Westenholz eG" },
            { "code": "sk-fidu-genodem1wkp", "caption": "Volksbank Westerkappeln-Saerbeck eG" },
            { "code": "sk-fidu-genodef1wre", "caption": "Volksbank Westerstede eG" },
            { "code": "sk-fidu-genodem1wru", "caption": "Volksbank Wickede (Ruhr) eG" },
            { "code": "ngp-vbwi", "caption": "Volksbank Wien" },
            { "code": "sk-fidu-genodef1whv", "caption": "Volksbank Wilhelmshaven eG" },
            { "code": "sk-fidu-genodef1wim", "caption": "Volksbank Winsener Marsch eG" },
            { "code": "sk-fidu-genodef1wb1", "caption": "Volksbank Wittenberg eG" },
            { "code": "sk-fidu-genodem1bb1", "caption": "Volksbank Wittgenstein eG" },
            { "code": "sk-fidu-genode51wwi", "caption": "Volksbank Wißmar eG" },
            { "code": "sk-fidu-genodef1wop", "caption": "Volksbank Worpswede eG" },
            { "code": "sk-fidu-genodef1wul", "caption": "Volksbank Wulfsen eG" },
            { "code": "sk-fidu-genodes1zuf", "caption": "Volksbank Zuffenhausen eG" },
            { "code": "sk-fidu-genodef1z01", "caption": "Volksbank Zwickau eG" },
            { "code": "sk-fidu-genodes1utv", "caption": "Volksbank am Württemberg eG" },
            { "code": "sk-fidu-genoded1gdl", "caption": "Volksbank an der Niers eG" },
            { "code": "sk-fidu-genode61og1", "caption": "Volksbank eG - Die Gestalterbank" },
            { "code": "sk-fidu-genodef1htr", "caption": "Volksbank eG Bad Laer-Borgloh-Hilter-Melle" },
            { "code": "sk-fidu-genodef1wob", "caption": "Volksbank eG Braunschweig Wolfsburg" },
            { "code": "sk-fidu-genodef1bev", "caption": "Volksbank eG Bremerhaven-Cuxland" },
            { "code": "sk-fidu-genodef1gsc", "caption": "Volksbank eG Delmenhorst Schierbrok" },
            { "code": "sk-fidu-genodef1ruj", "caption": "Volksbank eG Gera · Jena · Rudolstadt" },
            { "code": "sk-fidu-genodes1fds", "caption": "Volksbank eG Horb-Freudenstadt" },
            { "code": "sk-fidu-genode61mos", "caption": "Volksbank eG Mosbach" },
            { "code": "sk-fidu-genodef1ohz", "caption": "Volksbank eG Osterholz-Scharmbeck" },
            { "code": "sk-fidu-genodef1hmn", "caption": "Volksbank eG Südheide - Isenhagener Land - Altmark" },
            { "code": "sk-fidu-genodef1wrh", "caption": "Volksbank eG Westrhauderfehn" },
            { "code": "sk-fidu-genodef1sum", "caption": "Volksbank eG Wümme-Wieste" },
            { "code": "sk-fidu-genodef1ade", "caption": "Volksbank eG, Adelebsen" },
            { "code": "sk-fidu-genodef1frb", "caption": "Volksbank eG, Fredenbeck" },
            { "code": "sk-fidu-genodef1ga1", "caption": "Volksbank eG, Gardelegen" },
            { "code": "sk-fidu-genode51grc", "caption": "Volksbank eG, Grebenhain" },
            { "code": "sk-fidu-genodef1pat", "caption": "Volksbank eG, Hildesheim-Lehrte-Pattensen" },
            { "code": "sk-fidu-genodef1koe", "caption": "Volksbank eG, Köthen-Bitterfeld" },
            { "code": "sk-fidu-genodef1log", "caption": "Volksbank eG, Löningen" },
            { "code": "sk-fidu-genodef1nin", "caption": "Volksbank eG, Nienburg" },
            { "code": "sk-fidu-genodef1sgh", "caption": "Volksbank eG, Sangerhausen" },
            { "code": "sk-fidu-genodef1ses", "caption": "Volksbank eG, Seesen" },
            { "code": "sk-fidu-genodef1sul", "caption": "Volksbank eG, Sulingen" },
            { "code": "sk-fidu-genodef1shr", "caption": "Volksbank eG, Syke" },
            { "code": "sk-fidu-genodem1ahl", "caption": "Volksbank eG, Warendorf" },
            { "code": "sk-fidu-genodef1wfv", "caption": "Volksbank eG, Wolfenbüttel" },
            { "code": "sk-fidu-genode61ube", "caption": "Volksbank eG, Überlingen" },
            { "code": "sk-fidu-vbrsde33xxx", "caption": "Volksbank im Bergischen Land eG" },
            { "code": "sk-fidu-genodef1oha", "caption": "Volksbank im Harz eG" },
            { "code": "sk-fidu-genodem1mas", "caption": "Volksbank im Hochsauerland eG" },
            { "code": "sk-fidu-genodef1cop", "caption": "Volksbank im Wesertal eG" },
            { "code": "sk-fidu-genodef1bck", "caption": "Volksbank in Schaumburg eG" },
            { "code": "sk-fidu-genodem1nrd", "caption": "Volksbank in Südwestfalen eG" },
            { "code": "sk-fidu-genodem1dlr", "caption": "Volksbank in der Hohen Mark eG" },
            { "code": "sk-fidu-genode51ubh", "caption": "Volksbank Überherrn eG" },
            { "code": "sk-fidu-genode51abt", "caption": "Volksbank Überwald-Gorxheimertal eG" },
            { "code": "sk-fidu-genodef1amv", "caption": "Volksbank-Raiffeisenbank Amberg eG" },
            { "code": "sk-fidu-genodes1dgg", "caption": "Volksbank-Raiffeisenbank Deggingen eG" },
            { "code": "sk-fidu-genodef1gc1", "caption": "Volksbank-Raiffeisenbank Glauchau eG" },
            { "code": "sk-fidu-genodes1vbl", "caption": "Volksbank-Raiffeisenbank Laupheim-Illertal eG" },
            { "code": "sk-fidu-genodes1vrr", "caption": "Volksbank-Raiffeisenbank Riedlingen eG" },
            { "code": "sk-fidu-wegbde77xxx", "caption": "WEG Bank AG" },
            { "code": "sk-fidu-genodef1kbw", "caption": "Waldecker Bank eG" },
            { "code": "sk-84055050", "caption": "Wartburg-Sparkasse" },
            { "code": "sk-10120100", "caption": "Weberbank" },
            { "code": "sk-29250000", "caption": "Weser-Elbe Sparkasse" },
            { "code": "sk-fidu-genode51ww1", "caption": "Westerwald Bank eG Volks- und Raiffeisenbank" },
            { "code": "sk-fidu-wibade5wxxx", "caption": "Wiesbadener Volksbank eG" },
            { "code": "sk-fidu-genodes1wbb", "caption": "Winterbacher Bank eG" },
            { "code": "sk-fidu-wiredemmxxx", "caption": "Wirecard Bank AG" },
            { "code": "ob-transferwise", "caption": "Wise" },
            { "code": "sk-fidu-genodef1sit", "caption": "Zevener Volksbank eG" },
            { "code": "gohenry", "caption": "gohenry" },
            { "code": "sk-fidu-genode51leb", "caption": "levoBank eG" },
            { "code": "sk-fidu-bvwbde2wxxx", "caption": "net-m privatbank 1891 AG Aktiengesellschaft" },
            { "code": "thkm", "caption": "thinkmoney" },
            { "code": "sk-fidu-genodef1shl", "caption": "vr bank Südthüringen eG" },
            { "code": "sk-fidu-vrbude51xxx", "caption": "vr bank Untertaunus eG" }
        ]
    },
    {
        "localName": "Instant_Open_Bank",
        "localLogoURL": "open_banking.png",
        "paymentMethod": "apmgw_Instant_Open_Banking",
        "paymentMethodDisplayName": [
            {
                "language": "en",
                "message": "Instant_Open_Bank"
            }
        ],
        "countries": [
            "AT",
            "BE",
            "EE",
            "FR",
            "DE",
            "HU",
            "IT",
            "LV",
            "LT",
            "NL",
            "PL",
            "PT",
            "ES",
            "GB"
        ],
        "currencies": [
            "EUR",
            "GBP",
            "PLN",
            "USD"
        ],
        "isDirect": "false",
        "openBankIdList": [
            { "code": "ngp-abna", "caption": "ABN AMRO Group" },
            { "code": "ngp-opsk", "caption": "BAWAG PSK" },
            { "code": "ngp-inal", "caption": "BBVA" },
            { "code": "stet-bnpa-per", "caption": "BNP Paribas" },
            { "code": "ngp-bsab", "caption": "Banco Sabadell" },
            { "code": "ngp-parx", "caption": "Banka Citadele" },
            { "code": "ob-barclays", "caption": "Barclays Bank" },
            { "code": "stet-ctbk", "caption": "Beobank" },
            { "code": "ngp-cibhpr", "caption": "CIB BanknPrivate" },
            { "code": "ngp-cgdi", "caption": "Caixa Bank" },
            { "code": "stet-cmci", "caption": "Crédit Mutuel" },
            { "code": "ngp-deutdedb", "caption": "DB Privat- und Firmenkunden Bank Aktiengesellschaft (DB PFB AG)" },
            { "code": "ob-hsbc", "caption": "HSBC Bank - Personal" },
            { "code": "ngp-ingba", "caption": "ING Austria" },
            { "code": "sk-okhb", "caption": "K&H Bank" },
            { "code": "ngp-bask", "caption": "Kutxabank" },
            { "code": "ob-lloyds", "caption": "Lloyds Bank" },
            { "code": "ob-natwest", "caption": "NatWest Bank" },
            { "code": "sbas-otphu", "caption": "OTP Banka Hungary" },
            { "code": "ngp-rabo", "caption": "Rabobank" },
            { "code": "ob-rbs", "caption": "Royal Bank of Scotland" },
            { "code": "ngp-eeuh", "caption": "SEB Pank" },
            { "code": "ob-santander", "caption": "Santander Bank" },
            { "code": "ngp-bsch", "caption": "Santander Spain" },
            { "code": "stet-soge", "caption": "Société Générale" },
            { "code": "sk-44152370", "caption": "Sparkasse an der Lippe" },
            { "code": "ngp-bkau-24you", "caption": "UniCredit Bank Austria - 24 You" },
            { "code": "ngp-ucja", "caption": "Unicaja Banco" },
            { "code": "ngp-bmpb", "caption": "Aion" },
            { "code": "ob-aib-bus", "caption": "Allied Irish Bank - Business" },
            { "code": "ob-aib-per", "caption": "Allied Irish Bank - Personal" },
            { "code": "sk-69051410", "caption": "BSK Reichenau" },
            { "code": "ngp-bcoeesmmxxx", "caption": "Banco Cooperativo Espanol" },
            { "code": "ob-bankofireland", "caption": "Bank of Ireland (UK)" },
            { "code": "ob-bankofireland-bus", "caption": "Bank of Ireland (UK) Business" },
            { "code": "ob-bankofscotland", "caption": "Bank of Scotland" },
            { "code": "ob-bankofscotland-bus", "caption": "Bank of Scotland Business" },
            { "code": "ob-bankofscotland-com", "caption": "Bank of Scotland Commercial" },
            { "code": "ngp-cahm", "caption": "Bankia" },
            { "code": "ob-barclayscard-comm-payments", "caption": "Barclaycard Commercial Payments" },
            { "code": "ob-barclayscard", "caption": "Barclaycard UK" },
            { "code": "ob-barclays-bus", "caption": "Barclays Bank Business" },
            { "code": "ob-barclays-corp", "caption": "Barclays Corporate" },
            { "code": "ob-barclays-wm", "caption": "Barclays Wealth" },
            { "code": "ngp-gkcc", "caption": "Belfius Bank" },
            { "code": "sk-10050000", "caption": "Berliner Sparkasse" },
            { "code": "sk-fidu-bevodebbxxx", "caption": "Berliner Volksbank eG Abteilung BIT" },
            { "code": "sk-21051275", "caption": "Bordesholmer Sparkasse AG" },
            { "code": "sk-29050000", "caption": "Bremer LB" },
            { "code": "ngp-cibhco", "caption": "CIB BankCorp" },
            { "code": "ngp-bcoeesmm070", "caption": "Caixa Rural Galega" },
            { "code": "ngp-bcoeesmm174", "caption": "Caja Rural El Salvador Vinaroz" },
            { "code": "ngp-bcoeesmm098", "caption": "Caja Rural Nueva Carteya" },
            { "code": "ngp-bcoeesmm005", "caption": "Caja Rural Orihuela" },
            { "code": "ngp-bcoeesmm166", "caption": "Caja Rural San Isidro les Coves Vinroma" },
            { "code": "ngp-bcoeesmm115", "caption": "Caja Rural de Adamuz" },
            { "code": "ngp-bcoeesmm150", "caption": "Caja Rural de Albal" },
            { "code": "ngp-bcoeesmm117", "caption": "Caja Rural de Algemesi" },
            { "code": "ngp-bcoeesmm104", "caption": "Caja Rural de Canete de las Torres" },
            { "code": "ngp-bcoeesmm018", "caption": "Caja Rural de Fuentealamo" },
            { "code": "ngp-bcoeesmm007", "caption": "Caja Rural de Gijon" },
            { "code": "ngp-bcoeesmm023", "caption": "Caja Rural de Granada" },
            { "code": "ngp-bcoeesmm096", "caption": "Caja Rural de Lu0027alcudia" },
            { "code": "ngp-bcoeesmm111", "caption": "Caja Rural de San Isidro Vall Du0027uxo" },
            { "code": "ngp-bcoeesmm113", "caption": "Caja Rural de San Jose Alcora" },
            { "code": "ngp-bcoeesmm080", "caption": "Caja Rural de Teruel" },
            { "code": "ngp-bcoeesmm020", "caption": "Caja Rural de Utrera" },
            { "code": "ngp-bcoeesmm060", "caption": "Cajaviva" },
            { "code": "ob-coutts", "caption": "Coutts & Company" },
            { "code": "ob-danske-bus", "caption": "Danske Bank - Business" },
            { "code": "ob-danske-per", "caption": "Danske Bank - Personal" },
            { "code": "sk-deutgb2l", "caption": "Deutsche Bank Aktiengesellschaft, Branch London (DB AG (UK))" },
            { "code": "sk-deutlull", "caption": "Deutsche Bank Aktiengesellschaft, Branch Luxemburg (DB AG (Luxembourg))" },
            { "code": "sk-deutczpx", "caption": "Deutsche Bank Aktiengesellschaft, Branch Prag (DB AG (Prague))" },
            { "code": "sk-deutatww", "caption": "Deutsche Bank Aktiengesellschaft, Branch Vienna (DB AG (Austria))" },
            { "code": "sk-deuthu2b", "caption": "Deutsche Bank Aktiengesellschaft,Branch Budapest (DB AG (Hungary))" },
            { "code": "sk-deutplpx", "caption": "Deutsche Bank Polska Spólka Akcyjna (Dt. Bank Polska S.A.)" },
            { "code": "sk-87054000", "caption": "Erzgebirgssparkasse" },
            { "code": "ob-firstdirect", "caption": "First Direct Bank" },
            { "code": "ob-firsttrust-bus", "caption": "First Trust Bank - Business" },
            { "code": "ob-firsttrust-per", "caption": "First Trust Bank - Personal" },
            { "code": "sk-50050201", "caption": "Frankfurter Sparkasse" },
            { "code": "sk-21050170", "caption": "Förde SPK" },
            { "code": "sk-fidu-genodem1gls", "caption": "GLS Gemeinschaftsbank eG" },
            { "code": "ngp-bcoeesmm190", "caption": "GlobalCaja" },
            { "code": "ob-hsbc-bus", "caption": "HSBC Bank - Business" },
            { "code": "ob-halifax", "caption": "Halifax" },
            { "code": "sk-20050550", "caption": "Hamburger Sparkasse" },
            { "code": "sk-fidu-genodef1hh2", "caption": "Hamburger Volksbank eG" },
            { "code": "sk-81052000", "caption": "Harzsparkasse" },
            { "code": "stet-hello", "caption": "Hello Bank" },
            { "code": "ngp-cazr", "caption": "Ibercaja" },
            { "code": "sk-57751310", "caption": "KSK Ahrweiler" },
            { "code": "sk-80053722", "caption": "KSK Anhalt-Bitterfeld" },
            { "code": "sk-72050101", "caption": "KSK Augsburg" },
            { "code": "sk-85550000", "caption": "KSK Bautzen" },
            { "code": "sk-26551540", "caption": "KSK Bersenbrück" },
            { "code": "sk-65450070", "caption": "KSK Biberach" },
            { "code": "sk-56250030", "caption": "KSK Birkenfeld" },
            { "code": "sk-58650030", "caption": "KSK Bitburg-Prüm" },
            { "code": "sk-60350130", "caption": "KSK Böblingen" },
            { "code": "sk-81055000", "caption": "KSK Börde" },
            { "code": "sk-86055462", "caption": "KSK Döbeln" },
            { "code": "sk-30150200", "caption": "KSK Düsseldorf" },
            { "code": "sk-82057070", "caption": "KSK Eichsfeld" },
            { "code": "sk-61150020", "caption": "KSK Esslingen-Nürtingen" },
            { "code": "sk-38250110", "caption": "KSK Euskirchen" },
            { "code": "sk-64251060", "caption": "KSK Freudenstadt" },
            { "code": "sk-70350000", "caption": "KSK Garmisch-Partenkirchen" },
            { "code": "sk-50750094", "caption": "KSK Gelnhausen" },
            { "code": "sk-82052020", "caption": "KSK Gotha" },
            { "code": "sk-25651325", "caption": "KSK Grafschaft Diepholz" },
            { "code": "sk-50852553", "caption": "KSK Groß-Gerau" },
            { "code": "sk-61050000", "caption": "KSK Göppingen" },
            { "code": "sk-48051580", "caption": "KSK Halle (Westf.)" },
            { "code": "sk-63250030", "caption": "KSK Heidenheim" },
            { "code": "sk-62050000", "caption": "KSK Heilbronn" },
            { "code": "sk-31251220", "caption": "KSK Heinsberg" },
            { "code": "sk-23052750", "caption": "KSK Herzogtum Lauenburg" },
            { "code": "sk-84054040", "caption": "KSK Hildburghausen" },
            { "code": "sk-54050220", "caption": "KSK Kaiserslautern" },
            { "code": "sk-75051565", "caption": "KSK Kelheim" },
            { "code": "sk-54051550", "caption": "KSK Kusel" },
            { "code": "sk-37050299", "caption": "KSK Köln" },
            { "code": "sk-51150018", "caption": "KSK Limburg" },
            { "code": "sk-60450050", "caption": "KSK Ludwigsburg" },
            { "code": "sk-57650010", "caption": "KSK Mayen" },
            { "code": "sk-26552286", "caption": "KSK Melle" },
            { "code": "sk-71152570", "caption": "KSK Miesbach-Tegernsee" },
            { "code": "sk-17054040", "caption": "KSK Märkisch-Oderland" },
            { "code": "sk-70250150", "caption": "KSK München Starnberg Ebersberg" },
            { "code": "sk-82054052", "caption": "KSK Nordhausen" },
            { "code": "sk-26750001", "caption": "KSK Nordhorn" },
            { "code": "sk-26250001", "caption": "KSK Northeim" },
            { "code": "sk-61450050", "caption": "KSK Ostalb" },
            { "code": "sk-65050110", "caption": "KSK Ravensburg" },
            { "code": "sk-64050000", "caption": "KSK Reutlingen" },
            { "code": "sk-56051790", "caption": "KSK Rhein-Hunsrück" },
            { "code": "sk-64250040", "caption": "KSK Rottweil" },
            { "code": "sk-83050505", "caption": "KSK Saale-Orla" },
            { "code": "sk-83050303", "caption": "KSK Saalfeld-Rudolstadt" },
            { "code": "sk-59350110", "caption": "KSK Saarlouis" },
            { "code": "sk-59450010", "caption": "KSK Saarpfalz" },
            { "code": "sk-53051396", "caption": "KSK Schlüchtern" },
            { "code": "sk-52052154", "caption": "KSK Schwalm-Eder" },
            { "code": "sk-65351050", "caption": "KSK Sigmaringen" },
            { "code": "sk-25851660", "caption": "KSK Soltau" },
            { "code": "sk-59251020", "caption": "KSK St. Wendel" },
            { "code": "sk-24151116", "caption": "KSK Stade" },
            { "code": "sk-40351060", "caption": "KSK Steinfurt" },
            { "code": "sk-81050555", "caption": "KSK Stendal" },
            { "code": "sk-29151700", "caption": "KSK Syke" },
            { "code": "sk-71052050", "caption": "KSK Traunstein-Trostberg" },
            { "code": "sk-64350070", "caption": "KSK Tuttlingen" },
            { "code": "sk-64150020", "caption": "KSK Tübingen" },
            { "code": "sk-29152670", "caption": "KSK Verden" },
            { "code": "sk-58651240", "caption": "KSK Vulkaneifel" },
            { "code": "sk-60250010", "caption": "KSK Waiblingen" },
            { "code": "sk-25152375", "caption": "KSK Walsrode" },
            { "code": "sk-51151919", "caption": "KSK Weilburg" },
            { "code": "sk-47853520", "caption": "KSK Wiedenbrück" },
            { "code": "sk-73450000", "caption": "KSSK Kaufbeuren" },
            { "code": "sk-71152680", "caption": "KSSK Wasserburg" },
            { "code": "sk-52050353", "caption": "Kasseler Sparkasse" },
            { "code": "sk-82055000", "caption": "Kyffhäusersparkasse Artern-Sondershausen" },
            { "code": "sk-28050100", "caption": "LSK Oldenburg" },
            { "code": "ob-lloyds-bus", "caption": "Lloyds Bank Business" },
            { "code": "ob-lloyds-com", "caption": "Lloyds Bank Commercial" },
            { "code": "sk-hbwe", "caption": "Magnet Bank" },
            { "code": "sk-fidu-mvbmde55xxx", "caption": "Mainzer Volksbank eG" },
            { "code": "ob-msfs", "caption": "Marks & Spencer Financial Services" },
            { "code": "sk-16050000", "caption": "Mittelbrandenburgische SPK in Potsdam" },
            { "code": "ob-monzo", "caption": "Monzo Bank" },
            { "code": "sk-15050100", "caption": "Müritz-Sparkasse" },
            { "code": "sk-25050000", "caption": "NORD/LB" },
            { "code": "sk-51050015", "caption": "Nassauische Sparkasse" },
            { "code": "ob-natwest", "caption": "NatWest Bank" },
            { "code": "ob-natwest-bus", "caption": "NatWest Bank Bankline" },
            { "code": "ob-natwest-com", "caption": "NatWest Bank ClearSpend" },
            { "code": "ob-nationwide", "caption": "Nationwide Building Society" },
            { "code": "sk-35650000", "caption": "Niederrheinische Sparkasse RheinLippe" },
            { "code": "sk-21750000", "caption": "Nord-Ostsee SPK" },
            { "code": "ngp-norsde51", "caption": "Norisbank GmbH" },
            { "code": "sk-13050000", "caption": "OstseeSparkasse Rostock" },
            { "code": "sk-85050300", "caption": "Ostsächsische Sparkasse Dresden" },
            { "code": "ob-modelo", "caption": "Ozone Modelo Test Bank" },
            { "code": "sk-fidu-genodef1p20", "caption": "PSD Bank RheinNeckarSaar eG" },
            { "code": "sk-fidu-genodef1ab1", "caption": "Raiffeisen-Volksbank Aschaffenburg eG" },
            { "code": "sk-fidu-genodes1aul", "caption": "Raiffeisenbank Aulendorf eG" },
            { "code": "sk-fidu-genodef1sfo", "caption": "Raiffeisenbank Kempten-Oberallgäu eG" },
            { "code": "ob-revolut-eea", "caption": "Revolut" },
            { "code": "ob-revolut", "caption": "Revolut" },
            { "code": "sk-84050000", "caption": "Rhön-Rennsteig SPK" },
            { "code": "sk-fidu-genodef1hr1", "caption": "Rostocker Volks- und Raiffeisenbank eG" },
            { "code": "ob-rbs-bus", "caption": "Royal Bank of Scotland Bankline" },
            { "code": "ob-rbs-com", "caption": "Royal Bank of Scotland ClearSpend" },
            { "code": "ngp-cbvi", "caption": "SEB Bankas" },
            { "code": "sk-39050000", "caption": "SPK Aachen" },
            { "code": "sk-72051210", "caption": "SPK Aichach-Schrobenhausen" },
            { "code": "sk-73350000", "caption": "SPK Allgäu" },
            { "code": "sk-83050200", "caption": "SPK Altenburger Land" },
            { "code": "sk-81055555", "caption": "SPK Altmark West" },
            { "code": "sk-71151020", "caption": "SPK Altötting-Mühldorf" },
            { "code": "sk-75250000", "caption": "SPK Amberg-Sulzbach" },
            { "code": "sk-76550000", "caption": "SPK Ansbach" },
            { "code": "sk-46650005", "caption": "SPK Arnsberg-Sundern" },
            { "code": "sk-84051010", "caption": "SPK Arnstadt-Ilmenau" },
            { "code": "sk-79550000", "caption": "SPK Aschaffenburg-Alzenau" },
            { "code": "sk-28350000", "caption": "SPK Aurich-Norden" },
            { "code": "sk-53250000", "caption": "SPK Bad Hersfeld-Rotenburg" },
            { "code": "sk-79351010", "caption": "SPK Bad Kissingen" },
            { "code": "sk-79353090", "caption": "SPK Bad Neustadt a.d. Saale" },
            { "code": "sk-49051285", "caption": "SPK Bad Oeynhausen - Porta Westfalica" },
            { "code": "sk-70054306", "caption": "SPK Bad Tölz-Wolfratshausen" },
            { "code": "sk-66250030", "caption": "SPK Baden-Baden Gaggenau" },
            { "code": "sk-77050000", "caption": "SPK Bamberg" },
            { "code": "sk-17052000", "caption": "SPK Barnim" },
            { "code": "sk-51752267", "caption": "SPK Battenberg" },
            { "code": "sk-77350110", "caption": "SPK Bayreuth" },
            { "code": "sk-41250035", "caption": "SPK Beckum-Wadersloh" },
            { "code": "sk-50950068", "caption": "SPK Bensheim" },
            { "code": "sk-71050000", "caption": "SPK Berchtesgadener Land" },
            { "code": "sk-41051845", "caption": "SPK Bergkamen-Bönen" },
            { "code": "sk-48050161", "caption": "SPK Bielefeld" },
            { "code": "sk-43050001", "caption": "SPK Bochum" },
            { "code": "sk-69050001", "caption": "SPK Bodensee" },
            { "code": "sk-68051207", "caption": "SPK Bonndorf-Stühlingen" },
            { "code": "sk-42451220", "caption": "SPK Bottrop" },
            { "code": "sk-29050101", "caption": "SPK Bremen" },
            { "code": "sk-46051240", "caption": "SPK Burbach-Neunkirchen" },
            { "code": "sk-80053000", "caption": "SPK Burgenlandkreis" },
            { "code": "sk-66251434", "caption": "SPK Bühl" },
            { "code": "sk-87050000", "caption": "SPK Chemnitz" },
            { "code": "sk-78350000", "caption": "SPK Coburg-Lichtenfels" },
            { "code": "sk-70051540", "caption": "SPK Dachau" },
            { "code": "sk-50850150", "caption": "SPK Darmstadt" },
            { "code": "sk-74150000", "caption": "SPK Deggendorf" },
            { "code": "sk-50852651", "caption": "SPK Dieburg" },
            { "code": "sk-51650045", "caption": "SPK Dillenburg" },
            { "code": "sk-72250160", "caption": "SPK Donauwörth" },
            { "code": "sk-54051990", "caption": "SPK Donnersberg" },
            { "code": "sk-44050199", "caption": "SPK Dortmund" },
            { "code": "sk-26051260", "caption": "SPK Duderstadt" },
            { "code": "sk-35050000", "caption": "SPK Duisburg" },
            { "code": "sk-39550110", "caption": "SPK Düren" },
            { "code": "sk-26251425", "caption": "SPK Einbeck" },
            { "code": "sk-18051000", "caption": "SPK Elbe-Elster" },
            { "code": "sk-22150000", "caption": "SPK Elmshorn" },
            { "code": "sk-28450000", "caption": "SPK Emden" },
            { "code": "sk-26650001", "caption": "SPK Emsland" },
            { "code": "sk-69251445", "caption": "SPK Engen-Gottmadingen" },
            { "code": "sk-45451060", "caption": "SPK Ennepetal-Breckerfeld" },
            { "code": "sk-70051995", "caption": "SPK Erding-Dorfen" },
            { "code": "sk-76350000", "caption": "SPK Erlangen Höchstadt Herzogenaurach" },
            { "code": "sk-75351960", "caption": "SPK Eschenbach Neustadt (a.d. Waldnaab)" },
            { "code": "sk-36050105", "caption": "SPK Essen" },
            { "code": "sk-46251590", "caption": "SPK Finnentrop" },
            { "code": "sk-76351040", "caption": "SPK Forchheim" },
            { "code": "sk-68050101", "caption": "SPK Freiburg-Nördlicher Breisgau" },
            { "code": "sk-70051003", "caption": "SPK Freising" },
            { "code": "sk-74051230", "caption": "SPK Freyung-Grafenau" },
            { "code": "sk-53050180", "caption": "SPK Fulda" },
            { "code": "sk-70053070", "caption": "SPK Fürstenfeldbruck" },
            { "code": "sk-76250000", "caption": "SPK Fürth" },
            { "code": "sk-42050001", "caption": "SPK Gelsenkirchen" },
            { "code": "sk-83050000", "caption": "SPK Gera-Greiz" },
            { "code": "sk-41651965", "caption": "SPK Geseke" },
            { "code": "sk-45450050", "caption": "SPK Gevelsberg-Wetter" },
            { "code": "sk-51350025", "caption": "SPK Gießen" },
            { "code": "sk-26951311", "caption": "SPK Gifhorn-Wolfsburg" },
            { "code": "sk-42450040", "caption": "SPK Gladbeck" },
            { "code": "sk-51351526", "caption": "SPK Grünberg" },
            { "code": "sk-38450000", "caption": "SPK Gummersbach-Bergneustadt" },
            { "code": "sk-76551540", "caption": "SPK Gunzenhausen" },
            { "code": "sk-26050001", "caption": "SPK Göttingen" },
            { "code": "sk-72051840", "caption": "SPK Günzburg-Krumbach" },
            { "code": "sk-47850065", "caption": "SPK Gütersloh-Rietberg" },
            { "code": "sk-45050001", "caption": "SPK HagenHerdecke" },
            { "code": "sk-25450110", "caption": "SPK Hameln-Weserbergland" },
            { "code": "sk-41050095", "caption": "SPK Hamm" },
            { "code": "sk-50650023", "caption": "SPK Hanau" },
            { "code": "sk-66451862", "caption": "SPK Hanauerland" },
            { "code": "sk-25050180", "caption": "SPK Hannover" },
            { "code": "sk-20750000", "caption": "SPK Harburg-Buxtehude" },
            { "code": "sk-66451548", "caption": "SPK Haslach-Zell" },
            { "code": "sk-43051040", "caption": "SPK Hattingen" },
            { "code": "sk-69250035", "caption": "SPK Hegau-Bodensee" },
            { "code": "sk-67250020", "caption": "SPK Heidelberg" },
            { "code": "sk-49450120", "caption": "SPK Herford" },
            { "code": "sk-43250030", "caption": "SPK Herne" },
            { "code": "sk-33450000", "caption": "SPK Hilden-Ratingen-Velbert" },
            { "code": "sk-25950130", "caption": "SPK Hildesheim Goslar Peine" },
            { "code": "sk-78050000", "caption": "SPK Hochfranken" },
            { "code": "sk-68452290", "caption": "SPK Hochrhein" },
            { "code": "sk-41651770", "caption": "SPK Hochsauerland" },
            { "code": "sk-68051004", "caption": "SPK Hochschwarzwald" },
            { "code": "sk-62251550", "caption": "SPK Hohenlohekreis" },
            { "code": "sk-21352240", "caption": "SPK Holstein" },
            { "code": "sk-47251550", "caption": "SPK Höxter" },
            { "code": "sk-72150000", "caption": "SPK IngolstadtEichstätt" },
            { "code": "sk-44550045", "caption": "SPK Iserlohn" },
            { "code": "sk-83053030", "caption": "SPK Jena-Saale-Holzland" },
            { "code": "sk-66050101", "caption": "SPK Karlsruhe" },
            { "code": "sk-45851665", "caption": "SPK Kierspe-Meinerzhagen" },
            { "code": "sk-57050120", "caption": "SPK Koblenz" },
            { "code": "sk-66350036", "caption": "SPK Kraichgau" },
            { "code": "sk-32050000", "caption": "SPK Krefeld" },
            { "code": "sk-77150000", "caption": "SPK Kulmbach-Kronach" },
            { "code": "sk-37050198", "caption": "SPK KölnBonn" },
            { "code": "sk-70052060", "caption": "SPK Landsberg-Dießen" },
            { "code": "sk-74350000", "caption": "SPK Landshut" },
            { "code": "sk-50652124", "caption": "SPK Langen-Seligenstadt" },
            { "code": "sk-51352227", "caption": "SPK Laubach-Hungen" },
            { "code": "sk-28550000", "caption": "SPK LeerWittmund" },
            { "code": "sk-48250110", "caption": "SPK Lemgo" },
            { "code": "sk-37551440", "caption": "SPK Leverkusen" },
            { "code": "sk-41650001", "caption": "SPK Lippstadt" },
            { "code": "sk-68350048", "caption": "SPK Lörrach-Rheinfelden" },
            { "code": "sk-45850005", "caption": "SPK Lüdenscheid" },
            { "code": "sk-24050110", "caption": "SPK Lüneburg" },
            { "code": "sk-79050000", "caption": "SPK Mainfranken Würzburg" },
            { "code": "sk-55050120", "caption": "SPK Mainz" },
            { "code": "sk-80055008", "caption": "SPK Mansfeld-Südharz" },
            { "code": "sk-53350000", "caption": "SPK Marburg-Biedenkopf" },
            { "code": "sk-68351865", "caption": "SPK Markgräflerland" },
            { "code": "sk-14051000", "caption": "SPK Mecklenburg-Nordwest" },
            { "code": "sk-14052000", "caption": "SPK Mecklenburg-Schwerin" },
            { "code": "sk-15051732", "caption": "SPK Mecklenburg-Strelitz" },
            { "code": "sk-85055000", "caption": "SPK Meißen" },
            { "code": "sk-73150000", "caption": "SPK Memmingen-Lindau-Mindelheim" },
            { "code": "sk-59351040", "caption": "SPK Merzig-Wadern" },
            { "code": "sk-46451012", "caption": "SPK Meschede" },
            { "code": "sk-79650000", "caption": "SPK Miltenberg-Obernburg" },
            { "code": "sk-49050101", "caption": "SPK Minden-Lübbecke" },
            { "code": "sk-76450000", "caption": "SPK Mittelfranken-Süd" },
            { "code": "sk-21450000", "caption": "SPK Mittelholstein AG" },
            { "code": "sk-58751230", "caption": "SPK Mittelmosel-Eifel-Mosel-Hunsrück" },
            { "code": "sk-87052000", "caption": "SPK Mittelsachsen" },
            { "code": "sk-82051000", "caption": "SPK Mittelthüringen" },
            { "code": "sk-74351740", "caption": "SPK Moosburg" },
            { "code": "sk-86050200", "caption": "SPK Muldental" },
            { "code": "sk-44551210", "caption": "SPK Märkisches Sauerland Hemer - Menden" },
            { "code": "sk-36250000", "caption": "SPK Mülheim a.d. Ruhr" },
            { "code": "sk-40050150", "caption": "SPK Münsterland-Ost" },
            { "code": "sk-67450048", "caption": "SPK Neckartal-Odenwald" },
            { "code": "sk-73050000", "caption": "SPK Neu-Ulm-Illertissen" },
            { "code": "sk-15050200", "caption": "SPK Neubrandenburg-Demmin" },
            { "code": "sk-72152070", "caption": "SPK Neuburg-Rain" },
            { "code": "sk-76052080", "caption": "SPK Neumarkt i.d.OPf.-Parsberg" },
            { "code": "sk-59252046", "caption": "SPK Neunkirchen" },
            { "code": "sk-30550000", "caption": "SPK Neuss" },
            { "code": "sk-76251020", "caption": "SPK Neustadt a.d. Aisch-Bad Windsheim" },
            { "code": "sk-57450120", "caption": "SPK Neuwied" },
            { "code": "sk-74250000", "caption": "SPK Niederbayern-Mitte" },
            { "code": "sk-18055000", "caption": "SPK Niederlausitz" },
            { "code": "sk-25650106", "caption": "SPK Nienburg" },
            { "code": "sk-72250000", "caption": "SPK Nördlingen" },
            { "code": "sk-76050101", "caption": "SPK Nürnberg" },
            { "code": "sk-51850079", "caption": "SPK Oberhessen" },
            { "code": "sk-70351030", "caption": "SPK Oberland" },
            { "code": "sk-85050100", "caption": "SPK Oberlausitz-Niederschlesien" },
            { "code": "sk-75350000", "caption": "SPK Oberpfalz Nord" },
            { "code": "sk-50851952", "caption": "SPK Odenwaldkreis" },
            { "code": "sk-17055050", "caption": "SPK Oder-Spree" },
            { "code": "sk-66450050", "caption": "SPK Offenburg/Ortenau" },
            { "code": "sk-46250049", "caption": "SPK Olpe-Drolshagen-Wenden" },
            { "code": "sk-26550105", "caption": "SPK Osnabrück" },
            { "code": "sk-26351015", "caption": "SPK Osterode am Harz" },
            { "code": "sk-16050202", "caption": "SPK Ostprignitz-Ruppin" },
            { "code": "sk-47650130", "caption": "SPK Paderborn-Detmold" },
            { "code": "sk-74050000", "caption": "SPK Passau" },
            { "code": "sk-72151650", "caption": "SPK Pfaffenhofen" },
            { "code": "sk-66650085", "caption": "SPK Pforzheim Calw" },
            { "code": "sk-69051620", "caption": "SPK Pfullendorf-Meßkirch" },
            { "code": "sk-16050101", "caption": "SPK Prignitz" },
            { "code": "sk-34051350", "caption": "SPK Radevormwald-Hückeswagen" },
            { "code": "sk-66550070", "caption": "SPK Rastatt-Gernsbach" },
            { "code": "sk-74151450", "caption": "SPK Regen-Viechtach" },
            { "code": "sk-75050000", "caption": "SPK Regensburg" },
            { "code": "sk-67050505", "caption": "SPK Rhein Neckar Nord" },
            { "code": "sk-54651240", "caption": "SPK Rhein-Haardt" },
            { "code": "sk-32450000", "caption": "SPK Rhein-Maas" },
            { "code": "sk-56050180", "caption": "SPK Rhein-Nahe" },
            { "code": "sk-71150000", "caption": "SPK Rosenheim-Bad Aibling" },
            { "code": "sk-24151235", "caption": "SPK Rotenburg Osterholz" },
            { "code": "sk-74351430", "caption": "SPK Rottal-Inn" },
            { "code": "sk-59050101", "caption": "SPK Saarbrücken" },
            { "code": "sk-69051725", "caption": "SPK Salem-Heiligenberg" },
            { "code": "sk-25551480", "caption": "SPK Schaumburg" },
            { "code": "sk-29152550", "caption": "SPK Scheeßel" },
            { "code": "sk-69450065", "caption": "SPK Schwarzwald-Baar" },
            { "code": "sk-79350101", "caption": "SPK Schweinfurt-Haßberge" },
            { "code": "sk-62250030", "caption": "SPK Schwäbisch Hall-Crailsheim" },
            { "code": "sk-46050001", "caption": "SPK Siegen" },
            { "code": "sk-41450075", "caption": "SPK SoestWerl" },
            { "code": "sk-84054722", "caption": "SPK Sonneberg" },
            { "code": "sk-18050000", "caption": "SPK Spree-Neiße" },
            { "code": "sk-45251515", "caption": "SPK Sprockhövel" },
            { "code": "sk-68052230", "caption": "SPK St.Blasien" },
            { "code": "sk-24151005", "caption": "SPK Stade - Altes Land" },
            { "code": "sk-50951469", "caption": "SPK Starkenburg" },
            { "code": "sk-68052328", "caption": "SPK Staufen-Breisach" },
            { "code": "sk-54250010", "caption": "SPK Suedwestpfalz" },
            { "code": "sk-23051030", "caption": "SPK Südholstein" },
            { "code": "sk-54850010", "caption": "SPK Südl. Weinstr. Landau" },
            { "code": "sk-67352565", "caption": "SPK Tauberfranken" },
            { "code": "sk-58550130", "caption": "SPK Trier" },
            { "code": "sk-17056060", "caption": "SPK Uckermark" },
            { "code": "sk-15050400", "caption": "SPK Uecker-Randow" },
            { "code": "sk-25850110", "caption": "SPK Uelzen Lüchow-Dannenberg" },
            { "code": "sk-63050000", "caption": "SPK Ulm" },
            { "code": "sk-44350060", "caption": "SPK UnnaKamen" },
            { "code": "sk-82056060", "caption": "SPK Unstrut-Hainich" },
            { "code": "sk-42650150", "caption": "SPK Vest Recklinghausen" },
            { "code": "sk-87058000", "caption": "SPK Vogtland" },
            { "code": "sk-54550010", "caption": "SPK Vorderpfalz" },
            { "code": "sk-15050500", "caption": "SPK Vorpommern" },
            { "code": "sk-52350005", "caption": "SPK Waldeck-Frankenberg" },
            { "code": "sk-52250030", "caption": "SPK Werra-Meißner" },
            { "code": "sk-57351030", "caption": "SPK Westerwald-Sieg" },
            { "code": "sk-22250020", "caption": "SPK Westholstein" },
            { "code": "sk-40154530", "caption": "SPK Westmünsterland" },
            { "code": "sk-51550035", "caption": "SPK Wetzlar" },
            { "code": "sk-68351557", "caption": "SPK Wiesental" },
            { "code": "sk-28250110", "caption": "SPK Wilhelmshaven" },
            { "code": "sk-45250035", "caption": "SPK Witten" },
            { "code": "sk-80550101", "caption": "SPK Wittenberg" },
            { "code": "sk-46053480", "caption": "SPK Wittgenstein" },
            { "code": "sk-66452776", "caption": "SPK Wolfach" },
            { "code": "sk-55350010", "caption": "SPK Worms-Alzey-Ried" },
            { "code": "sk-65351260", "caption": "SPK Zollernalb" },
            { "code": "sk-87055000", "caption": "SPK Zwickau" },
            { "code": "sk-35450000", "caption": "SPK am Niederrhein" },
            { "code": "sk-74251020", "caption": "SPK im Landkreis Cham" },
            { "code": "sk-75051040", "caption": "SPK im Landkreis Schwandorf" },
            { "code": "sk-23050101", "caption": "SPK zu Lübeck AG" },
            { "code": "sk-72050000", "caption": "SSK Augsburg" },
            { "code": "sk-25451345", "caption": "SSK Bad Pyrmont" },
            { "code": "sk-25151270", "caption": "SSK Barsinghausen" },
            { "code": "sk-42850035", "caption": "SSK Bocholt" },
            { "code": "sk-52051373", "caption": "SSK Borken" },
            { "code": "sk-25151371", "caption": "SSK Burgdorf" },
            { "code": "sk-24150001", "caption": "SSK Cuxhaven" },
            { "code": "sk-47251740", "caption": "SSK Delbrück" },
            { "code": "sk-80053572", "caption": "SSK Dessau" },
            { "code": "sk-30050110", "caption": "SSK Düsseldorf" },
            { "code": "sk-52051877", "caption": "SSK Grebenstein" },
            { "code": "sk-30351220", "caption": "SSK Haan (Rheinland)" },
            { "code": "sk-42651315", "caption": "SSK Haltern am See" },
            { "code": "sk-37551780", "caption": "SSK Langenfeld (Rheinland)" },
            { "code": "sk-40154476", "caption": "SSK Lengerich" },
            { "code": "sk-81053272", "caption": "SSK Magdeburg" },
            { "code": "sk-31050000", "caption": "SSK Mönchengladbach" },
            { "code": "sk-70150000", "caption": "SSK München" },
            { "code": "sk-36550000", "caption": "SSK Oberhausen" },
            { "code": "sk-50550020", "caption": "SSK Offenbach" },
            { "code": "sk-49051065", "caption": "SSK Rahden" },
            { "code": "sk-34050000", "caption": "SSK Remscheid" },
            { "code": "sk-40350005", "caption": "SSK Rheine" },
            { "code": "sk-52053458", "caption": "SSK Schwalmstadt" },
            { "code": "sk-17052302", "caption": "SSK Schwedt" },
            { "code": "sk-45451555", "caption": "SSK Schwelm" },
            { "code": "sk-44152490", "caption": "SSK Schwerte" },
            { "code": "sk-34250000", "caption": "SSK Solingen" },
            { "code": "sk-47853355", "caption": "SSK Versmold" },
            { "code": "sk-22151730", "caption": "SSK Wedel" },
            { "code": "sk-34051570", "caption": "SSK Wermelskirchen" },
            { "code": "sk-25152490", "caption": "SSK Wunstorf" },
            { "code": "sk-33050000", "caption": "SSK Wuppertal" },
            { "code": "sk-80053762", "caption": "Saalesparkasse" },
            { "code": "sk-59050000", "caption": "SaarLB" },
            { "code": "sk-80055500", "caption": "Salzlandsparkasse" },
            { "code": "sk-fidu-genodef1s10", "caption": "Sparda-Bank Berlin eG" },
            { "code": "sk-38452490", "caption": "Sparkasse der Homburgischen Gemeinden" },
            { "code": "sk-86055592", "caption": "Stadt- und Kreissparkasse Leipzig" },
            { "code": "starling", "caption": "Starling Bank" },
            { "code": "ngp-habalt", "caption": "Swedbank" },
            { "code": "ob-tsb", "caption": "TSB Bank" },
            { "code": "sk-51250000", "caption": "Taunus Sparkasse" },
            { "code": "ob-tesco", "caption": "Tesco Bank" },
            { "code": "ob-ulster", "caption": "Ulster Bank" },
            { "code": "ob-ulster-bus", "caption": "Ulster Bank Bankline" },
            { "code": "ob-ulster-com", "caption": "Ulster Bank ClearSpend" },
            { "code": "sk-fidu-genodes1vls", "caption": "VBU Volksbank im Unterland eG" },
            { "code": "sk-fidu-genodef1aub", "caption": "VR Bank Augsburg-Ostallgäu eG" },
            { "code": "sk-fidu-genodef1lsr", "caption": "VR Bank Main-Kinzig-Büdingen eG" },
            { "code": "sk-fidu-genodef1nea", "caption": "VR meine Bank eG" },
            { "code": "sk-fidu-genodef1mtg", "caption": "VR-Bank Handels- und Gewerbebank eG" },
            { "code": "sk-fidu-genodef1ans", "caption": "VR-Bank Mittelfranken West eG" },
            { "code": "sk-fidu-genoded1rst", "caption": "VR-Bank Rhein-Sieg eG" },
            { "code": "sk-40153768", "caption": "VSK Emsdetten-Ochtrup" },
            { "code": "sk-32250050", "caption": "VSK Goch-Kevelaer-Weeze" },
            { "code": "sk-45851020", "caption": "Vereinigte Sparkasse im Märkischen Kreis" },
            { "code": "sk-fidu-genodes1bbv", "caption": "Vereinigte Volksbank eG, Sindelfingen" },
            { "code": "ob-virginmoney", "caption": "Virgin Money" },
            { "code": "sk-fidu-genode61btt", "caption": "Volksbank Bruchsal-Bretten eG" },
            { "code": "sk-fidu-genodem1grn", "caption": "Volksbank Gronau-Ahaus eG" },
            { "code": "sk-fidu-genodes1vgp", "caption": "Volksbank Göppingen eG" },
            { "code": "sk-fidu-genoded1hrb", "caption": "Volksbank Heinsberg eG" },
            { "code": "sk-fidu-genode61wie", "caption": "Volksbank Kraichgau eG" },
            { "code": "sk-fidu-genoded1htk", "caption": "Volksbank Krefeld eG" },
            { "code": "sk-fidu-genode61wnm", "caption": "Volksbank Kurpfalz eG " },
            { "code": "sk-fidu-genoded1brs", "caption": "Volksbank Köln Bonn eG" },
            { "code": "sk-fidu-genodes1lbg", "caption": "Volksbank Ludwigsburg eG" },
            { "code": "sk-fidu-genode61kzt", "caption": "Volksbank Mittlerer Schwarzwald eG" },
            { "code": "sk-fidu-genodef1mbg", "caption": "Volksbank Mittleres Erzgebirge eG" },
            { "code": "ngp-vbno", "caption": "Volksbank Niederösterreich" },
            { "code": "sk-fidu-genodem1cno", "caption": "Volksbank Nottuln eG" },
            { "code": "ngp-vboo", "caption": "Volksbank Oberösterreich" },
            { "code": "sk-fidu-genodef1brk", "caption": "Volksbank Raiffeisenbank Bad Kissingen eG" },
            { "code": "sk-fidu-genoded1fhh", "caption": "Volksbank Rhein-Erft-Köln eG" },
            { "code": "sk-fidu-genode51die", "caption": "Volksbank Rhein-Lahn-Limburg eG" },
            { "code": "sk-fidu-genoded1bna", "caption": "Volksbank RheinAhrEifel eG" },
            { "code": "sk-fidu-genodem1rne", "caption": "Volksbank Rietberg eG" },
            { "code": "ngp-vbsm", "caption": "Volksbank Steiermark" },
            { "code": "sk-fidu-genodem1lhn", "caption": "Volksbank Südmünsterland-Mitte eG" },
            { "code": "ngp-vbtl", "caption": "Volksbank Tirol" },
            { "code": "sk-fidu-genodef1eub", "caption": "Volksbank Uelzen-Salzwedel eG" },
            { "code": "sk-fidu-genodem1mas", "caption": "Volksbank im Hochsauerland eG" },
            { "code": "sk-fidu-genodem1nrd", "caption": "Volksbank in Südwestfalen eG" },
            { "code": "sk-84055050", "caption": "Wartburg-Sparkasse" },
            { "code": "sk-10120100", "caption": "Weberbank" },
            { "code": "sk-29250000", "caption": "Weser-Elbe Sparkasse" },
            { "code": "ob-transferwise", "caption": "Wise" }
        ]
    },
]