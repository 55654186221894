import axios from 'axios';

const API_URL = process.env.REACT_APP_HOST_URL;
const USER_API_BASE_URL = API_URL + '/reports/';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const MERCHANT_SESSION_ATTRIBUTE_NAME = 'loggedInMerchant'

export const Status = {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "primary",
    NONE: undefined,
    NOTIFY: "default",
    DANGER: "danger",
};

class ApiService {
	getFromSessionStorate(key) {
		return sessionStorage.getItem(key);
	}
	setInSessionStorage(key, value) {
		sessionStorage.setItem(key, value);	
	}
	removeFromSessionStorage(key) {
		sessionStorage.removeItem(key);
	}
	retrieveTerminalConfigForOp(qpId) {
		return axios.post(API_URL + '/res/terminalConfigForQp', {
			"qpId" : qpId,
		});
	}
	retrieveQuickPayTransaction(qpId) {
		return axios.post(API_URL + '/res/retrieveQPTransaction', {
			"qpId" : qpId,
		});
	}
	retrieveInvoiceById(invoiceId) {
		return axios.post(API_URL + '/res/retrieveInvoiceTransaction', {
			"qpId" : invoiceId,
		});
	}
	quickPaymentCheckout(paymentObj) {
		return axios.post(API_URL + '/res/quickPaymentCheckout', paymentObj);
	}
	quickApmCheckout(paymentObj) {
		return axios.post(API_URL + '/res/quickApmCheckout', paymentObj);
	}
	
}
export default new ApiService();