import React, { useState, Component } from 'react';
import queryString from 'query-string';

import _ from 'lodash';

import { 
	Container, Autocomplete, TextField, Button, Typography, Box, Paper, InputAdornment, 
	Card, CardCover, CardHeader, IconButton, Divider
} from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Alert from '@mui/material/Alert';
import CreditCardInput from 'react-credit-card-input';

import Image from '../components/Image';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import ObjectUtils from '../util/objectUtil';
import { Edit, ArrowDownward, ArrowUpward, Phone, EmailOutlined, LinkOff } from '@mui/icons-material';
import ApiService from '../services/ApiService';
import ObjectUtil from '../util/objectUtil';
import { terminalMetaData } from '../referenceData/terminalMetaData';
import { terminalCheckoutForm } from '../referenceData/terminalCheckoutForm';

import ApmOpenBankSelection from './ApmOpenBankSelection';

let logo = require ('../environment/client/logo/' + process.env.REACT_APP_CLIENT + '-logo.png');

const divStyle = {
	alignItems: 'center',
	cursor: 'pointer',
};

const cardTypes = [
	{ type: 'AMEX', startPattern: /^3[47]/ },
	{ type: 'DANKORT', startPattern: /^5019/ },
	{ type: 'HIPERCARD', startPattern: /^(384100|384140|384160|606282|637095|637568|60(?!11))/ },
	{ type: 'DINERCLUB', startPattern: /^(36|38|30[0-5])/ },
	{ type: 'DISCOVER', startPattern: /^(6011|65|64[4-9]|622)/ },
	{ type: 'JCB', startPattern: /^35/ },
	{ type: 'LASER', startPattern: /^(6706|6771|6709)/ },
	{ type: 'MAESTRO', startPattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/ },
	{ type: 'MC', startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/ },
	{ type: 'UNIONPAY', startPattern: /^62/ },
	{ type: 'VISAELECTRON', startPattern: /^4(026|17500|405|508|844|91[37])/ },
	{ type: 'ELO', startPattern: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|3[0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8])|9([2-6][0-9]|7[0-8])|541|700|720|901)|651652|655000|655021)/ },
	{ type: 'VISA', startPattern: /^4/ }
];


class Checkout extends Component {
	constructor(props) {
	  	super(props);
	  	let params = queryString.parse(window.location.search);
	  	this.state = {
			qpId: params.id,
			selectTerminal: null,
			terminalInfoList: null,
			invTerminals: null,
			qpTransaction: null,
			apiError: null,
			cardHolderName: null,
			cardNumber: '',
			cardExpiry: null,
			cardCvv: null,
			nameErr: false,
			errMsg: '',
            paymentSuccess: false,
			paymentFailed: false,
			paymentError: null, 
			email: "",
			firstName: "",
			lastName: "",
			address: "",
			phone: "",
			city: "",
			state: "",
			country: "",
			zip: "",
			responseMessage: null,
			failed: false,
			success: false,

			showCCTypeError: false,
            showCCError: false,
			cardNoErr: false,
            cardExpErr: false,
            cardCvvErr: false,
			
			redirectionUrl: null,
			redirectionForm: null,

			sortApmAsc: true,
			selectedApm: null,

			openBankId: null,
			openOpenBankIdDialog: false,
			openBankIdSelectTerminal: null,
			openBankIdOptions: [],
	  	}
		this.handleCardHolderChange = this.handleCardHolderChange.bind(this);
		this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
		this.handleCardExpiryChange = this.handleCardExpiryChange.bind(this);
		this.handleCardCvvChange = this.handleCardCvvChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	componentDidMount()	{
		ApiService.retrieveTerminalConfigForOp(this.state.qpId).then(qTConfig => {
			// console.log("qTConfig.data.data", qTConfig.data.data);
			this.setState ({terminalInfoList: qTConfig.data.data, selectTerminal: null}, () => {
				ApiService.retrieveQuickPayTransaction(this.state.qpId).then(qTrans => {
					if (qTrans.data.status === "SALE_STARTED" && qTrans.data.invoiceId !== null) {
						ApiService.retrieveInvoiceById(qTrans.data.invoiceId).then((invData)=>{
							const inv = invData.data;
							let [firstName, ...lastName] = inv.customerName.split(" ");
							lastName = lastName.join(" ");
							
							const terminalList = [];
							if (inv.terminals != null && inv.terminals.length > 0) {
								inv.terminals.map((terminal, idx) => {									
									const terminalInfo = this.state.terminalInfoList.filter(t => t.terminalId === terminal.terminalId);									
									if (terminalInfo != null && terminalInfo.length > 0)	{
										const apm = terminalMetaData.filter (a => a.localName.toUpperCase() === terminalInfo[0].terminalCardType.toUpperCase());

										const acquirerCheckout = terminalCheckoutForm.filter (a => a.acquirerName.toUpperCase() === terminalInfo[0].acquirerName.toUpperCase());
										if (acquirerCheckout !== null && acquirerCheckout.length > 0) terminalInfo[0].isAcquirerCheckout = true;

										terminalList.push({
											info: terminalInfo[0], 
											metaData: (apm !== null && apm.length > 0) ? apm[0] : null
										});
									}
								});
							}
							this.setState({
								qpTransaction: qTrans.data, 
								firstName: firstName, lastName: lastName, email: inv.customerEmail, phone: inv.customerPhone,
								city: inv.customerCity, state: inv.customerState, country: inv.customerCountry,
								address: inv.customerAddress,
								invTerminals: terminalList
							});
						})
						.catch((error) => {
							// this.setState({qpTransaction: qTrans.data}); 
							this.setState({apiError: "We are currently unable to fetch the Information. Please refresh this page or try again later"
							});
						});
					}
					else {
						this.setState({qpTransaction: qTrans.data});
					}
				}).catch((error) => {
					this.setState({apiError: "We are currently unable to fetch the Information. Please refresh this page or try again later"});
				});
			});
		}).catch((error) => {
			this.setState({apiError: "We are currently unable to fetch the Information. Please refresh this page or try again later"});
		});
	}
    
	handleCardHolderChange(val){
		this.setState({nameErr: false, errMsg: '',failed: false, cardHolderName: val.target.value});
	}
	handleCardNumberChange(val){
		this.setState({showCCTypeError: false, showCCError: false, cardNoErr: false,failed: false, cardNumber: val.target.value});
	}
	handleCardExpiryChange(val){
		this.setState({showCCTypeError: false, showCCError: false, cardExpErr: false,failed: false, cardExpiry: val.target.value});
	}
	handleCardCvvChange(val) {
		this.setState({showCCTypeError: false, showCCError: false, cardCvvErr: false,failed: false, cardCvv: val.target.value});
	}

	handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : inputValue
        });        
    }

	handleSubmit() {
		if(!ObjectUtils.isNullorEmpty(this.state.firstName) 
			&& !ObjectUtils.isNullorEmpty(this.state.lastName)
			&& !ObjectUtils.isNullorEmpty(this.state.phone)
			&& !ObjectUtils.isNullorEmpty(this.state.email)
			&& !ObjectUtils.isNullorEmpty(this.state.address)
			&& !ObjectUtils.isNullorEmpty(this.state.city)
			&& !ObjectUtils.isNullorEmpty(this.state.country)
			&& !ObjectUtils.isNullorEmpty(this.state.zip)) {

			var request = Object.assign({}, this.state);

			const terminalInfo = {...this.state.selectTerminal.info};

			delete request.terminalInfoList;
			delete request.qpTransaction;
			delete request.invTerminals;
			delete request.openBankIdOptions;
			delete request.openBankIdSelectTerminal;
			delete request.openOpenBankIdDialog;
			delete request.sortApmAsc;

			request.selectTerminal = {...this.state.selectTerminal.info};

			if (terminalInfo.terminalPaymentMode === "APM" || terminalInfo.isAcquirerCheckout === true) {
				// const url = "" + window.location;
				// const pathArray = url.split( '/' );
				// request.redirect_url = pathArray[0] + '//' + pathArray[2] + "/status";
				request.redirect_url = "https://checkout.papelpayments.com/status";
				console.log("request: ", request);

				ApiService.quickApmCheckout(request).then((res) => {
					if (res.data.success === true) {
						this.setState({ failed: false, success: true, redirectionUrl: res.data.redirectUrl, redirectionForm: res.data.responseForm});
					}
					else {
						this.setState({ failed: true, success: false, responseMessage: res.data.responseMessage })
					}
				}).catch(e => {
					this.setState({ failed: true, responseMessage: "Error" });
				});
			}
			else	{	//Credit / Debit Card
				if (this.state.cardNoErr === true || this.state.cardExpErr === true || this.state.cardCvvErr === true) {
					this.setState({showCCError: true, showCCTypeError: false});
					return;
				}
	
				const inputCardType = this.getCardType(this.state.cardNumber);
				if (inputCardType === null || inputCardType.type !== terminalInfo.terminalCardType) {
					this.setState({showCCError: false, showCCTypeError: true});
					return;
				}

				request.cardHolderName = request.firstName + " " + request.lastName;
				request.cardExpiry = request.cardExpiry.replaceAll(" ", "");
				request.cardNumber = request.cardNumber.replaceAll(" ", "");

				ApiService.quickPaymentCheckout(request).then((res) => {
					if (res.data.success === true) {

						this.setState({ failed: false, success: true, responseMessage: res.data.responseMessage });
					}
					else {
						this.setState({ failed: true, success: false, responseMessage: res.data.responseMessage })
					}
				}).catch(e => {
					this.setState({ failed: true, responseMessage: "Error" });
				});
			}
		}
	}

	getTerminalDisplayText (terminal) {
		if (terminal.info.terminalPaymentMode === "CC" || terminal.info.terminalPaymentMode === "DC") {
			const paymentMode = terminal.info.terminalPaymentMode === "CC" ? "Credit Card" : 
				(terminal.info.terminalPaymentMode === "DC" ? "Debit Card" : "Unknwon Card");
			const ccTypeName = (terminal.info.terminalCardType === "MC") ? "Master Card" :
				(terminal.info.terminalCardType === "VISA") ? "Visa" : "Unknown Card";
			const ccLogo = terminal.info.terminalPaymentMode.toLowerCase() + "_" + terminal.info.terminalCardType.toLowerCase() + ".png";
			//console.log ("{ccTypeName, ccLogo, payMode: paymentMode }", ccTypeName, ccLogo, paymentMode)
			return { ccTypeName, ccLogo, payMode: paymentMode };
		}
		else {
			const paymentMode = (terminal.info.terminalPaymentMode === "APM" ? "Alternate Method" : "Payment Method");
			const ccTypeName = this.toTitleCase(terminal.info.terminalCardType);
			//console.log ("terminal.terminalCardType.toUpperCase()", terminal.terminalCardType.toUpperCase());
			const ccLogo = terminal.metaData.localLogoURL;
			//console.log ("{ccTypeName, ccLogo, payMode: paymentMode }", ccTypeName, ccLogo, paymentMode)
			return { ccTypeName, ccLogo, payMode: paymentMode };
		}
	}

	getCardType(value) {
		return cardTypes.filter(function (cardType) { return cardType.startPattern.test(value); })[0];
	}

	onTerminalSelection(terminal) {
		// if (terminal.terminalPaymentMode === "APM") {
		// 	//Call APM API with terminal.paymentMethod to get redirectionUrl
		// 	this.setState({
		// 		selectTerminal: terminal,
		// 		redirectionUrl: "https://cdn-int.safecharge.com/safecharge_resources/v1/apm-emulator.html?url=https://test.safecharge.com/APMNotificationGateway/ApmConnector/778621CD7FB23F255B378569AAE5E91A&logoUrl=https://ppp-test.safecharge.com/ppp/resources/img/svg/solid-white/apm_test_nuvei.svg"
		// 	});
		// }
		// else {
			const openBankIdOptions = (terminal.metaData != null && 
				terminal.metaData.openBankIdList != null && terminal.metaData.openBankIdList.length > 0) 
				? terminal.metaData.openBankIdList : null;

			//console.log ("terminal.metaData", openBankIdOptions);
			
			if (openBankIdOptions == null) {
				this.setState({
					openBankIdOptions: null,
					selectTerminal: terminal
				});
			}
			else {
				this.setState({
					openBankIdOptions: openBankIdOptions,
					openBankIdSelectTerminal: terminal,
					openOpenBankIdDialog: true
				});
			}
		// }
	}

	showTerminalSelectionOptions(classes, terminalList) {
		const terminalButtons = [];
		if (terminalList != null) {
			var i = 0;
			terminalList.map(terminal => {
				const payInfo = this.getTerminalDisplayText(terminal);
				const bottom = ++i === terminalList.length ? "0px" : "10px";
				terminalButtons.push (
				<div key={terminal.info.terminalId} style={divStyle} onClick={() => this.onTerminalSelection(terminal)}>
					<Card style={{marginBottom: bottom, height:"80px", backgroundColor:"#f6f6f6"}}>
						<CardHeader
							avatar={<Image alt={payInfo.ccTypeName + " Logo"} 
									src={(terminal.info.terminalPaymentMode === "APM" ? "apm/" : "") + payInfo.ccLogo} 
									fallbackSrc={"blank.png"} style={{width: "60px"}} />}
							action={
								<IconButton size="large" color="primary" onClick={() => this.onTerminalSelection(terminal)}
									aria-label="select"><KeyboardDoubleArrowRightIcon /></IconButton>
							}
							title={<Typography variant="h5" >{payInfo.ccTypeName}</Typography>}
							subheader={payInfo.payMode}
						/>					
					</Card>
				</div>);
			});
		}
		return terminalButtons;
	}

	isArrayEmpty (arr) {
		return arr == null || arr === undefined || arr.length === 0;
	}

	toTitleCase(str) {
		return str.replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
	}

	render() {
		const { classes } = this.props; 
		const {
			qpTransaction, responseMessage, failed, success, invTerminals, 
			selectTerminal, redirectionUrl, redirectionForm, apiError, sortApmAsc, 
			selectedApm, openBankIdSelectTerminal, openBankId, openOpenBankIdDialog, openBankIdOptions 
		} = this.state;

		//console.log("openBankIdSelectTerminal", openBankIdSelectTerminal);
		//console.log("redirectionForm", redirectionForm);

		//console.log("terminalMetaData", terminalMetaData);

		// console.log (qpTransaction, responseMessage, failed, success, invTerminals,
		// 		selectTerminal, redirectionUrl, apiError, sortApmAsc, selectedApm);

		const payInfo =  (selectTerminal === null) ? {} : this.getTerminalDisplayText(selectTerminal);

		var paymentModeGroups = [];
		if ((invTerminals != null && invTerminals.length > 0)) {
			invTerminals.map((invTerm, idx) => {
				const payMode = invTerm.info.terminalPaymentMode;
				if (paymentModeGroups[payMode] == null || paymentModeGroups[payMode] === undefined) {
					paymentModeGroups[payMode] = [];
				}
				paymentModeGroups[payMode].push (invTerm);
			});
		}

		//console.log("paymentModeGroups", paymentModeGroups);

		let apmTerminalList = paymentModeGroups["APM"] === undefined ? [] : ObjectUtil.DeepClone(paymentModeGroups["APM"]);

		if (selectedApm !== null) {
			const filteredApm = apmTerminalList.filter(a => a.info.terminalCardType === selectedApm.info.terminalCardType);
			if (filteredApm !== null && filteredApm !== undefined) apmTerminalList = [filteredApm[0]];
		}

		if (apmTerminalList != null && apmTerminalList !== undefined) {
			const multiplier = (sortApmAsc === true) ? 1 : -1;
			apmTerminalList = apmTerminalList.sort((a, b) => a.info.terminalCardType.toUpperCase() > b.info.terminalCardType.toUpperCase() ? 1 * multiplier : -1 * multiplier)
		}

		//console.log (paymentModeGroups["CC"]);

		// if (redirectionForm) {
			
		// 	// var extractscript=/<script>(.+)<\/script>/gi.exec(redirectionForm);
		// 	// var newRedirectionForm = redirectionForm.replace(extractscript[0],"");
		// 	// window.eval(newRedirectionForm[1]);
		// 	// return <section dangerouslySetInnerHTML={{__html: newRedirectionForm}} />

		// 	return <section dangerouslySetInnerHTML={{__html: 
		// 		<script language="javascript">
		// 			var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
		// 			win.document.body.innerHTML = {redirectionForm};
		// 		</script>
		// 	}} />
		// }
		
		return (<Container maxWidth="sm" style={{marginTop: '1em'}} align="left">
			<Paper style={{margin: '1em', padding: '1em'}} elevation={0}>
				<div align="center" style={{marginBottom: '1em'}}>
					<img src={logo} alt={process.env.REACT_APP_CLIENT}/>
				</div>
				{apiError !== null ? <Alert severity="error" elevation="0">{apiError}</Alert> 
				: qpTransaction != null && qpTransaction.status !== "SALE_STARTED" ? 
					<React.Fragment>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: "20px"}}>
								<Typography variant="h5" style={{fontWeight: "550" }}>Payment in incorrect state.</Typography>
							</GridItem>
							</GridContainer>
					</React.Fragment>
				: selectTerminal === null ? 
					<React.Fragment>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: "20px"}}>
								<Typography variant="h5" style={{fontWeight: "550" }}>Select a payment method</Typography>
							</GridItem>
							{(!(this.isArrayEmpty(paymentModeGroups["CC"]) && this.isArrayEmpty(paymentModeGroups["DC"]))) &&
								<>
								<GridItem xs={12} sm={12} md={12} lg={12} >
									<Typography variant="h6" style={{marginTop: '1em', marginBottom: '0.5em'}}>
										Credit / Debit Card</Typography>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={12}  
									style={{padding: "0.5em", border: '1px solid rgba(0, 0, 0, 0.05)', borderRadius: "10px"}}>
										{this.showTerminalSelectionOptions(classes, paymentModeGroups["CC"])}
										{this.showTerminalSelectionOptions(classes, paymentModeGroups["DC"])}
									</GridItem>
								</>
							}
							
						</GridContainer>						
						{!this.isArrayEmpty(apmTerminalList) &&
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop: "20px"}}>
									<Typography variant="h6">Other Payment Options</Typography>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={12} 
									style={{ marginLeft:"15px", marginBottom:"5px", 
										padding: "2px", display:"flex", flexDirection:"row" }}>
									<Autocomplete disablePortal id="search-apm-auto-complete" 
										sx={{ width: "85%" }} 
										options={apmTerminalList}
										value={selectedApm == null ? "" : selectedApm.info.terminalCardType}
										onChange={(event, value) => this.setState({selectedApm: value})}
										autoHighlight
										getOptionLabel={(option) => {
											return option.constructor === Object ?
												(ObjectUtil.isNullObject(option) ? "" : option.info.terminalCardType) : option;
										}}
										renderOption={(props, option) => (
											<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
												{option.info.terminalCardType}
											</Box>
										)}
										renderInput={(params) => <TextField {...params} label="Search payment methods..." />} />
									<IconButton size="large" color="primary"
										style={{ width:"15" }} 
										onClick={() => this.setState({sortApmAsc: !this.state.sortApmAsc})}
										aria-label="select">{sortApmAsc ? <ArrowDownward/> : <ArrowUpward/>}</IconButton>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={12}
									style={{padding: "0.5em", border: '1px solid rgba(0, 0, 0, 0.05)', borderRadius: "10px"}}>
									{this.showTerminalSelectionOptions(classes, apmTerminalList)}
								</GridItem>
							</GridContainer>
						}
					</React.Fragment>
				: redirectionUrl ? window.location.replace(redirectionUrl)
				: redirectionForm ? <div dangerouslySetInnerHTML={{__html: redirectionForm}} />
				: <>
					{failed && <Alert severity="error" elevation="0"> {responseMessage}</Alert>}
					{success && <Alert severity="success" elevation="0">{responseMessage}</Alert>}
					{qpTransaction === null ? 
						<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<Typography variant="h5" style={{marginTop: '2em', marginBottom: '1em'}}>
									Loading... Please wait.
								</Typography>					
							</GridItem>
						</GridContainer>
					: qpTransaction.status !== "SALE_STARTED" ? 
						<GridContainer style={{ marginTop: "4em", alignItems: 'center', justifyContent: 'center'}}>
							<GridItem xs={2} sm={2} md={2} lg={2}>
								<LinkOff style={{fontSize: "2em"}} /> 
							</GridItem>
							<GridItem xs={10} sm={10} md={10} lg={10}>
								<Typography variant="h5" style={{padding: '1em'}}>Payment link has expired.</Typography>
							</GridItem>
						</GridContainer>
					: <>
						<Paper style={{padding: '0.5em', marginBottom: '1em'}} elevation={2}>
							<GridContainer>
								<GridItem xs={12} sm={9} md={12} lg={3}>
									<Image alt={payInfo.ccLogoAlt} 
										src={(selectTerminal.info.terminalPaymentMode === "APM" ? "apm/" : "") + payInfo.ccLogo} 
										fallbackSrc={"blank.png"} style={{width: "80px"}} />
								</GridItem>
								<GridItem xs={12} sm={9} md={12} lg={6} 
									style={{display: "flex", flexFlow: "row nowrap", alignItems: "center"}}
								>
									<Typography variant="h5">{payInfo.ccTypeName}</Typography>									
								</GridItem>
								<GridItem xs={12} sm={3} md={12} lg={3}
									style={{display: "flex", flexFlow: "row nowrap",justifyContent: "center", alignItems: "center"}}
								>
									<Button variant="text" sx={{borderRadius: "20px" }} size="medium" color="primary" 
										onClick={() => this.setState({selectTerminal: null})}><Edit />Change</Button>
								</GridItem>
								<GridItem xs={12} sm={4} md={6} lg={3}
									style={{display: "flex", flexFlow: "row nowrap",justifyContent: "center", alignItems: "center"}}>
									<Typography variant="h6" style={{color: "#B12704"}}>Amount</Typography>
								</GridItem>
								<GridItem xs={12} sm={8} md={6} lg={9}
									style={{display: "flex", flexFlow: "row nowrap", alignItems: "center"}}>
									<Typography variant="body2" style={{color: "#B12704"}}>{qpTransaction && `${qpTransaction.amount.toFixed(2)} ${qpTransaction.currency}`}</Typography>
								</GridItem>
							</GridContainer>
						</Paper>						
						<Typography variant="body2" style={{marginBottom: '1em',}}>Contact Information</Typography>
						<Paper style={{marginBottom: '1em', padding: '1em'}} elevation={2}>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<TextField name="phone" placeholder="Phone" fullWidth 
										InputProps={{
											startAdornment: (
											<InputAdornment position="start">
												<Phone />
											</InputAdornment>
											),
										}}
										error={ObjectUtils.isNullorEmpty(this.state.phone)} size="small" value={this.state.phone} 
										onChange={this.handleInputChange} 
										helperText={ObjectUtils.isNullorEmpty(this.state.phone) && "Value is required" }/>
								</GridItem>
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<TextField name="email" placeholder="Email" fullWidth 
										InputProps={{
											startAdornment: (
											<InputAdornment position="start">
												<EmailOutlined />
											</InputAdornment>
											),
										}}
										error={ObjectUtils.isNullorEmpty(this.state.email)}  margin="dense" type="email" size="small" 
										value={this.state.email} onChange={this.handleInputChange} 
										required helperText={ObjectUtils.isNullorEmpty(this.state.email) && "Value is required" }/>
							</GridItem>
							</GridContainer>
						</Paper>
						<Typography variant="body2" style={{marginBottom: '1em',}}>Customer Name</Typography>
						<Paper style={{marginBottom: '1em', padding: '1em'}} elevation={2}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={6} lg={6}>
								<TextField name="firstName" placeholder="First Name" fullWidth 
									error={ObjectUtils.isNullorEmpty(this.state.firstName)} size="small" value={this.state.firstName} 
									onChange={this.handleInputChange} required 
									helperText={ObjectUtils.isNullorEmpty(this.state.firstName) && "Value is required" }/>
							</GridItem>
							<GridItem xs={12} sm={12} md={6} lg={6}>					
								<TextField name="lastName" placeholder="Last Name" fullWidth 
									error={ObjectUtils.isNullorEmpty(this.state.lastName)} size="small" value={this.state.lastName} 
									onChange={this.handleInputChange} required 
									helperText={ObjectUtils.isNullorEmpty(this.state.lastName) && "Value is required" }/>
							</GridItem>
						</GridContainer>
						</Paper>
						<Typography variant="body2" style={{marginBottom: '1em',}}>Billing address</Typography>
						<Paper style={{marginBottom: '1em', padding: '1em'}} elevation={2}>					
							<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<TextField name="address" placeholder="Address" fullWidth 
									error={ObjectUtils.isNullorEmpty(this.state.address)} size="small" value={this.state.address} 
									onChange={this.handleInputChange} 
									helperText={ObjectUtils.isNullorEmpty(this.state.address) && "Value is required" }/>
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={6}>
								<TextField name="city" placeholder="City" fullWidth 
									error={ObjectUtils.isNullorEmpty(this.state.city)} size="small" value={this.state.city} 
									onChange={this.handleInputChange} required 
									helperText={ObjectUtils.isNullorEmpty(this.state.city) && "Value is required" }/>
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={6}>
								<TextField name="zip" placeholder="Zip" fullWidth 
									error={ObjectUtils.isNullorEmpty(this.state.zip)} size="small" value={this.state.zip} 
									onChange={this.handleInputChange} 
									helperText={ObjectUtils.isNullorEmpty(this.state.zip) && "Value is required" }/>
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12}>					
								{/*<TextField name="state" placeholder="State" fullWidth size="small"
									value={this.state.state} onChange={this.handleInputChange}  />*/}					
								<TextField name="country" placeholder="Country" fullWidth 
									error={ObjectUtils.isNullorEmpty(this.state.country)} size="small" value={this.state.country} 
									onChange={this.handleInputChange} 
									helperText={ObjectUtils.isNullorEmpty(this.state.country) && "Value is required (Only 2 Character Country code e.g. US )" }/>
							</GridItem>					
							</GridContainer>
						</Paper>
						{selectTerminal.info.terminalPaymentMode !== "APM" &&
						selectTerminal.info.isAcquirerCheckout !== true &&
							<>
								<Typography variant="body2" style={{marginBottom: '1em',}}>Card Information</Typography>
								<Paper style={{marginBottom: '1em', padding: '1em'}} elevation={2}>					
									<Box align="center" style={{margin: '1em'}}>
									<CreditCardInput required fullWidth
										cardNumberInputProps={{ value: this.state.cardNumber, 
											onChange: this.handleCardNumberChange, onError: () => this.setState({cardNoErr: true}) }}
										cardExpiryInputProps={{ value: this.state.cardExpiry, 
											onChange: this.handleCardExpiryChange, onError: () => this.setState({cardExpErr: true}) }}
										cardCVCInputProps={{ value: this.state.cardCvv, 
											onChange: this.handleCardCvvChange, onError: () => this.setState({cardCvvErr: true}) }} 
										fieldClassName="input"
									/>
										{this.state.showCCError && 
											<Box><Typography variant="caption" color="red">Credit card details are mandatory</Typography></Box>
										}
										{this.state.showCCTypeError && 
											<Box>
												<Typography variant="caption" color="red">
													Payment method does not match the Credit card details provided</Typography>										
											</Box>
										}
									</Box>
								</Paper>
							</>
						}
						{selectTerminal.info.terminalPaymentMode === "APM" ?
						<Button variant="contained" color="primary" sx={{borderRadius: "20px"}} onClick={this.handleSubmit} 
							fullWidth>Redirect to {payInfo.ccTypeName} {qpTransaction && `(${qpTransaction.amount.toFixed(2)} ${qpTransaction.currency})`}
						</Button>
						: <Button variant="contained" color="primary" sx={{borderRadius: "20px"}} onClick={this.handleSubmit} 
							fullWidth>Pay {qpTransaction && `(${qpTransaction.amount.toFixed(2)} ${qpTransaction.currency})`}
						</Button>}
					</>
					}
				</>
			}
			</Paper>
			{openBankIdOptions != null && openBankIdOptions.length > 0 && 
			<ApmOpenBankSelection
				keepMounted
				title={openBankIdSelectTerminal == null ? "" : openBankIdSelectTerminal.metaData.localName}
				open={openOpenBankIdDialog}
				options={openBankIdOptions}
				onClose={(value) => {
					if (value == null) {
						this.setState({ openOpenBankIdDialog: false, openBankIdOptions: [], openBankId: null })	
					}
					else {
						this.setState({
							openOpenBankIdDialog: false,
							openBankIdOptions: [],
							openBankId: value,
							selectTerminal: openBankIdSelectTerminal
						})
					}
				}}
				value={openBankId}
			/>}
		</Container>);
	}
}

export default Checkout;