import React, { useState, Component } from 'react';
import { ThemeProvider} from '@mui/material/styles';

class AppTheme extends Component {
    constructor(props) {
        super(props);
	  	
	  	this.state = {
            module: null,
            component: this.props.component
        }
    }

    componentDidMount() {
        import(`./${process.env.REACT_APP_CLIENT}`)
        .then(module => this.setState({ module: module.default }));
    }
   
    render() {
        const {module, component} = this.state;
        return module == null ? null : <ThemeProvider theme={module.default}>{component}</ThemeProvider>
    }    
}

export default AppTheme;