import React, { Component } from "react";
import './App.css';
import { ThemeProvider} from '@mui/material/styles';
import CheckoutApp from './CheckoutApp';
import AppTheme from "./environment/client/themes/AppTheme";

class App extends Component {
  render() {
	return <AppTheme component={
    <div className="App">
		  <CheckoutApp />
    </div>} />
  }
}

export default App;
