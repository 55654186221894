import React, { useState, Component } from 'react';
import { Link } from 'react-router-dom'
import queryString from 'query-string';

import _ from 'lodash';

import { Button, Container, Paper, Typography } from '@mui/material';
import { CreditScore, Error, InfoOutlined } from '@mui/icons-material';

import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';

let logo = require ('../environment/client/logo/' + process.env.REACT_APP_CLIENT + '-logo.png');

class Status extends Component {
	constructor(props) {
	  	super(props);
	  	let params = queryString.parse(window.location.search);
	  	this.state = {
			txnStatus: params.txnStatus,
			txnStatusDesc: params.txnStatusDesc,
			redirect_url: params.redirect_url || null,
	  	}
		this.handleDone = this.handleDone.bind(this);
	}

	componentDidMount()	{
		
	}
    
	handleDone() {
		if (this.state.redirect_url !== null)
			window.location.href = this.state.redirect_url;
		else
			window.close();
	}

	render() {
		const { classes } = this.props; 
		const {txnStatus, txnStatusDesc } = this.state;

		//console.log (txnStatus, txnStatusDesc);

		return (<Container maxWidth="sm" style={{marginTop: '1em'}} align="left">
			<Paper style={{margin: '1em', padding: '1em'}} elevation={0}>
				<div align="center" style={{marginBottom: '4em'}}>
					<img src={logo} alt="Papel"/>
				</div>			
				<GridContainer>
					{txnStatus === "SUCCESS" 
						? <>
							<GridItem xs={12} sm={12} md={12} lg={12} 
					 			style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
								<CreditScore style={{fontSize: "100px", color: "#43D19E"}} />
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12} 
								style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
								<Typography variant="h2" style={{fontWeight: "550",color: "#43D19E" }}>Thank You!</Typography>
							</GridItem>
							</>
						: txnStatus === "PENDING" 
						? <>
							<GridItem xs={12} sm={12} md={12} lg={12} 
					 			style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
								<InfoOutlined style={{fontSize: "100px", color: "#777"}} />
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12} 
								style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
								<Typography variant="h2" style={{fontWeight: "550",color: "#777" }}>Your transaction is pending.</Typography>
							</GridItem>
							</>
						: txnStatus === "BACK" 
						? <>
							<GridItem xs={12} sm={12} md={12} lg={12} 
					 			style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
								<InfoOutlined style={{fontSize: "100px", color: "#777"}} />
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12} 
								style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
								<Typography variant="h2" style={{fontWeight: "550",color: "#777" }}>Please retry payment again.</Typography>
							</GridItem>
							</>
						: <>
							<GridItem xs={12} sm={12} md={12} lg={12} 
					 			style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
								<Error style={{fontSize: "100px", color: "#F0555A"}} />
							</GridItem>
							<GridItem xs={12} sm={12} md={12} lg={12} 
								style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
								<Typography variant="h2" style={{fontWeight: "550",color: "#F0555A" }}>Payment Failed!</Typography>
							</GridItem>
						</>
					}
					<GridItem xs={12} sm={12} md={12} lg={12} 
					 	style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: '1em'}}>
						<Typography variant="h6" style={{marginTop: '1em', marginBottom: '0.5em'}}>{txnStatusDesc}</Typography>
					</GridItem>
				<GridItem xs={12} sm={12} md={12} lg={12} 
					style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: '1em'}}>
					{this.state.redirect_url !== null 
						? <Button variant="contained" color="primary" 
							sx={{borderRadius: "20px", marginTop: '2em' }} 
							onClick={() => { window.location.href=this.state.redirect_url; }}
							fullWidth>Done</Button>
						: <Button variant="contained" color="primary" sx={{borderRadius: "20px", marginTop: '2em' }} 
							onClick={() => window.close()} fullWidth>Close</Button>
					}
					</GridItem>
				</GridContainer>
			</Paper>
		</Container>);
	}
}

export default Status;