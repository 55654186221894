//https://www.npmjs.com/package/exact-math
import { v1 as uuidv1 } from "uuid";

class ObjectUtils {
  generateUUID() {
    return uuidv1() + "-" + Math.floor(Math.random() * 1000);
  }

  isJson(item) {
    item = typeof item !== "string" ? JSON.stringify(item) : item;
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    return typeof item === "object" && item !== null;
  }

  isNumber(str) {
    return (str === null || (typeof str === "string" && str.trim() === "")) ? false : !isNaN(str);
  }

  NumVal(str) {
    return this.isNumber("" + str) ? parseFloat("" + str) : 0;
  }

  isNull(obj) {
    return obj === null || obj === undefined;
  }

  isValidToastMessage(obj) {
    return (
      !this.isNullObject(obj) &&
      obj.title &&
      !this.isNull(obj.title) &&
      obj.description &&
      !this.isNull(obj.description)
    );
  }

  isNullorEmpty(obj) {
    return (
      this.isNull(obj) === true ||
      (typeof obj === "string" && obj.trim() === "")
    );
  }

  isNullObject(obj) {
    return (
      this.isNullorEmpty(obj) ||
      (obj.constructor === Object && Object.keys(obj).length === 0)
    );
  }
  
  splitArrayIntoChunksOfLen(arr, len) {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  }

  TitleCase = (text) => {
    if (this.isNullorEmpty(text)) return "";
    var sentence = text.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  };

  DeepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };
}

let oUtil = new ObjectUtils();
export default oUtil;
