import React, { useState, Component } from 'react';
import { Link, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import queryString from 'query-string';

import Checkout from './pages/Checkout';
import Status from './pages/Status';

const CheckoutApp = () => {
	return (
	  <Router>
		  <Routes>
			<Route exact path="/" element={<Checkout/>}/>
			<Route exact path="/status" element={<Status/>}/>
			<Route path="*" element={<Checkout/>}/>
		  </Routes>
	  </Router>
	);
}

export default CheckoutApp;