import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      alt: props.alt,
      errored: false,
    };
  }

  onError = () => {
    if (!this.state.errored) {
      this.setState({
        src: "../logo/" + this.props.fallbackSrc,
        errored: true,
      });
    }
  };

  render() {
    //console.log("props", this.props);

    const { src, alt } = this.state;
    const { src: _1, fallbackSrc: _2, alt: _3, ...props } = this.props;
    return <img src={"../logo/" + src} alt={alt} onError={this.onError} {...props} />;
  }
}

Image.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  alt: PropTypes.string
};
